import axios from "axios";

import {
  ALL_INFO_REQUEST,
  ALL_INFO_SUCCESS,
  ALL_INFO_FAIL,
  ADMIN_INFO_REQUEST,
  ADMIN_INFO_SUCCESS,
  ADMIN_INFO_FAIL,
  NEW_INFO_REQUEST,
  NEW_INFO_SUCCESS,
  NEW_INFO_FAIL,
  DELETE_INFO_REQUEST,
  DELETE_INFO_SUCCESS,
  DELETE_INFO_FAIL,
  CLEAR_ERRORS,
} from "../constants/infoConstants";

export const getInfo = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_INFO_REQUEST,
    });
    const { data } = await axios.get("/api/v1/info");

    dispatch({
      type: ALL_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_INFO_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAdminInfo = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_INFO_REQUEST });
    const { data } = await axios.get("/api/v1/info");
    dispatch({
      type: ADMIN_INFO_SUCCESS,
      payload: data.info,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_INFO_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const createinfo = (infoData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_INFO_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `/api/v1/admin/info/new`,
      infoData,
      config
    );

    dispatch({
      type: NEW_INFO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_INFO_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteInfo = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_INFO_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/info/${id}`);

    dispatch({
      type: DELETE_INFO_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_INFO_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Category.css";
import icon from "../../../image/swing.png";
import {
  getCategoryImg,
  clearErrors,
} from "../../../actions/categoryImgAction";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Category = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { categoryImg, error } = useSelector((state) => state.categoryImgs);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getCategoryImg());
  }, [dispatch, error, alert]);

  if (!categoryImg) {
    return null; // Render nothing until data is available
  }

  const sortedCategoryImg = categoryImg.sort((a, b) =>
    a.category > b.category ? 1 : b.category > a.category ? -1 : 0
  );

  return (
    <div className="category">
      <h1 className="heading">
        Bringing the Indian Handcraft to the modern world...!!{" "}
        <img src={icon} alt="" />
      </h1>
      <Link to="/handcraftedBags">
        <span className="viewBtn">
          View All Category <ArrowForwardIcon />
        </span>
      </Link>
      <div className="cate-section sec">
        {sortedCategoryImg.map((cate) => (
          <div key={cate._id} className="first-cate area">
            <Link to={`/products/${cate.category}`}>
              <img src={cate.url} alt={cate.name} />
              <button style={{ textTransform: "uppercase" }}>
                {cate.name}
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;

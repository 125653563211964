import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { getBlogs, clearErrors } from "../../../actions/blogAction";
import Loader from "../../layout/Loader/Loader";
import MetaData from "../../layout/MetaData";
import BlogCard from "./BlogCard";
import "./BlogCard.css";

const Blog = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  let { blogs, error, loading } = useSelector((state) => state.blogs);
  blogs = blogs.sort((a, b) =>
    a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
  );
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getBlogs());
  }, [dispatch, alert, error]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title="Blogs--Woca Designs" />
          <div className="blogger">
            <div className="headingBlog">
              {" "}
              <h3>Blogs..!!</h3>
            </div>
            <div className="blogList-wrap">
              {blogs &&
                blogs.map((blog) => <BlogCard blog={blog} key={blog._id} />)}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Blog;

import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from "../../../image/logo.png";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { FaBars } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getNavbar, clearErrors } from "../../../actions/navbarAction";
import { getAdminWeekSaleDis } from "../../../actions/weekSaleDisAction";

const Navbar = ({ toggleDrawer }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { navbar, error } = useSelector((state) => state.navbars);
  const { weekSaleDis } = useSelector((state) => state.weekSaleDiss);
  let disWeekSale = weekSaleDis.map((c) => c.weekSaleDis);
  disWeekSale = disWeekSale[0];
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getAdminWeekSaleDis());
    dispatch(getNavbar());
  }, [dispatch, error, alert]);

  const navRoutes = navbar;

  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      navigate(`/products/${keyword}`);
    } else {
      navigate(`/products}`);
    }
  };

  return (
    <nav className="navbar">
      <button className="drawerBtn" onClick={toggleDrawer}>
        <FaBars />
      </button>
      <Link to="/" className="nav_brand">
        <img src={logo} alt="Woca Designs" />
      </Link>
      <ul className="nav_menu">
        {disWeekSale && disWeekSale > 0 ? (
          <li className="nav_item">
            <Link to="/weekendsale" className="nav_link">
              WeekendSale
            </Link>
          </li>
        ) : (
          ""
        )}
        {navRoutes &&
          navRoutes.map((route) => {
            return (
              <li className="nav_item" key={route.name}>
                <Link to={route.link} className="nav_link">
                  {route.name}
                </Link>
              </li>
            );
          })}
        <div className="nicon">
          <li onClick={handleToggle}>
            <SearchOutlinedIcon fontSize="large" />
          </li>
          {/* form */}
          <form
            className={isActive ? "nav_Search dn" : "nav_Search db"}
            onSubmit={searchSubmitHandler}
          >
            <input
              type="text"
              placeholder="Search a Product..."
              onChange={(e) => setKeyword(e.target.value)}
            />
            <input type="submit" value="Search" onClick={handleToggle} />
          </form>
          {/* form */}
          <li>
            <Link to="/login">
              <PersonOutlineOutlinedIcon fontSize="large" />
            </Link>
          </li>
          <li>
            <Link to="/wishlist">
              <FavoriteBorderOutlinedIcon fontSize="large" />
            </Link>
          </li>
          <li>
            <Link to="/cart">
              <LocalMallOutlinedIcon fontSize="large" />
            </Link>
          </li>
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;

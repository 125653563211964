import React, { useRef, useEffect } from "react";
import CheckOutSteps from "./CheckOutSteps";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import { useAlert } from "react-alert";
import axios from "axios";
import { createOrder, clearErrors } from "../../actions/orderAction";
// import { clearErrors } from "../../actions/orderAction";
import Logo from "../../image/logo.png";
import { useNavigate } from "react-router-dom";
import "./Payment.css";

const Payment = () => {
  const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));
  const dispatch = useDispatch();
  const alert = useAlert();
  const payBtn = useRef(null);
  const navigate = useNavigate();

  // const { shippingInfo } = useSelector((state) => state.cart);
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.newOrder);

  const paymentData = {
    amount: Math.round(orderInfo.totalPrice * 100),
  };

  // const orders = {
  //   shippingInfo,
  //   orderItems: cartItems,
  //   itemsPrice: orderInfo.subtotal,
  //   taxPrice: orderInfo.tax,
  //   shippingPrice: orderInfo.shippingCharges,
  //   totalPrice: orderInfo.totalPrice,
  // };

  const submitHandler = async () => {
    const {
      data: { key },
    } = await axios.get("/api/v1/getKey");
    const {
      data: { order },
    } = await axios.post("/api/v1/payment/process", paymentData);

    // razarpay
    const options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: user.name === "guest" ? shippingInfo.name : user.name,
      description: "Thanks for purchasing",
      image: Logo,
      order_id: order.id,
      callback_url: "/api/v1/paymentVerification",
      prefill: {
        name: user.name === "guest" ? shippingInfo.name : user.name,
        email: user.name === "guest" ? shippingInfo.name : user.email,
        contact: shippingInfo.phoneNo,
      },
      notes: {
        address: shippingInfo.address,
      },
      theme: {
        color: "#f3d2c1",
      },
    };
    const razor = new window.Razorpay(options);
    razor.open();

    // if (razor) {
    //   orders.paymentInfo = {
    //     id: options.order_id,
    //     status: "Successed",
    //   };
    //   dispatch(createOrder(orders));
    // }
  };
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, error, alert]);

  const sHand = async () => {
    const orders = {
      shippingInfo,
      orderItems: cartItems,
      itemsPrice: orderInfo.subtotal,
      taxPrice: orderInfo.tax,
      shippingPrice: orderInfo.shippingCharges,
      totalPrice: orderInfo.totalPrice + 100,
      paymentInfo: {
        id: user._id,
        status: "COD",
      },
    };
    dispatch(createOrder(orders));
    navigate(`/PaymentSuccess?reference=${user._id}`);
  };

  return (
    <>
      <MetaData title="Payment-- Woca Designs" />
      <CheckOutSteps activeStep={2} />
      <div className="paymentContainer">
        <div className="paymentBox">
          <h2 className="shippingHeading">Payment</h2>
          <div className="paymentForm">
            <p>Please select the option:</p>
            <h4>Razorpay Secure (UPI, Cards, Wallets, NetBanking)</h4>
            <p>
              Pay online to avail secured and contactless delivery. After
              clicking “Pay”, you will be redirected to Razorpay (Cards, UPI,
              NetBanking, Wallets) to complete your purchase securely.
            </p>
            <p style={{ fontWeight: "bold" }}>
              ₹{orderInfo && orderInfo.totalPrice}
            </p>
            <div>
              <input
                type="submit"
                value="Pay Now"
                ref={payBtn}
                className="paymentFormBtn"
                onClick={() => submitHandler()}
              />
            </div>
            <h4>Cash/ Pay On Delivery</h4>
            <span>Extra INR 100 will be charge for COD orders</span>
            <b>INR{orderInfo.totalPrice + 100}</b>
            <div>
              <input
                type="submit"
                value="Cash on devlivery"
                onClick={() => sHand()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;

export const ALL_SALEDIS_REQUEST = "ALL_SALEDIS_REQUEST";
export const ALL_SALEDIS_SUCCESS = "ALL_SALEDIS_SUCCESS";
export const ALL_SALEDIS_FAIL = "ALL_SALEDIS_FAIL";
export const ADMIN_SALEDIS_REQUEST = "ADMIN_SALEDIS_REQUEST";
export const ADMIN_SALEDIS_SUCCESS = "ADMIN_SALEDIS_SUCCESS";
export const ADMIN_SALEDIS_FAIL = "ADMIN_SALEDIS_FAIL";
export const NEW_SALEDIS_REQUEST = "NEW_SALEDIS_REQUEST";
export const NEW_SALEDIS_SUCCESS = "NEW_SALEDIS_SUCCESS";
export const NEW_SALEDIS_RESET = "NEW_SALEDIS_RESET";
export const NEW_SALEDIS_FAIL = "NEW_SALEDIS_FAIL";
export const DELETE_SALEDIS_REQUEST = "DELETE_SALEDIS_REQUEST";
export const DELETE_SALEDIS_SUCCESS = "DELETE_SALEDIS_SUCCESS";
export const DELETE_SALEDIS_RESET = "DELETE_SALEDIS_RESET";
export const DELETE_SALEDIS_FAIL = "DELETE_SALEDIS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

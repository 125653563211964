import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  newProductReducer,
  newReviewReducer,
  productDetailsReducer,
  productReducer,
  productReviewsReducer,
  productsReducer,
  reviewReducer,
} from "./reducers/productReducer";

import {
  allUsersReducer,
  forgotPasswordReducer,
  profileReducer,
  userDetailsReducer,
  userReducer,
} from "./reducers/userReducer";
import { cartReducer } from "./reducers/cartReducer";

import {
  allOrdersReducer,
  myOrdersReducer,
  newOrderReducer,
  orderDetailsReducer,
  orderReducer,
} from "./reducers/orderReducer";
import {
  infooReducer,
  infoReducer,
  newInfoReducer,
} from "./reducers/infoReducer";
import {
  bannerReducer,
  bannerrReducer,
  newBannerReducer,
} from "./reducers/bannerReducer";
import {
  categoryReducer,
  categoryyReducer,
  newCategoryReducer,
} from "./reducers/categoryReducer";
import {
  categoryImgReducer,
  categoryImgsReducer,
  newCategoryImgReducer,
} from "./reducers/categoryImgReducer";
import {
  navbarReducer,
  navbarrReducer,
  newNavbarReducer,
} from "./reducers/navbarReducer";
import { wishReducer } from "./reducers/wishlistReducer";
import {
  newSaleDisReducer,
  saleDisReducer,
  saleDissReducer,
} from "./reducers/saleDisReducer";
import {
  newWeekSaleDisReducer,
  weekSaleDisReducer,
  weekSaleDissReducer,
} from "./reducers/weekSaleDisReducer";
import { recentReducer } from "./reducers/recentViewReducer";
import {
  collectionnReducer,
  collectionReducer,
  newCollectionReducer,
} from "./reducers/collectionReducer";
import {
  newPageBannerReducer,
  pageBannerReducer,
  pageBannerrReducer,
} from "./reducers/pageBannerReducer";
import {
  blogDetailsReducer,
  blognReducer,
  blogReducer,
  newBlogReducer,
} from "./reducers/blogReducer";
import promotionReducer from "./reducers/promotionReducer";

const reducer = combineReducers({
  products: productsReducer,
  product: productReducer,
  productDetails: productDetailsReducer,
  user: userReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  cart: cartReducer,
  wish: wishReducer,
  recent: recentReducer,
  newOrder: newOrderReducer,
  myOrders: myOrdersReducer,
  orderDetails: orderDetailsReducer,
  allOrders: allOrdersReducer,
  order: orderReducer,
  newReview: newReviewReducer,
  newProduct: newProductReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  productReviews: productReviewsReducer,
  review: reviewReducer,
  infos: infoReducer,
  newInfo: newInfoReducer,
  info: infooReducer,
  banners: bannerReducer,
  newBanner: newBannerReducer,
  banner: bannerrReducer,
  pageBanners: pageBannerReducer,
  newPageBanner: newPageBannerReducer,
  pageBanner: pageBannerrReducer,
  categories: categoryReducer,
  newCategory: newCategoryReducer,
  category: categoryyReducer,
  printCollections: collectionReducer,
  newPrintCollection: newCollectionReducer,
  printCollection: collectionnReducer,
  blogs: blogReducer,
  blog: blogDetailsReducer,
  newBlog: newBlogReducer,
  blogn: blognReducer,
  categoryImgs: categoryImgReducer,
  newCategoryImg: newCategoryImgReducer,
  categoryImg: categoryImgsReducer,
  navbars: navbarReducer,
  newNavbar: newNavbarReducer,
  navbar: navbarrReducer,
  saleDiss: saleDisReducer,
  newSaleDis: newSaleDisReducer,
  saleDis: saleDissReducer,
  weekSaleDiss: weekSaleDisReducer,
  newWeekSaleDis: newWeekSaleDisReducer,
  weekSaleDis: weekSaleDissReducer,
  promotion: promotionReducer,
});

let initialState = {
  cart: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
    shippingInfo: localStorage.getItem("shippingInfo")
      ? JSON.parse(localStorage.getItem("shippingInfo"))
      : {},
  },
  wish: {
    wishItems: localStorage.getItem("wishItems")
      ? JSON.parse(localStorage.getItem("wishItems"))
      : [],
  },
  recent: {
    recentItems: localStorage.getItem("recentItems")
      ? JSON.parse(localStorage.getItem("recentItems"))
      : [],
  },
};

const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

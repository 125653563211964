import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Rating } from "@mui/material";
import "./Product.css";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { addItemsToCart } from "../../actions/cartAction";
import { addItemsToWish } from "../../actions/wishAction";
import { addItemsToRecent } from "../../actions/recentViewAction";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getSaleDis } from "../../actions/saleDisAction";
import { getWeekSaleDis } from "../../actions/weekSaleDisAction";

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();
  const { saleDis } = useSelector((state) => state.saleDiss);
  let disSale = saleDis.map((c) => c.saleDis);
  disSale = disSale[0];
  const { weekSaleDis } = useSelector((state) => state.weekSaleDiss);
  let disWeekSale = weekSaleDis.map((c) => c.weekSaleDis);
  disWeekSale = disWeekSale[0];
  useEffect(() => {
    dispatch(getSaleDis());
    dispatch(getWeekSaleDis());
  }, [dispatch]);
  const alert = useAlert();
  const quantity = 1;
  const addToCartHandler = () => {
    if (product.stock > 1) {
      dispatch(addItemsToCart(product._id, quantity));
      alert.success("Item Added To Cart");
    } else {
      alert.error("Product is Out of Stock");
    }
  };
  const addToWishHandler = () => {
    dispatch(addItemsToWish(product._id));
    alert.success("Item Added To Wishlist");
  };
  const addToRecentHandler = () => {
    dispatch(addItemsToRecent(product._id));
  };
  const options = {
    size: "large",
    value: product.ratings,
    readOnly: true,
    precision: 0.5,
  };
  return (
    <>
      <div className="main">
        <div className="container">
          <span
            className={
              `${
                disSale
                  ? disSale
                  : product.weekSale
                  ? disWeekSale
                  : product.discount
              }` > 1 || product.stock === 0
                ? "discHH"
                : "none"
            }
          >
            {product.stock === 0
              ? "Sold"
              : `${
                  disSale
                    ? disSale + "%"
                    : product.weekSale
                    ? disWeekSale + `%`
                    : product.discount === 0
                    ? ""
                    : product.weekSale
                    ? disWeekSale + `%`
                    : product.discount + `%`
                }`}
          </span>
          <span
            className={
              product.discount > 30 || product.ratings > 4 ? "discHHp" : "none"
            }
          >
            {product.discount > 30
              ? "Price Drop"
              : product.ratings > 4
              ? "Bestseller"
              : ""}
          </span>
          <div className="card">
            <div className="imgBox">
              <img src={product.images[0].url} alt={product.name} />
              <ul className="action">
                <li onClick={addToWishHandler}>
                  <FavoriteBorderOutlinedIcon />
                  <span>Add to Wishlist</span>
                </li>
                <li onClick={addToCartHandler}>
                  <LocalMallOutlinedIcon />
                  <span>Add to Cart</span>
                </li>
                <li>
                  <VisibilityOutlinedIcon />
                  <span>Ouickview</span>
                </li>
              </ul>
            </div>
            <Link
              to={`/product/${product.name}/${product._id}`}
              className="content"
              onClick={addToRecentHandler}
            >
              <div className="productName">
                <h3>{product.name}</h3>
              </div>
              <div className="price_rating" style={{ marginTop: "5px" }}>
                <h2
                  className={
                    `${
                      disSale
                        ? disSale
                        : product.weekSale
                        ? disWeekSale
                        : product.discount
                    }` > 1
                      ? "disc"
                      : "none"
                  }
                >{`Rs. ${product.price}`}</h2>
                <h2>
                  {" "}
                  {`${
                    disSale
                      ? disSale
                      : product.weekSale
                      ? disWeekSale
                      : product.discount
                  }` > 0
                    ? `Rs.${Math.floor(
                        product.price -
                          (product.price / 100) *
                            `${
                              disSale
                                ? disSale
                                : product.weekSale
                                ? disWeekSale
                                : product.discount
                            }`
                      )}`
                    : ""}
                </h2>
                <div className="rating">
                  <Rating {...options} />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;

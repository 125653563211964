import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { getCollection, clearErrors } from "../../../actions/collectionAction";
import Loader from "../../layout/Loader/Loader";
import MetaData from "../../layout/MetaData";
import CollectionCard from "./CollectionCard";

const Collection = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { collection, error, loading } = useSelector(
    (state) => state.printCollections
  );
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getCollection());
  }, [dispatch, alert, error]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title="Collections--Woca Designs" />
          <div className="collectionDiv">
            <div className="collectionHeading">
              Explore Our Signature Embroidery..!!
            </div>
            <div className="cc">
              {collection &&
                collection.map((collect) => (
                  <CollectionCard collect={collect} key={collect._id} />
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Collection;

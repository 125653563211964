import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAdminInfo,
  deleteInfo,
  clearErrors,
} from "../../actions/infoAction";
import MetaData from "../layout/MetaData";
import "./ProductList.css";
import Sidebar from "./Sidebar";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { DELETE_INFO_RESET } from "../../constants/infoConstants";

const InfoList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const { error, info } = useSelector((state) => state.infos);
  const { error: deleteError, isDeleted } = useSelector((state) => state.info);

  const deleteInfoHandler = (id) => {
    dispatch(deleteInfo(id));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("Info Deleted Successfully");
      navigate("/admin/dashboard");
      dispatch({ type: DELETE_INFO_RESET });
    }

    dispatch(getAdminInfo());
  }, [dispatch, alert, error, deleteError, isDeleted, navigate]);

  const columns = [
    { field: "id", headerName: "Info ID", minWidth: 200, flex: 0.5 },

    {
      field: "info",
      headerName: "Info",
      minWidth: 350,
      flex: 1,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() =>
                deleteInfoHandler(params.getValue(params.id, "id"))
              }
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
    },
  ];

  const rows = [];

  info &&
    info.forEach((item) => {
      rows.push({
        id: item._id,
        info: item.info,
      });
    });

  return (
    <>
      <MetaData title={`ALL INFO - Admin`} />

      <div className="dashboard">
        <Sidebar />
        <div className="productListContainer">
          <h1 id="productListHeading">ALL INFO</h1>

          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </>
  );
};

export default InfoList;

import {
  ADD_TO_WISHLIST,
  REMOVE_WISHLIST_ITEM,
} from "../constants/wishlistConstants";

export const wishReducer = (state = { wishItems: [] }, action) => {
  switch (action.type) {
    case ADD_TO_WISHLIST:
      const item = action.payload;

      const isItemExist = state.wishItems.find(
        (i) => i.product === item.product
      );

      if (isItemExist) {
        return {
          ...state,
          wishItems: state.wishItems.map((i) =>
            i.product === isItemExist.product ? item : i
          ),
        };
      } else {
        return {
          ...state,
          wishItems: [...state.wishItems, item],
        };
      }

    case REMOVE_WISHLIST_ITEM:
      return {
        ...state,
        wishItems: state.wishItems.filter((i) => i.product !== action.payload),
      };

    default:
      return state;
  }
};

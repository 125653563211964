import axios from "axios";

import {
  ALL_COLLECTION_REQUEST,
  ALL_COLLECTION_SUCCESS,
  ALL_COLLECTION_FAIL,
  ADMIN_COLLECTION_REQUEST,
  ADMIN_COLLECTION_SUCCESS,
  ADMIN_COLLECTION_FAIL,
  NEW_COLLECTION_REQUEST,
  NEW_COLLECTION_SUCCESS,
  NEW_COLLECTION_FAIL,
  DELETE_COLLECTION_REQUEST,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAIL,
  CLEAR_ERRORS,
} from "../constants/collectionConstants";

export const getCollection = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_COLLECTION_REQUEST,
    });
    const { data } = await axios.get("/api/v1/collection");

    dispatch({
      type: ALL_COLLECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_COLLECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAdminCollection = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_COLLECTION_REQUEST });
    const { data } = await axios.get("/api/v1/collection");
    dispatch({
      type: ADMIN_COLLECTION_SUCCESS,
      payload: data.collection,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_COLLECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const createCollection = (collectionData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_COLLECTION_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `/api/v1/admin/collection/new`,
      collectionData,
      config
    );

    dispatch({
      type: NEW_COLLECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_COLLECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteCollection = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_COLLECTION_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/collection/${id}`);

    dispatch({
      type: DELETE_COLLECTION_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COLLECTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

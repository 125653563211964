import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, createBlog } from "../../actions/blogAction";
import MetaData from "../layout/MetaData";
import "./NewProduct.css";
import { Button } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { NEW_BLOG_RESET } from "../../constants/blogConstants";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";

const NewBlog = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { loading, error, success } = useSelector((state) => state.newBlog);

  const [Title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Blog Created Successfully");
      navigate("/admin/blogs");
      dispatch({ type: NEW_BLOG_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const createCategorySubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("Title", Title);
    myForm.set("description", description);
    myForm.set("authorName", authorName);
    myForm.set("image", image);

    dispatch(createBlog(myForm));
  };

  return (
    <>
      <MetaData title="Create Blog" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createCategorySubmitHandler}
          >
            <h1>Create Collection</h1>

            <div>
              <DescriptionIcon />
              <input
                type="text"
                placeholder="Title"
                required
                value={Title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div>
              <DescriptionIcon />
              <input
                className="desc"
                type="textarea"
                placeholder="Description"
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div>
              <DescriptionIcon />
              <input
                type="text"
                placeholder="AuthorName"
                required
                value={authorName}
                onChange={(e) => setAuthorName(e.target.value)}
              />
            </div>
            <div>
              <LinkIcon />
              <input
                type="text"
                placeholder="Blog Image Url"
                required
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewBlog;

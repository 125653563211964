export const ALL_WEEKSALEDIS_REQUEST = "ALL_WEEKSALEDIS_REQUEST";
export const ALL_WEEKSALEDIS_SUCCESS = "ALL_WEEKSALEDIS_SUCCESS";
export const ALL_WEEKSALEDIS_FAIL = "ALL_WEEKSALEDIS_FAIL";
export const ADMIN_WEEKSALEDIS_REQUEST = "ADMIN_WEEKSALEDIS_REQUEST";
export const ADMIN_WEEKSALEDIS_SUCCESS = "ADMIN_WEEKSALEDIS_SUCCESS";
export const ADMIN_WEEKSALEDIS_FAIL = "ADMIN_WEEKSALEDIS_FAIL";
export const NEW_WEEKSALEDIS_REQUEST = "NEW_WEEKSALEDIS_REQUEST";
export const NEW_WEEKSALEDIS_SUCCESS = "NEW_WEEKSALEDIS_SUCCESS";
export const NEW_WEEKSALEDIS_RESET = "NEW_WEEKSALEDIS_RESET";
export const NEW_WEEKSALEDIS_FAIL = "NEW_WEEKSALEDIS_FAIL";
export const DELETE_WEEKSALEDIS_REQUEST = "DELETE_WEEKSALEDIS_REQUEST";
export const DELETE_WEEKSALEDIS_SUCCESS = "DELETE_WEEKSALEDIS_SUCCESS";
export const DELETE_WEEKSALEDIS_RESET = "DELETE_WEEKSALEDIS_RESET";
export const DELETE_WEEKSALEDIS_FAIL = "DELETE_WEEKSALEDIS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

import React from "react";
import "./Cart.css";
import "./CartItemCard";
import CartItemCard from "./CartItemCard";
import { useSelector, useDispatch } from "react-redux";
import { removeItemsFromWish } from "../../actions/wishAction";
import { Typography } from "@mui/material";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { Link } from "react-router-dom";
import MetaData from "../layout/MetaData";
import { addItemsToCart } from "../../actions/cartAction";
import { useAlert } from "react-alert";

const Wishlist = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { wishItems } = useSelector((state) => state.wish);

  const quantity = 1;
  const addToCartHandler = (id) => {
    dispatch(addItemsToCart(id, quantity));
    alert.success("Item Added To Cart");
  };

  const deleteCartItem = (id, stock) => {
    if (stock === 0) {
      return;
    }
    dispatch(removeItemsFromWish(id));
  };
  return (
    <>
      <MetaData title="Wishlist- Woca Designs" />
      {wishItems.length === 0 ? (
        <div className="emptyCart">
          <RemoveShoppingCartIcon />
          <Typography> Please add some product</Typography>
          <Link to="/products">View Products</Link>
        </div>
      ) : (
        <>
          <div className="cartPage">
            <h2>Wishlist Details</h2>
            <div className="wishHeader">
              <p>Product Details</p>
            </div>
            <div className="overflowcartw">
              {wishItems &&
                wishItems.map((item) => (
                  <div className="wishContainer" key={item.product}>
                    <CartItemCard item={item} deleteCartItem={deleteCartItem} />
                    <button
                      className="wishBtn"
                      onClick={() => addToCartHandler(item.product)}
                    >
                      Add to Cart
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Wishlist;

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, createPageBanner } from "../../actions/pageBannerAction";
import MetaData from "../layout/MetaData";
import "./NewProduct.css";
import { Button } from "@mui/material";
import TitleIcon from "@mui/icons-material/Title";
import LinkIcon from "@mui/icons-material/Link";
import { NEW_PAGEBANNER_RESET } from "../../constants/pageBannerConstants";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";

const NewPageBanner = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { loading, error, success } = useSelector(
    (state) => state.newPageBanner
  );

  const [url, setUrl] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Banner Created Successfully");
      navigate("/admin/pageBanners");
      dispatch({ type: NEW_PAGEBANNER_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const createBannerSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("url", url);
    myForm.set("name", name);

    dispatch(createPageBanner(myForm));
  };

  return (
    <>
      <MetaData title="Create Page Banner" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createBannerSubmitHandler}
          >
            <h1>Create Page Banner</h1>

            <div>
              <LinkIcon />
              <input
                type="text"
                placeholder="Page Banner Url"
                required
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
            <div>
              <TitleIcon />
              <input
                type="text"
                placeholder="Page Banner Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewPageBanner;

import React, { useState } from "react";
import MetaData from "../../layout/MetaData";
import "./FAQs.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const dataFaq = [
  {
    questions: "How to clean your Woca products?",
    answer: "Dryclean",
  },
  {
    questions: "How to store your Woca products?",
    answer:
      "Store your products in such a position that it doesn’t flatten. For example, if you plan to start using your new bags in a few days, retain the paper fillings. This helps retain the original shape of your bags. Similarly, do not put any heavy object on top of your empty Woca wallets and accessories.",
  },
  {
    questions: "How to increase the life of your Woca products?",
    answer:
      "Always keep your Woca bags and wallets on a shelf. Avoid keeping the bags hung for a long time, since this can cause wear and tear to the handles. Similarly, avoid keeping any pointed objects on the wallet. Also, avoid overstuffing the wallet, since this might cause damage to the zippers.",
  },
];

const UsageandCare = () => {
  const [accordion, setAccordion] = useState(null);
  const toggleAccordion = (index) => {
    if (index === accordion) {
      setAccordion(null);
      return;
    }
    setAccordion(index);
  };

  return (
    <>
      <MetaData title="Usage and Care--Woca Designs" />
      {/* <img
        className="faqPageImg"
        src="https://res.cloudinary.com/woca-designs/image/upload/v1663764506/Useful%20Links%20Image/usage_xis8ds.jpg"
        alt="Usage and care Woca Designs"
      /> */}
      <h2 className="productsHeading">Usage and Care</h2>

      <div className="faqDiv">
        <p>
          Woca products are a great choice as your go-to everyday option. You
          can prolong the life of the products more by following some of our
          tips below.
        </p>
        {dataFaq &&
          dataFaq.map((item, index) => (
            <div
              className="item"
              key={index}
              onClick={() => toggleAccordion(index)}
            >
              <div className="faqHeading">
                {accordion === index ? (
                  <span className="verticle">
                    <KeyboardArrowUpIcon fontSize="large" />
                  </span>
                ) : (
                  <span className="verticle">
                    <KeyboardArrowDownIcon fontSize="large" />
                  </span>
                )}
                <h3 className={accordion === index ? "activefaq" : ""}>
                  {item.questions}
                </h3>
              </div>
              <div
                className={
                  accordion === index ? "faqContent show" : "faqContent"
                }
              >
                {item.answer}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default UsageandCare;

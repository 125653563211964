import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, createinfo } from "../../actions/infoAction";
import MetaData from "../layout/MetaData";
import "./NewProduct.css";
import { Button } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { NEW_INFO_RESET } from "../../constants/infoConstants";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";

const NewInfo = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { loading, error, success } = useSelector((state) => state.newInfo);

  const [info, setInfo] = useState("");

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Info Created Successfully");
      navigate("/admin/dashboard");
      dispatch({ type: NEW_INFO_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const createInfoSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("info", info);

    dispatch(createinfo(myForm));
  };

  return (
    <>
      <MetaData title="Create Info" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createInfoSubmitHandler}
          >
            <h1>Create Info</h1>

            <div>
              <DescriptionIcon />
              <input
                type="text"
                placeholder="Info"
                required
                value={info}
                onChange={(e) => setInfo(e.target.value)}
              />
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewInfo;

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, createCategory } from "../../actions/categoryAction";
import MetaData from "../layout/MetaData";
import "./NewProduct.css";
import { Button } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { NEW_CATEGORY_RESET } from "../../constants/categoryConstants";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";

const NewCategory = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { loading, error, success } = useSelector((state) => state.newCategory);

  const [category, setCategory] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Info Created Successfully");
      navigate("/admin/categories");
      dispatch({ type: NEW_CATEGORY_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const createCategorySubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("category", category);
    myForm.set("image", image);

    dispatch(createCategory(myForm));
  };

  return (
    <>
      <MetaData title="Create Info" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createCategorySubmitHandler}
          >
            <h1>Create Category</h1>

            <div>
              <DescriptionIcon />
              <input
                type="text"
                placeholder="Category"
                required
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>
            <div>
              <LinkIcon />
              <input
                type="text"
                placeholder="Category Image Url"
                required
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewCategory;

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, createProduct } from "../../actions/productAction";
import MetaData from "../layout/MetaData";
import "./NewProduct.css";
import { Button } from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DescriptionIcon from "@mui/icons-material/Description";
import StorageIcon from "@mui/icons-material/Storage";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { NEW_PRODUCT_RESET } from "../../constants/productConstants";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { getAdminCategory } from "../../actions/categoryAction";
import { getAdminCollection } from "../../actions/collectionAction";

const NewProduct = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { category: categorie, error: catError } = useSelector(
    (state) => state.categories
  );
  const { collection } = useSelector((state) => state.printCollections);

  useEffect(() => {
    if (catError) {
      alert.error(catError);
      dispatch(clearErrors());
    }
    dispatch(getAdminCategory());
    dispatch(getAdminCollection());
  }, [dispatch, alert, catError]);

  const { loading, error, success } = useSelector((state) => state.newProduct);

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [printCollection, setPrintCollection] = useState("");
  const [colour, setColour] = useState("");
  const [externalMaterial, setExternalMaterial] = useState("");
  const [internalMaterial, setInternalMaterial] = useState("");
  const [size, setSize] = useState("");
  const [frequentProductOne, setPrintFrequentProductOne] = useState("");
  const [frequentProductTwo, setPrintFrequentProductTwo] = useState("");
  const [stock, setStock] = useState(0);
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);

  const categories = categorie.sort((a, b) =>
    a.category > b.category ? 1 : b.category > a.category ? -1 : 0
  );

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Product Created Successfully");
      navigate("/admin/products");
      dispatch({ type: NEW_PRODUCT_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const createProductSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("price", price);
    myForm.set("discount", discount);
    myForm.set("description", description);
    myForm.set("category", category);
    myForm.set("printCollection", printCollection);
    myForm.set("colour", colour);
    myForm.set("externalMaterial", externalMaterial);
    myForm.set("internalMaterial", internalMaterial);
    myForm.set("size", size);
    myForm.set("frequentProductOne", frequentProductOne);
    myForm.set("frequentProductTwo", frequentProductTwo);
    myForm.set("stock", stock);

    images.forEach((image) => {
      myForm.append("images", image);
    });
    dispatch(createProduct(myForm));
  };

  const createProductImagesChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);
    setImagesPreview([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
          setImages((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <MetaData title="Create Product" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createProductSubmitHandler}
          >
            <h1>Create Product</h1>
            <div>
              <SpellcheckIcon />
              <input
                type="text"
                placeholder="Product Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <AttachMoneyIcon />
              <input
                type="number"
                placeholder="Price"
                required
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            <div>
              <AttachMoneyIcon />
              <input
                type="number"
                placeholder="Discount"
                value={discount}
                required
                onChange={(e) => setDiscount(e.target.value)}
              />
            </div>

            <div>
              <DescriptionIcon />

              <textarea
                placeholder="Product Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>
            <div>
              <DescriptionIcon />

              <input
                placeholder="Product Colour"
                value={colour}
                onChange={(e) => setColour(e.target.value)}
                cols="30"
                rows="1"
              ></input>
            </div>
            <div>
              <DescriptionIcon />

              <input
                placeholder="Product External Material"
                value={externalMaterial}
                onChange={(e) => setExternalMaterial(e.target.value)}
                cols="30"
                rows="1"
              ></input>
            </div>
            <div>
              <DescriptionIcon />

              <input
                placeholder="Product Internal Material"
                value={internalMaterial}
                onChange={(e) => setInternalMaterial(e.target.value)}
                cols="30"
                rows="1"
              ></input>
            </div>
            <div>
              <DescriptionIcon />

              <input
                placeholder="Product Size"
                value={size}
                onChange={(e) => setSize(e.target.value)}
                cols="30"
                rows="1"
              ></input>
            </div>

            <div>
              <AccountTreeIcon />
              <select onChange={(e) => setCategory(e.target.value)}>
                <option value="">Choose Category</option>
                {categories &&
                  categories.map((cate) => (
                    <option key={cate._id} value={cate.category}>
                      {cate.category}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <AccountTreeIcon />
              <select onChange={(e) => setPrintCollection(e.target.value)}>
                <option value="">Choose Collection</option>
                {collection &&
                  collection.map((cate) => (
                    <option key={cate._id} value={cate.printCollection}>
                      {cate.printCollection}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <AccountTreeIcon />
              <select
                onChange={(e) => setPrintFrequentProductOne(e.target.value)}
              >
                <option value="">Choose FrequentProductOne Category</option>
                {categories &&
                  categories.map((cate) => (
                    <option key={cate._id} value={cate.category}>
                      {cate.category}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <AccountTreeIcon />
              <select
                onChange={(e) => setPrintFrequentProductTwo(e.target.value)}
              >
                <option value="">Choose FrequentProductTwo Category</option>
                {categories &&
                  categories.map((cate) => (
                    <option key={cate._id} value={cate.category}>
                      {cate.category}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <StorageIcon />
              <input
                type="number"
                placeholder="Stock"
                required
                onChange={(e) => setStock(e.target.value)}
              />
            </div>

            <div id="createProductFormFile">
              <input
                type="file"
                name="avatar"
                accept="image/*"
                onChange={createProductImagesChange}
                multiple
              />
            </div>

            <div id="createProductFormImage">
              {imagesPreview.map((image, index) => (
                <img key={index} src={image} alt="Product Preview" />
              ))}
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewProduct;

import React, { useState, useEffect } from "react";
import "./SlideModal.css";

const SlideModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [randomName, setRandomName] = useState("");
  const [randomPlace, setRandomPlace] = useState("");
  const [randomTime, setRandomTime] = useState("");
  const [randomProduct, setRandomProduct] = useState(null);

  const names = [
    "Sarika",
    "Priya",
    "Neha",
    "Anjali",
    "Shweta",
    "Amita",
    "Ananya",
    "Smita",
    "Rashmi",
    "Pooja",
    "Meera",
    "Shreya",
    "Kavita",
    "Nandini",
    "Sanjana",
    "Radhika",
    "Aditi",
    "Aparna",
    "Pallavi",
    "Kiran",
    "Khushboo",
    "Poonam",
    "Ritu",
    "Shalini",
    "Swati",
    "Deepika",
    "Alka",
    "Nisha",
    "Monika",
    "Rachana",
    "Kajal",
    "Vandana",
    "Manisha",
    "Divya",
    "Harshita",
    "Nishi",
    "Tanu",
    "Krishna",
    "Ankita",
  ];
  const places = [
    "Mumbai",
    "Delhi",
    "Kolkata",
    "Chennai",
    "Bangalore",
    "Hyderabad",
    "Jaipur",
    "Pune",
    "Lucknow",
    "Ahmedabad",
    "Patna",
    "Bhopal",
    "Bhubaneswar",
    "Nagpur",
    "Coimbatore",
    "Surat",
    "Indore",
    "Kanpur",
    "Varanasi",
    "Agra",
  ];
  const timeAgoValues = [
    "1 hour ago",
    "2 hours ago",
    "3 hours ago",
    "4 hours ago",
    "5 hours ago",
    "6 hours ago",
    "7 hours ago",
    "8 hours ago",
    "9 hours ago",
    "10 hours ago",
    "11 hours ago",
    "12 hours ago",
    "13 hours ago",
    "14 hours ago",
    "15 hours ago",
    "16 hours ago",
    "17 hours ago",
    "18 hours ago",
    "19 hours ago",
    "20 hours ago",
    "21 hours ago",
    "22 hours ago",
    "23 hours ago",
    "1 day ago",
    "2 days ago",
    "3 days ago",
    "4 days ago",
    "5 days ago",
    "6 days ago",
    "1 week ago",
    "2 weeks ago",
  ];

  const randomProducts = [
    {
      name: "Moonlight Black handbag",
      imageUrl:
        "https://res.cloudinary.com/woca-designs/image/upload/v1698671559/products/jtzbyf99ddcgwwkfsx3h.jpg",
    },
    {
      name: "Moonlight Red handbag",
      imageUrl:
        "https://res.cloudinary.com/woca-designs/image/upload/v1698671393/products/shb7ejouu4j06jo04tcu.jpg",
    },
    {
      name: "Mid Work Patch Sling Bag",
      imageUrl:
        "https://res.cloudinary.com/woca-designs/image/upload/v1698328585/products/pt89euzzsegg6ujjpyhl.jpg",
    },
    {
      name: "Gota patti Patch sling bag",
      imageUrl:
        "https://res.cloudinary.com/woca-designs/image/upload/v1698328323/products/ab5btocae3vlocz1xnhk.jpg",
    },
    {
      name: "Kodi Patch Sling Bag",
      imageUrl:
        "https://res.cloudinary.com/woca-designs/image/upload/v1698328026/products/s4r1kmdav3oqkluyhyzs.jpg",
    },
    {
      name: "Olive Green Chhapai Tote Bag",
      imageUrl:
        "https://res.cloudinary.com/woca-designs/image/upload/v1698327788/products/qmb7kd4p4wqdfhxakgdw.jpg",
    },
    {
      name: "Black Chhapai Tote Bag",
      imageUrl:
        "https://res.cloudinary.com/woca-designs/image/upload/v1698327583/products/stcgyvbswtougk9lqt20.jpg",
    },
    {
      name: "Suede Chhapai Tote Bag",
      imageUrl:
        "https://res.cloudinary.com/woca-designs/image/upload/v1698327328/products/qhwbhrs1tezlkwc8rvos.jpg",
    },
    {
      name: "Beige Chhapai Tote Bag",
      imageUrl:
        "https://res.cloudinary.com/woca-designs/image/upload/v1698319061/products/rda8gkaznehpm7tdjfnl.jpg",
    },
    {
      name: "Tulip Pocketo Hand Embroidery Canvas Sling Bag",
      imageUrl:
        "https://res.cloudinary.com/dw7qmqukk/image/upload/f_auto,q_auto/v1688818862/woca/bqiidmptp99mx4czaf9w_bahzgt.jpg",
    },
    {
      name: "Silica Pocketo Hand Embroidery Canvas Sling Bag",
      imageUrl:
        "https://res.cloudinary.com/dw7qmqukk/image/upload/f_auto,q_auto/v1688818693/woca/e8iom6yehkzohgpau9av_sv2p1b.jpg",
    },
    {
      name: "Nakashi Pocketo Hand Embroidery Canvas Sling Bag",
      imageUrl:
        "https://res.cloudinary.com/dw7qmqukk/image/upload/f_auto,q_auto/v1688818380/woca/ahp4dnegza8xl6zh2uxq_ddgozb.jpg",
    },
    {
      name: "Maple Pocketo Hand Embroidery Canvas Sling Bag",
      imageUrl:
        "https://res.cloudinary.com/dw7qmqukk/image/upload/f_auto,q_auto/v1688817758/woca/iyqzsjqzhz0zcwitu8m5_a6eqrb.jpg",
    },
    {
      name: 'Golden Banarasi 14"Laptop Bag',
      imageUrl:
        "https://res.cloudinary.com/dw7qmqukk/image/upload/f_auto,q_auto/v1688817319/woca/cy7jozkpz4touo2l7szp_crbqn4.jpg",
    },
    {
      name: 'Brown Banarasi 14"Laptop Bag',
      imageUrl:
        "https://res.cloudinary.com/dw7qmqukk/image/upload/f_auto,q_auto/v1688816627/woca/c6unflwb4fm4fwgatqoq_pp0pem.jpg",
    },
    {
      name: 'Beige Banarasi 14"Laptop Bag',
      imageUrl:
        "https://res.cloudinary.com/dw7qmqukk/image/upload/f_auto,q_auto/v1688815439/woca/rsilsqqktcfz9vwzz2m6_hrdpx0.jpg",
    },
    {
      name: 'Teal Green Banarasi 14"Laptop Bag',
      imageUrl:
        "https://res.cloudinary.com/dqi6h61uo/image/upload/f_auto,q_auto/v1688814786/woca/fcyrbv6uyepumssthscl_uq9msp.jpg",
    },
    {
      name: "Mid-way Green sling bag",
      imageUrl:
        "https://res.cloudinary.com/dqi6h61uo/image/upload/f_auto,q_auto/v1688814262/woca/vbe8rgemhdsepufzvoto_wnwjev.jpg",
    },
    {
      name: "Mid-way Pastel Blue sling bag",
      imageUrl:
        "https://res.cloudinary.com/dqi6h61uo/image/upload/f_auto,q_auto/v1688814206/woca/c5mottutgrptxn9b69p9_t1fkzk.jpg",
    },
    {
      name: "Mid-way Red sling bag",
      imageUrl:
        "https://res.cloudinary.com/dqi6h61uo/image/upload/f_auto,q_auto/v1688813876/woca/a7xcbxnpqblvoptfpfpv_s9xhd4.jpg",
    },
    {
      name: "Sillica Blue Fanny bag",
      imageUrl:
        "https://res.cloudinary.com/dqi6h61uo/image/upload/f_auto,q_auto/v1688812911/woca/om5aw2jfqqwwrxoqqowe_qg5m7j.jpg",
    },
    {
      name: "Sillica Red Fanny bag",
      imageUrl:
        "https://res.cloudinary.com/dqi6h61uo/image/upload/f_auto,q_auto/v1688811279/woca/pb4y9sf7idak8ask9ygp_abw4dc.jpg",
    },
    {
      name: "Magnolia White Fanny Bag",
      imageUrl:
        "https://res.cloudinary.com/dqi6h61uo/image/upload/f_auto,q_auto/v1688810901/woca/zp0j2vmtxrlxvjcyde2j_uncbkp.jpg",
    },
    {
      name: "Magnolia beige fanny Bag",
      imageUrl:
        "https://res.cloudinary.com/dqi6h61uo/image/upload/f_auto,q_auto/v1688802449/woca/cyvgoqxkecunwjk9orsk_rxizaa.jpg",
    },
    {
      name: "Magnolia Blue Fanny Bag",
      imageUrl:
        "https://res.cloudinary.com/ds75hbygk/image/upload/f_auto,q_auto/v1688754718/woca/qy2iskggm8isoajd5vmi_eurubv.jpg",
    },
    {
      name: "Red velvet Tote Bag",
      imageUrl:
        "https://res.cloudinary.com/ds75hbygk/image/upload/f_auto,q_auto/v1688754416/woca/uhtpxnfw8irn1qephlym_phwlwm.jpg",
    },
    {
      name: "Olive Green Tote Bag",
      imageUrl:
        "https://res.cloudinary.com/ds75hbygk/image/upload/f_auto,q_auto/v1688754155/woca/t4rnvvp7z3h11gwo6ojg_xxmx08.jpg",
    },
    {
      name: "Minty Tote Bag",
      imageUrl:
        "https://res.cloudinary.com/ds75hbygk/image/upload/f_auto,q_auto/v1688753875/woca/slh7wts8tfpmd7stnlpx_xxtofm.jpg",
    },
    {
      name: "Maple Tote Bag",
      imageUrl:
        "https://res.cloudinary.com/ds75hbygk/image/upload/f_auto,q_auto/v1688753237/woca/muq1i67jiuuzoaziqex2_igkrf9.jpg",
    },
    {
      name: "Bahar Banarasi Silk Sling Bag",
      imageUrl:
        "https://res.cloudinary.com/ds75hbygk/image/upload/f_auto,q_auto/v1688753018/woca/bevc7wowvzaxnk40weub_zp8mh6.jpg",
    },
    {
      name: "Mastani  Banarasi Silk Sling Bag",
      imageUrl:
        "https://res.cloudinary.com/ds75hbygk/image/upload/f_auto,q_auto/v1688752888/woca/ymduioacntnsaegpxu8y_eau9bu.jpg",
    },
    {
      name: "Kahani Banarasi Silk Sling Bag",
      imageUrl:
        "https://res.cloudinary.com/ds75hbygk/image/upload/f_auto,q_auto/v1688752254/woca/r4gqbw5rwbnoy5sfc3wa_pxaft7.jpg",
    },
    {
      name: "Kavita Banarasi Silk Sling Bag",
      imageUrl:
        "https://res.cloudinary.com/dumir6ksf/image/upload/f_auto,q_auto/v1688751888/woca/pkvsw6s7brsw0wkaoi6k_hsp7zq.jpg",
    },
    {
      name: "Jhallar Banarasi Handbag",
      imageUrl:
        "https://res.cloudinary.com/dumir6ksf/image/upload/f_auto,q_auto/v1688751690/woca/bvwjsv3c0itepco2woie_gsru74.jpg",
    },
    {
      name: "Bel Banarasi Handbag",
      imageUrl:
        "https://res.cloudinary.com/dumir6ksf/image/upload/f_auto,q_auto/v1688751378/woca/bqv21iyc0hzn6escdkvn_qeckoq.jpg",
    },
    {
      name: "Phool Banatasi Handbag",
      imageUrl:
        "https://res.cloudinary.com/dumir6ksf/image/upload/f_auto,q_auto/v1688751141/woca/vp5zxcpoy2sylos3wpcm_xhoik3.jpg",
    },
    {
      name: "Tulip Blue Book Tote Bag",
      imageUrl:
        "https://res.cloudinary.com/dumir6ksf/image/upload/f_auto,q_auto/v1688750696/woca/rrdz5j3uw9nfrlzahxvy_nmo24k.jpg",
    },
    {
      name: "Kala Banarasi Handbag",
      imageUrl:
        "https://res.cloudinary.com/dumir6ksf/image/upload/f_auto,q_auto/v1688750519/woca/lmzdx7pobs2pdfkc0twq_n0lxwx.jpg",
    },
    {
      name: "Silica Handembroidery Satchel Bag",
      imageUrl:
        "https://res.cloudinary.com/dumir6ksf/image/upload/f_auto,q_auto/v1688750185/woca/l0ui4mifp7mvccvmwhq2_numfhz.jpg",
    },
    {
      name: "Nakashi Handembroidery Satchel Bag",
      imageUrl:
        "https://res.cloudinary.com/dumir6ksf/image/upload/f_auto,q_auto/v1688749660/woca/ihtkuzzlhgmdhurxtfg3_ircmmt.jpg",
    },
    {
      name: "Maple Handembroidery Satchel Bag",
      imageUrl:
        "https://res.cloudinary.com/dtqa2hdju/image/upload/f_auto,q_auto/v1688745688/woca/alat5sisbsghfqrlzfug_gstc0e.jpg",
    },
    {
      name: "Nakashi Handembroidery Baugette Bag",
      imageUrl:
        "https://res.cloudinary.com/dtqa2hdju/image/upload/f_auto,q_auto/v1688745487/woca/jananfpjjioqu29ajd7j_wgsgb2.jpg",
    },
    {
      name: "Maple Handembroidery Baugette Bag",
      imageUrl:
        "https://res.cloudinary.com/dtqa2hdju/image/upload/f_auto,q_auto/v1688745247/woca/bpwlu38zenmzaqqw439f_n0x6fa.jpg",
    },
    {
      name: "Starry Night Handembroidery Clutch",
      imageUrl:
        "https://res.cloudinary.com/dtqa2hdju/image/upload/f_auto,q_auto/v1688745020/woca/vqyvx83d8533kcxbilcu_waqyw0.jpg",
    },
    {
      name: "PunchLime Handembroidery Clutch",
      imageUrl:
        "https://res.cloudinary.com/dtqa2hdju/image/upload/f_auto,q_auto/v1688744828/woca/hysolfsibwhwyzxuxekb_wnxter.jpg",
    },
    {
      name: "Drizzle Handembroidery Clutch",
      imageUrl:
        "https://res.cloudinary.com/dtqa2hdju/image/upload/f_auto,q_auto/v1688744613/woca/lf9qbehx7an80rod86fr_aralek.jpg",
    },
    {
      name: "Blueberry Handembroidery Clutch",
      imageUrl:
        "https://res.cloudinary.com/dtqa2hdju/image/upload/f_auto,q_auto/v1688744363/woca/udfrlt2siavmtrtstpec_t6dkp1.jpg",
    },
    {
      name: "Red velvet Handembroidery Clutch",
      imageUrl:
        "https://res.cloudinary.com/dtqa2hdju/image/upload/f_auto,q_auto/v1688744030/woca/hkbznzvcdmsen0dnf6sh_zhpwft.jpg",
    },
    {
      name: "Handembroidery Minty Backpack",
      imageUrl:
        "https://res.cloudinary.com/dfclentwy/image/upload/f_auto,q_auto/v1688743361/woca/yl80pc1e7cds0roq3q5j_wcqnmr.jpg",
    },
    {
      name: "Handembroidery Frosty Backpack",
      imageUrl:
        "https://res.cloudinary.com/dfclentwy/image/upload/f_auto,q_auto/v1688727208/woca/e5vds4odbjfclfiz1pl4_elfbdk.jpg",
    },
    {
      name: "Handembroidery Chulbuli Backpack",
      imageUrl:
        "https://res.cloudinary.com/dfclentwy/image/upload/f_auto,q_auto/v1688725114/woca/fkzhhpkwpr63rkmqjsxl_dy7b9d.jpg",
    },
    {
      name: "Handembroidery Anokhi Backpack",
      imageUrl:
        "https://res.cloudinary.com/dfclentwy/image/upload/f_auto,q_auto/v1688724676/woca/voylhaibmguzz8dtj57f_p4jujx.jpg",
    },
    {
      name: "Handembroidery Babli Backpack",
      imageUrl:
        "https://res.cloudinary.com/dfclentwy/image/upload/f_auto,q_auto/v1688724385/woca/lqxrpyewbtaqopmhc50c_mda9kx.jpg",
    },
    {
      name: "Tulip Beige Mini Book Bag",
      imageUrl:
        "https://res.cloudinary.com/dfclentwy/image/upload/f_auto,q_auto/v1688723668/woca/ydkshpvot7ejx00hzrv2_sojsln.jpg",
    },
    {
      name: "Tulip White Mini Book Bag",
      imageUrl:
        "https://res.cloudinary.com/dcmzqrrmu/image/upload/f_auto,q_auto/v1688667331/woca/xhfqducnguqxojl3lhnj_wyuczs.jpg",
    },
    {
      name: "Tulip Blue Mini Book Bag",
      imageUrl:
        "https://res.cloudinary.com/dcmzqrrmu/image/upload/f_auto,q_auto/v1688665035/woca/rhsip9grwd4tuc0apokd_yrhabp.jpg",
    },
    {
      name: "Tulip Navy Blue Book Tote bag",
      imageUrl:
        "https://res.cloudinary.com/dcmzqrrmu/image/upload/f_auto,q_auto/v1688664560/woca/u75fhp0nfhnn8cfdhawn_jjsbbq.jpg",
    },
    {
      name: "Tulip Pastel Pink Book Tote bag",
      imageUrl:
        "https://res.cloudinary.com/dhzkqexmk/image/upload/f_auto,q_auto/v1688662729/WOCA/e1wtkrf5b4gujjhf6lpq_sg8ehn.jpg",
    },
    {
      name: "Tulip Pastel Blue Book Tote Bag",
      imageUrl:
        "https://res.cloudinary.com/dhzkqexmk/image/upload/f_auto,q_auto/v1688661879/WOCA/qiynmvcprb2ns9vradmr_lko3zh.jpg",
    },
    {
      name: "Lilly Pastel Red Tote bag",
      imageUrl:
        "https://res.cloudinary.com/dhzkqexmk/image/upload/f_auto,q_auto/v1688661138/WOCA/zb96aa0i2z27ug7larfz_uzpk39.jpg",
    },
    {
      name: "Lilly Pastel Navy Blue Tote bag",
      imageUrl:
        "https://res.cloudinary.com/dhzkqexmk/image/upload/f_auto,q_auto/v1688660470/WOCA/oeebtdhcqszczuwcj9px_haj6cm.jpg",
    },
    {
      name: "Lilly Pastel Pink Tote bag",
      imageUrl:
        "https://res.cloudinary.com/dhzkqexmk/image/upload/f_auto,q_auto/v1688657068/WOCA/suxpp5xvyb2v99lgfz2n_xtrrhx.jpg",
    },
    {
      name: "Lilly Lemon Green Tote bag",
      imageUrl:
        "https://res.cloudinary.com/dhzkqexmk/image/upload/f_auto,q_auto/v1688656529/WOCA/y1ugkcvvtmxt0zvwo6ab_hfbe7x.jpg",
    },
    {
      name: "Sillica Red Sling bag",
      imageUrl:
        "https://res.cloudinary.com/dhzkqexmk/image/upload/f_auto,q_auto/v1688654618/WOCA/eh8comfc1khaq556ehel_hmn6ai.jpg",
    },
    {
      name: "Sillica Blue Sling bag",
      imageUrl:
        "https://res.cloudinary.com/dhzkqexmk/image/upload/f_auto,q_auto/v1688655132/WOCA/dkvtmwhojam22nqbs6sf_alkutd.jpg",
    },
  ];

  // Function to generate random values
  const generateRandomValues = () => {
    const name = names[Math.floor(Math.random() * names.length)];
    const place = places[Math.floor(Math.random() * places.length)];
    const time =
      timeAgoValues[Math.floor(Math.random() * timeAgoValues.length)];
    const productIndex = Math.floor(Math.random() * randomProducts.length);
    const product = randomProducts[productIndex];

    setRandomName(name);
    setRandomPlace(place);
    setRandomTime(time);
    setRandomProduct(product);
  };

  useEffect(() => {
    generateRandomValues();
    const timer = setInterval(() => {
      generateRandomValues();
      setIsVisible((prev) => !prev);
    }, 6000);

    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`slide-modal ${isVisible ? "visible" : ""}`}>
      <div className="content">
        <span className="close" onClick={() => setIsVisible(false)}>
          &times;
        </span>
        <div className="purchase-info">
          <div className="purchase-details">
            <span className="user-name ellipsis">
              {`${randomName} from ${randomPlace} purchased`}
            </span>
            <br />
            <span className="product-name ellipsis">
              {randomProduct ? randomProduct.name : ""}
            </span>
            <br />
            <span className="time-ago ellipsis">{randomTime}</span>
            <br />
          </div>
          <div className="product-image">
            {randomProduct && randomProduct.imageUrl ? (
              <img src={randomProduct.imageUrl} alt="Product" />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideModal;

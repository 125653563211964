import axios from "axios";
import {
  CREATE_PROMOTION_REQUEST,
  CREATE_PROMOTION_SUCCESS,
  CREATE_PROMOTION_FAIL,
  UPDATE_ISBOGO_REQUEST,
  UPDATE_ISBOGO_SUCCESS,
  UPDATE_ISBOGO_FAIL,
  UPDATE_ISBTGO_REQUEST,
  UPDATE_ISBTGO_SUCCESS,
  UPDATE_ISBTGO_FAIL,
  GET_ALL_PROMOTIONS_REQUEST,
  GET_ALL_PROMOTIONS_SUCCESS,
  GET_ALL_PROMOTIONS_FAIL,
} from "../constants/promotionConstants";

export const createPromotion = (promotionData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_PROMOTION_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
    };
    const { data } = await axios.post(
      "/api/v1/admin/promotions/new",
      promotionData,
      config
    );
    dispatch({ type: CREATE_PROMOTION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_PROMOTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateIsBOGO = (isBOGOValue) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ISBOGO_REQUEST });
    const { data } = await axios.put("/api/v1/admin/promotions/setIsBOGOTrue", {
      isBOGO: isBOGOValue,
    });
    dispatch({ type: UPDATE_ISBOGO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_ISBOGO_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateIsBTGO = (isBTGOValue) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ISBTGO_REQUEST });
    const { data } = await axios.put("/api/v1/admin/promotions/setIsBTGOTrue", {
      isBTGO: isBTGOValue,
    });
    dispatch({ type: UPDATE_ISBTGO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_ISBTGO_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAllPromotions = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PROMOTIONS_REQUEST });
    const { data } = await axios.get("/api/v1/promotions");
    dispatch({ type: GET_ALL_PROMOTIONS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_ALL_PROMOTIONS_FAIL,
      payload: error.response.data.message,
    });
  }
};

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./CartItemCard.css";
import { getSaleDis } from "../../actions/saleDisAction";
import { getWeekSaleDis } from "../../actions/weekSaleDisAction";
import { useDispatch, useSelector } from "react-redux";

const CartItemCard = ({ item, deleteCartItem }) => {
  const dispatch = useDispatch();
  const { saleDis } = useSelector((state) => state.saleDiss);
  let disSale = saleDis.map((c) => c.saleDis);
  disSale = disSale[0];
  useEffect(() => {
    dispatch(getSaleDis());
  }, [dispatch]);

  const { weekSaleDis } = useSelector((state) => state.weekSaleDiss);
  let disWeekSale = weekSaleDis.map((c) => c.weekSaleDis);
  useEffect(() => {
    dispatch(getWeekSaleDis());
  }, [dispatch]);
  disWeekSale = disWeekSale[0];
  return (
    <div className="CartItemCard">
      <img src={item.image} alt="sad" />
      <div>
        <Link to={`/product/${item.name}/${item.product}`}>
          <strong>{item.name}</strong>
        </Link>
        <span>
          {" "}
          {`${
            disSale ? disSale : item.weekSale ? disWeekSale : item.discount
          }` > 0
            ? `Save Rs.${Math.floor(
                (item.price / 100) *
                  `${
                    disSale
                      ? disSale
                      : item.weekSale
                      ? disWeekSale
                      : item.discount
                  }`
              )}`
            : ""}
        </span>
        <h4>
          {`${
            disSale ? disSale : item.weekSale ? disWeekSale : item.discount
          }` > 0
            ? `Discounted Price: Rs.${Math.floor(
                item.price -
                  (item.price / 100) *
                    `${
                      disSale
                        ? disSale
                        : item.weekSale
                        ? disWeekSale
                        : item.discount
                    }`
              )}`
            : `Price: Rs.${item.price}`}
        </h4>

        <p onClick={() => deleteCartItem(item.product)}>Remove</p>
      </div>
    </div>
  );
};

export default CartItemCard;

import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAdminBanner,
  deleteBanner,
  clearErrors,
} from "../../actions/bannerAction";
import MetaData from "../layout/MetaData";
import "./ProductList.css";
import Sidebar from "./Sidebar";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { DELETE_BANNER_RESET } from "../../constants/bannerConstants";

const BannerList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const { error, banner } = useSelector((state) => state.banners);
  const { error: deleteError, isDeleted } = useSelector(
    (state) => state.banner
  );

  const deleteInfoHandler = (id) => {
    dispatch(deleteBanner(id));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("Banner Deleted Successfully");
      navigate("/admin/dashboard");
      dispatch({ type: DELETE_BANNER_RESET });
    }

    dispatch(getAdminBanner());
  }, [dispatch, alert, error, deleteError, isDeleted, navigate]);

  const columns = [
    { field: "id", headerName: "Banner ID", minWidth: 200, flex: 0.5 },

    {
      field: "banner",
      headerName: "Banner",
      minWidth: 350,
      flex: 1,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() =>
                deleteInfoHandler(params.getValue(params.id, "id"))
              }
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
    },
  ];

  const rows = [];

  banner &&
    banner.forEach((item) => {
      rows.push({
        id: item._id,
        banner: item.name,
      });
    });

  return (
    <>
      <MetaData title={`ALL BANNERS - Admin`} />

      <div className="dashboard">
        <Sidebar />
        <div className="productListContainer">
          <h1 id="productListHeading">ALL BANNERS</h1>

          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </>
  );
};

export default BannerList;

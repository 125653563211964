export const ALL_COLLECTION_REQUEST = "ALL_COLLECTION_REQUEST";
export const ALL_COLLECTION_SUCCESS = "ALL_COLLECTION_SUCCESS";
export const ALL_COLLECTION_FAIL = "ALL_COLLECTION_FAIL";
export const ADMIN_COLLECTION_REQUEST = "ADMIN_COLLECTION_REQUEST";
export const ADMIN_COLLECTION_SUCCESS = "ADMIN_COLLECTION_SUCCESS";
export const ADMIN_COLLECTION_FAIL = "ADMIN_COLLECTION_FAIL";
export const NEW_COLLECTION_REQUEST = "NEW_COLLECTION_REQUEST";
export const NEW_COLLECTION_SUCCESS = "NEW_COLLECTION_SUCCESS";
export const NEW_COLLECTION_RESET = "NEW_COLLECTION_RESET";
export const NEW_COLLECTION_FAIL = "NEW_COLLECTION_FAIL";
export const DELETE_COLLECTION_REQUEST = "DELETE_COLLECTION_REQUEST";
export const DELETE_COLLECTION_SUCCESS = "DELETE_COLLECTION_SUCCESS";
export const DELETE_COLLECTION_RESET = "DELETE_COLLECTION_RESET";
export const DELETE_COLLECTION_FAIL = "DELETE_COLLECTION_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

import React from "react";
import profilePng from "../../image/Profile.png";
import { Rating } from "@mui/material";

const ReviewCard = ({ review }) => {
  const options = {
    size: "large",
    readOnly: true,
    precision: 0.5,
    value: review.rating,
  };

  return (
    <>
      {/* new design */}
      <section className="review_sec">
        <div className="review_container">
          <div className="review_content">
            <div className="review_card">
              <div className="card_content">
                <div className="user_img">
                  <img
                    src={review.avatar ? review.avatar : profilePng}
                    alt="user"
                  />
                </div>
                <div className="user_name">
                  <h4>{review.name}</h4>
                  <span>{review.comment}</span>
                </div>
                <div></div>
                <Rating {...options} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReviewCard;

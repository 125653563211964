import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, createCollection } from "../../actions/collectionAction";
import MetaData from "../layout/MetaData";
import "./NewProduct.css";
import { Button } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { NEW_COLLECTION_RESET } from "../../constants/collectionConstants";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";

const NewCollection = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { loading, error, success } = useSelector(
    (state) => state.newPrintCollection
  );

  const [printCollection, setPrintCollection] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Print Collection Created Successfully");
      navigate("/admin/collections");
      dispatch({ type: NEW_COLLECTION_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const createCategorySubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("printCollection", printCollection);
    myForm.set("image", image);

    dispatch(createCollection(myForm));
  };

  return (
    <>
      <MetaData title="Create Info" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createCategorySubmitHandler}
          >
            <h1>Create Collection</h1>

            <div>
              <DescriptionIcon />
              <input
                type="text"
                placeholder="Collection"
                required
                value={printCollection}
                onChange={(e) => setPrintCollection(e.target.value)}
              />
            </div>
            <div>
              <LinkIcon />
              <input
                type="text"
                placeholder="Collection Image Url"
                required
                value={image}
                onChange={(e) => setImage(e.target.value)}
              />
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewCollection;

export const ALL_CATEGORYIMG_REQUEST = "ALL_CATEGORYIMG_REQUEST";
export const ALL_CATEGORYIMG_SUCCESS = "ALL_CATEGORYIMG_SUCCESS";
export const ALL_CATEGORYIMG_FAIL = "ALL_CATEGORYIMG_FAIL";
export const ADMIN_CATEGORYIMG_REQUEST = "ADMIN_CATEGORYIMG_REQUEST";
export const ADMIN_CATEGORYIMG_SUCCESS = "ADMIN_CATEGORYIMG_SUCCESS";
export const ADMIN_CATEGORYIMG_FAIL = "ADMIN_CATEGORYIMG_FAIL";
export const NEW_CATEGORYIMG_REQUEST = "NEW_CATEGORYIMG_REQUEST";
export const NEW_CATEGORYIMG_SUCCESS = "NEW_CATEGORYIMG_SUCCESS";
export const NEW_CATEGORYIMG_RESET = "NEW_CATEGORYIMG_RESET";
export const NEW_CATEGORYIMG_FAIL = "NEW_CATEGORYIMG_FAIL";
export const DELETE_CATEGORYIMG_REQUEST = "DELETE_CATEGORYIMG_REQUEST";
export const DELETE_CATEGORYIMG_SUCCESS = "DELETE_CATEGORYIMG_SUCCESS";
export const DELETE_CATEGORYIMG_RESET = "DELETE_CATEGORYIMG_RESET";
export const DELETE_CATEGORYIMG_FAIL = "DELETE_CATEGORYIMG_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

import React, { useEffect } from "react";
import CheckOutSteps from "../Cart/CheckOutSteps";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import "./ConfirmOrder.css";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getWeekSaleDis } from "../../actions/weekSaleDisAction";
import { getAllPromotions } from "../../actions/promotionAction";

const ConfirmOrder = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { promotions } = useSelector((state) => state.promotion);
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { weekSaleDis } = useSelector((state) => state.weekSaleDiss);
  let disWeekSale = weekSaleDis.map((c) => c.weekSaleDis);
  useEffect(() => {
    dispatch(getWeekSaleDis());
    dispatch(getAllPromotions());
  }, [dispatch]);
  disWeekSale = disWeekSale[0];

  const subtotal = cartItems.reduce(
    (acc, item) =>
      acc +
      item.quantity *
        `${
          `${
            props.discount
              ? props.discount
              : item.weekSale
              ? disWeekSale
              : item.discount
          }` > 0
            ? `${Math.floor(
                item.price -
                  (item.price / 100) *
                    `${
                      props.discount
                        ? props.discount
                        : item.weekSale
                        ? disWeekSale
                        : item.discount
                    }`
              )}`
            : `${item.price}`
        }`,
    0
  );

  const shippingCharges = subtotal > 1100 ? 0 : 150;

  const discount =
    cartItems.length > 2 ||
    (Math.max(...cartItems.map((item) => item.quantity)) > 1 &&
      cartItems.length > 1) ||
    Math.max(...cartItems.map((item) => item.quantity)) > 2
      ? Math.min(
          ...cartItems.map(
            (item) =>
              `${
                `${
                  props.discountit
                    ? props.discount
                    : item.weekSale
                    ? disWeekSale
                    : item.discount
                }` > 0
                  ? `${Math.floor(
                      item.price -
                        (item.price / 100) *
                          `${
                            props.discount
                              ? props.discount
                              : item.weekSale
                              ? disWeekSale
                              : item.discount
                          }`
                    )}`
                  : `${item.price}`
              }`
          )
        )
      : 0;

  const discountBogo =
    cartItems.length > 1 ||
    Math.max(...cartItems.map((item) => item.quantity)) > 1
      ? Math.min(
          ...cartItems.map(
            (item) =>
              `${
                `${
                  props.discount
                    ? props.discount
                    : item.weekSale
                    ? disWeekSale
                    : item.discount
                }` > 0
                  ? `${Math.floor(
                      item.price -
                        (item.price / 100) *
                          `${
                            props.discount
                              ? props.discount
                              : item.weekSale
                              ? disWeekSale
                              : item.discount
                          }`
                    )}`
                  : `${item.price}`
              }`
          )
        )
      : 0;

  const isBOGO =
    promotions.success &&
    promotions.promotions &&
    promotions.promotions[0].isBOGO;
  const isBTGO =
    promotions.success &&
    promotions.promotions &&
    promotions.promotions[0].isBTGO;

  const totalPrice = isBOGO
    ? Math.floor(subtotal + shippingCharges - discountBogo)
    : isBTGO
    ? Math.floor(subtotal + shippingCharges - discount)
    : Math.floor(subtotal + shippingCharges);

  const address = `${shippingInfo.address}, ${shippingInfo.city}, ${shippingInfo.state}, ${shippingInfo.pinCode}, ${shippingInfo.country}`;
  const userName = shippingInfo.name;
  const userEmail = shippingInfo.email;
  const proceedToPayment = () => {
    if (user.name === "guest") {
      const data = {
        subtotal,
        shippingCharges,
        totalPrice,
        userEmail,
        userName,
      };
      sessionStorage.setItem("orderInfo", JSON.stringify(data));
    } else {
      const data = {
        subtotal,
        shippingCharges,
        totalPrice,
      };
      sessionStorage.setItem("orderInfo", JSON.stringify(data));
    }

    navigate("/process/payment");
  };

  return (
    <>
      <MetaData title="Confirm Order--Woca Designs" />
      <CheckOutSteps activeStep={1} />
      <div className="confirmOrderPage">
        <div>
          <div className="confirmshippingArea">
            <Typography>Shipping Info:</Typography>
            <div className="confirmshippingAreaBox">
              <div>
                <strong>
                  <p>Name:</p>
                </strong>
                <span>{user.name === "guest" ? userName : user.name}</span>
              </div>
              <div>
                <strong>
                  {" "}
                  <p>Email:</p>
                </strong>
                <span>{user.name === "guest" ? userEmail : user.email}</span>
              </div>
              <div>
                <strong>
                  {" "}
                  <p>Phone:</p>
                </strong>
                <span>{shippingInfo.phoneNo}</span>
              </div>
              <div>
                <strong>
                  {" "}
                  <p>Address:</p>
                </strong>
                <span>{address}</span>
              </div>
            </div>
          </div>
          <div className="confirmCartItems">
            <Typography>Your Cart Items:</Typography>
            <div className="confirmCartItemsContainer">
              {cartItems &&
                cartItems.map((item) => (
                  <div key={item.product}>
                    <img src={item.image} alt="Product" />
                    <Link to={`/product/${item.product}`}>
                      {item.name}
                    </Link>{" "}
                    <span>
                      {item.quantity} X ₹
                      {`${
                        `${
                          props.discount
                            ? props.discount
                            : item.weekSale
                            ? disWeekSale
                            : item.discount
                        }` > 0
                          ? `${Math.floor(
                              item.price -
                                (item.price / 100) *
                                  `${
                                    props.discount
                                      ? props.discount
                                      : item.weekSale
                                      ? disWeekSale
                                      : item.discount
                                  }`
                            )}`
                          : `${item.price}`
                      }`}{" "}
                      <b>
                        ₹
                        {`${
                          `${
                            props.discount
                              ? props.discount
                              : item.weekSale
                              ? disWeekSale
                              : item.discount
                          }` > 0
                            ? `${Math.floor(
                                item.price -
                                  (item.price / 100) *
                                    `${
                                      props.discount
                                        ? props.discount
                                        : item.weekSale
                                        ? disWeekSale
                                        : item.discount
                                    }`
                              )}`
                            : `${item.price}`
                        }` * item.quantity}
                      </b>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/*  */}
        <div>
          <div className="orderSummary">
            <Typography>Order Summery</Typography>
            <div>
              <div>
                <strong>
                  {" "}
                  <p>Subtotal:</p>
                </strong>
                <span>₹{subtotal}</span>
              </div>
              <div>
                <strong>
                  {" "}
                  <p>Shipping Charges:</p>
                </strong>
                <span>
                  {shippingCharges > 0 ? shippingCharges : "Free Shipping"}
                </span>
              </div>
              {isBOGO || isBTGO ? (
                <div>
                  <strong>
                    {" "}
                    <p>Discount: {isBTGO ? "BTGO" : isBOGO ? "BOGO" : ""}</p>
                  </strong>
                  {isBOGO && discountBogo === 0 ? (
                    <span style={{ color: "red" }}>
                      Add one more item to avail this offer
                    </span>
                  ) : isBTGO && discount === 0 ? (
                    <span style={{ color: "red" }}>
                      Add more item to avail this offer
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>
                      -₹{isBOGO ? discountBogo : isBTGO ? discount : ""}
                    </span>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="orderSummaryTotal">
              <p>
                <b>Total:</b>
              </p>
              <span>₹{totalPrice}</span>
            </div>
            {/* coupons system */}

            <button onClick={proceedToPayment}>Proceed To Payment</button>
            <Link to="/cart">
              <button>Back to Cart</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmOrder;

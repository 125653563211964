import axios from "axios";

import {
  ALL_SALEDIS_REQUEST,
  ALL_SALEDIS_SUCCESS,
  ALL_SALEDIS_FAIL,
  ADMIN_SALEDIS_REQUEST,
  ADMIN_SALEDIS_SUCCESS,
  ADMIN_SALEDIS_FAIL,
  NEW_SALEDIS_REQUEST,
  NEW_SALEDIS_SUCCESS,
  NEW_SALEDIS_FAIL,
  DELETE_SALEDIS_REQUEST,
  DELETE_SALEDIS_SUCCESS,
  DELETE_SALEDIS_FAIL,
  CLEAR_ERRORS,
} from "../constants/saleDisConstants";

export const getSaleDis = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_SALEDIS_REQUEST,
    });
    const { data } = await axios.get("/api/v1/saleDis");

    dispatch({
      type: ALL_SALEDIS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_SALEDIS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAdminSaleDis = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_SALEDIS_REQUEST });
    const { data } = await axios.get("/api/v1/saleDis");
    dispatch({
      type: ADMIN_SALEDIS_SUCCESS,
      payload: data.saleDis,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_SALEDIS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const createSaleDis = (saleDisData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_SALEDIS_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `/api/v1/admin/saleDis/new`,
      saleDisData,
      config
    );

    dispatch({
      type: NEW_SALEDIS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_SALEDIS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteSaleDis = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SALEDIS_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/saleDis/${id}`);

    dispatch({
      type: DELETE_SALEDIS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_SALEDIS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

export const ALL_CATEGORY_REQUEST = "ALL_CATEGORY_REQUEST";
export const ALL_CATEGORY_SUCCESS = "ALL_CATEGORY_SUCCESS";
export const ALL_CATEGORY_FAIL = "ALL_CATEGORY_FAIL";
export const ADMIN_CATEGORY_REQUEST = "ADMIN_CATEGORY_REQUEST";
export const ADMIN_CATEGORY_SUCCESS = "ADMIN_CATEGORY_SUCCESS";
export const ADMIN_CATEGORY_FAIL = "ADMIN_CATEGORY_FAIL";
export const NEW_CATEGORY_REQUEST = "NEW_CATEGORY_REQUEST";
export const NEW_CATEGORY_SUCCESS = "NEW_CATEGORY_SUCCESS";
export const NEW_CATEGORY_RESET = "NEW_CATEGORY_RESET";
export const NEW_CATEGORY_FAIL = "NEW_CATEGORY_FAIL";
export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_RESET = "DELETE_CATEGORY_RESET";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

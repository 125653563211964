import React, { useEffect, useState } from "react";
import MetaData from "../layout/MetaData";
import Sidebar from "./Sidebar";
import "./ProductList.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPromotions,
  updateIsBOGO,
  updateIsBTGO,
} from "../../actions/promotionAction";

const Promotion = () => {
  const dispatch = useDispatch();
  const { promotions } = useSelector((state) => state.promotion);
  // console.log(promotions);

  useEffect(() => {
    dispatch(getAllPromotions());
  }, [dispatch]);

  // Initialize state based on promotions object

  const [isBOGO, setIsBOGO] = useState(
    promotions.success && promotions.promotions
      ? promotions.promotions[0].isBOGO
      : false
  );
  const [isBTGO, setIsBTGO] = useState(
    promotions.success && promotions.promotions
      ? promotions.promotions[0].isBTGO
      : false
  );

  const handleUpdateIsBOGO = () => {
    const updatedIsBOGO = !isBOGO;
    dispatch(updateIsBOGO(updatedIsBOGO));
    setIsBOGO(updatedIsBOGO);
  };

  const handleUpdateIsBTGO = () => {
    const updatedIsBTGO = !isBTGO;
    dispatch(updateIsBTGO(updatedIsBTGO));
    setIsBTGO(updatedIsBTGO);
  };

  return (
    <>
      <MetaData title={`ALL Promotions - Admin`} />
      <div className="dashboard">
        <Sidebar />
        <div className="productListContainer">
          <h1 id="productListHeading">ALL PROMOTIONS</h1>
          <div>
            {promotions.success ? (
              <ul
                style={{
                  listStyleType: "none",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  padding: "20px",
                  borderRadius: "8px",
                  background: "#fff",
                  textAlign: "center",
                }}
              >
                <li
                  style={{
                    margin: "20px 0",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <p style={{ fontSize: "18px" }}>
                      Is BOGO: {isBOGO ? "True" : "False"}
                    </p>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "blue",
                      }}
                    >
                      Buy One get One
                    </span>
                    <div style={{ marginTop: "10px" }}>
                      {isBTGO ? (
                        <span style={{ fontSize: "14px", color: "red" }}>
                          (To turn it on, you need to turn off BTGO)
                        </span>
                      ) : (
                        <button
                          style={{
                            padding: "10px 20px",
                            background: "green",
                            color: "white",
                            border: "none",
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                          onClick={handleUpdateIsBOGO}
                        >
                          {isBOGO ? "Set to False" : "Set to True"}
                        </button>
                      )}
                    </div>
                  </div>

                  <div style={{ flex: 1 }}>
                    <p style={{ fontSize: "18px" }}>
                      Is BTGO: {isBTGO ? "True" : "False"}
                    </p>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "blue",
                      }}
                    >
                      Buy Two get One
                    </span>
                    <div style={{ marginTop: "10px" }}>
                      {isBOGO ? (
                        <span style={{ fontSize: "14px", color: "red" }}>
                          (To turn it on, you need to turn off BOGO)
                        </span>
                      ) : (
                        <button
                          style={{
                            padding: "10px 20px",
                            background: "green",
                            color: "white",
                            border: "none",
                            cursor: "pointer",
                            fontSize: "16px",
                          }}
                          onClick={handleUpdateIsBTGO}
                        >
                          {isBTGO ? "Set to False" : "Set to True"}
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            ) : (
              <p>Failed to fetch promotions or no promotions available</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Promotion;

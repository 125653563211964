import {
  ADD_TO_CART,
  GET_CART_DETAILS,
  REMOVE_CART_ITEM,
  SAVE_SHIPPING_INFO,
} from "../constants/cartConstants";
import axios from "axios";

// Add to Cart
export const addItemsToCart = (id, quantity) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/v1/product/${id}`);

  dispatch({
    type: ADD_TO_CART,
    payload: {
      product: data.product._id,
      name: data.product.name,
      price: data.product.price,
      discount: data.product.discount,
      weekSale: data.product.weekSale,
      image: data.product.images[0].url,
      stock: data.product.stock,
      quantity,
    },
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
  try {
    await axios.post("/api/v1/cart/add", {
      productCartId: data.product.name,
    });
  } catch (error) {
    // Handle the error
    console.error("Failed to save cart item to the database", error);
  }
};

// REMOVE FROM CART
export const removeItemsFromCart = (id) => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_CART_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

// SAVE SHIPPING INFO
export const saveShippingInfo = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data,
  });

  localStorage.setItem("shippingInfo", JSON.stringify(data));
};

export const getCartDetails = () => async (dispatch) => {
  try {
    const response = await axios.get("/api/v1/cart");

    dispatch({
      type: GET_CART_DETAILS,
      payload: response.data.cart,
    });
  } catch (error) {
    // Handle the error
    console.error("Failed to retrieve cart details", error);
  }
};

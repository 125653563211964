import React from "react";
import { Link } from "react-router-dom";
import { Rating } from "@mui/material";
import "./ProductHome.css";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { addItemsToCart } from "../../actions/cartAction";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { addItemsToWish } from "../../actions/wishAction";

const ProductCardRecent = ({ product }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const quantity = 1;
  const addToCartHandler = () => {
    dispatch(addItemsToCart(product.product, quantity));
    alert.success("Item Added To Cart");
  };
  const addToWishHandler = () => {
    dispatch(addItemsToWish(product.product));
    alert.success("Item Added To Wishlist");
  };
  const options = {
    size: "large",
    value: product.ratings,
    readOnly: true,
    precision: 0.5,
  };
  return (
    <>
      <div className="mainH">
        <div className="containerH">
          <span
            className={
              `${product.discount}` > 1 || product.stock === 0
                ? "discHH"
                : "none"
            }
          >
            {product.stock === 0
              ? "Sold"
              : `${product.discount === 0 ? "" : product.discount + `%`}`}
          </span>
          <div className="cardH">
            <div className="imgBoxH">
              <img src={product.image} alt={product.name} />
              <ul className="actionH">
                <li onClick={addToWishHandler}>
                  <FavoriteBorderOutlinedIcon />
                  <span>Add to Wishlist</span>
                </li>
                <li onClick={addToCartHandler}>
                  <LocalMallOutlinedIcon />
                  <span>Add to Cart</span>
                </li>
                <li>
                  <VisibilityOutlinedIcon />
                  <span>Ouickview</span>
                </li>
              </ul>
            </div>
            <Link
              to={`/product/${product.name}/${product.product}`}
              className="contentH"
            >
              <div className="productNameH">
                <h3>{product.name}</h3>
              </div>
              <div className="price_ratingH" style={{ marginTop: "5px" }}>
                <h2
                  className={`${product.discount}` > 1 ? "discH" : "none"}
                >{`Rs. ${product.price}`}</h2>
                <h2>
                  {" "}
                  {`${product.discount}` > 0
                    ? `Rs.${Math.floor(
                        product.price -
                          (product.price / 100) * `${product.discount}`
                      )}`
                    : ""}
                </h2>
                <div className="ratingH">
                  <Rating {...options} />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCardRecent;

import React, { useState } from "react";

const Modal = ({ isOpen, onClose, children, backgroundImage }) => {
  const [isHovered, setIsHovered] = useState(false); // Initialize the state here

  if (!isOpen) return null;

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%) scale(${isHovered ? 1.1 : 1})`,
    width: "300px",
    height: "300px",
    backgroundColor: "white",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
    padding: "20px",
    zIndex: 1000,
    background: `url(${backgroundImage}) center/cover no-repeat`,
    transition: "transform 0.3s ease",
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "5px",
    right: "15px",
    cursor: "pointer",
    fontSize: "24px",
    fontWeight: "bold",
  };

  return (
    <div>
      {isOpen && <div style={overlayStyle} onClick={onClose}></div>}
      <div
        style={modalStyle}
        onClick={onClose}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <span style={closeButtonStyle} onClick={onClose}>
          &times;
        </span>
        {children}
      </div>
    </div>
  );
};

export default Modal;

import React from "react";
import { Link } from "react-router-dom";
import MetaData from "../../layout/MetaData";
import "../Privacy Policy Page/PrivacyPolicy.css";

const Exchange = () => {
  return (
    <>
      <MetaData title="Return and Exchange--Woca Designs" />
      <h2 className="productsHeading">Return and Exchange</h2>.
      <div className="mainPrivacy">
        <div className="privacyPolicy">
          <p>
            Kindly refer to the return policy below to know if your order is
            eligible for return/ exchange. Incase your return is not getting
            accepted here this means your order is not eligible. You can reach
            us at +91 7985350600 or email: care@wocadesigns.in. We usually reply
            within 48 working hours.
          </p>
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/dbdez1ptp/image/upload/v1667297706/other%20img/exchange-wocadesigns_x2dr9b.png"
            alt="ExchangeandReturn"
          />
        </div>
      </div>
      <div className="privacyPolicy">
        <h2>Return Policy:</h2>
        <p>
          We will accept the return within 15 days of receipt of the product by
          the customer.<Link to="/returnPolicy">CLICK HERE</Link> to know more
        </p>
        <h2>Refund Policy:</h2>
        <p>
          Money will be refunded for any return as per the return policy after
          deducting the courier charges. Kindly refer to the return policy to
          know the eligibility for refunds.
        </p>
      </div>
    </>
  );
};

export default Exchange;

import React, { useEffect, useState } from "react";
import Slider from "../layout/Banner/Slider";
import Category from "../layout/Category/Category";
import ProductCardHome from "../ProductCard/ProductCardHome";
import "./Home.css";
import MetaData from "../layout/MetaData";
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { getCollection } from "../../actions/collectionAction";
import PrintCollectionCard from "../ProductCard/PrintCollectionCard";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BlogCard from "../Pages/Blogs/BlogCard";
import { getBlogs } from "../../actions/blogAction";
import Modal from "./Modal";
import SlideModal from "./SlideModal";

const Home = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  let { loading, error, products } = useSelector((state) => state.products);
  products = products.sort((a, b) =>
    a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
  );
  const { collection } = useSelector((state) => state.printCollections);

  const bestseller = products.filter((p) => p.ratings > 4);
  const weekendSale = products.filter((p) => p.weekSale);
  const wImg =
    "https://res.cloudinary.com/dbdez1ptp/image/upload/v1667297500/other%20img/WhatsApp_Image_2022-11-01_at_15.41.29_ond2oa.jpg";
  let { blogs } = useSelector((state) => state.blogs);
  blogs = blogs.sort((a, b) =>
    a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
  );

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    const isModalShown = localStorage.getItem("isModalShown");

    if (!isModalShown) {
      setIsModalOpen(true);
      localStorage.setItem("isModalShown", "true");
      setTimeout(() => {
        localStorage.removeItem("isModalShown");
      }, 900000);
    }
    dispatch(getCollection());
    dispatch(getProduct());
    dispatch(getBlogs());
  }, [dispatch, alert, error]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData
            title="Woca Designs-Perfect Pick for your Classy Style"
            description="Shop Online from a range of Handbags, Shoulder Bags &amp; Sleeves, Ladies Purse, Laptop Bag, Travel Bag, Totes, Satchel, Baguette, Backpack, college and office bags. Designed For Young Women and Girls. Using premium cotton &amp; Handcrafted sustainable vegan fabrics. Easy Return/Exchange, COD Available, Free Shipping, INDIA"
            imageAlt="Bringing the Indian Handcraft to the modern world...!!"
            imageUrl={wImg}
          />
          <SlideModal />
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            backgroundImage="https://res.cloudinary.com/dbdez1ptp/image/upload/f_auto,q_auto/v1701846144/modal/Pink_and_White_Modern_Feminine_Fashion_Promo_Free_Shipping_Instagram_Post_1_cnuhl0.jpg"
          ></Modal>

          <Slider />
          {/* <Slide left> */}
          <Category />
          {/* </Slide> */}
          {weekendSale.length > 1 ? (
            <h2 className="productHeading">Weekend Sale..!!</h2>
          ) : (
            ""
          )}
          {weekendSale.length > 1 ? (
            <Link to="/weekendsale">
              <span className="viewBtn">
                View All Products <ArrowForwardIcon />
              </span>
            </Link>
          ) : (
            ""
          )}
          <Swiper
            slidesPerView={4}
            spaceBetween={5}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              "@1.50": {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <div className="productConatiner">
              {weekendSale &&
                weekendSale.map((product) => (
                  <SwiperSlide key={product._id}>
                    <ProductCardHome product={product} />
                  </SwiperSlide>
                ))}
            </div>
          </Swiper>

          <div className="iconsDisplay">
            <div className="iconContent">
              {" "}
              <h1>Only the Good Goes In.!!</h1>
              <p>
                Woca effortlessly defines a modern Indian classic. Bringing
                together the contemporary and the traditional, Woca weaves
                subtle elegance into your lifestyle.
              </p>
              <Link to="/about">
                <span className="iconBtn">
                  Our Story <ArrowForwardIcon />
                </span>
              </Link>
            </div>
            <div>
              <img
                src="https://res.cloudinary.com/dbdez1ptp/image/upload/v1667294896/home%20icon/handmade_etd6rw.png"
                alt="HandmadeWocaDesigns"
              />
              <h3>
                Handcrafted <br /> Love
              </h3>
            </div>
            <div>
              <img
                src="https://res.cloudinary.com/dbdez1ptp/image/upload/v1667294897/home%20icon/made_in_india_cbkmc4.png"
                alt="madeinIndia-WocaDesigns"
              />
              <h3>
                Proudly <br /> Indian
              </h3>
            </div>
            <div>
              <img
                src="https://res.cloudinary.com/dbdez1ptp/image/upload/v1667294896/home%20icon/women_empowerment_woca_designs_qvy5l2.png"
                alt="HandmadeWocaDesigns"
              />
              <h3>
                Empowering <br /> Women
              </h3>
            </div>
          </div>
          <h2 className="productHeading">Carry What Matters..!!</h2>
          <Link to="/products">
            <span className="viewBtn">
              View All Products <ArrowForwardIcon />
            </span>
          </Link>
          <Swiper
            slidesPerView={4}
            spaceBetween={5}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              "@1.50": {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <div className="productConatiner">
              {products &&
                products.map((product) => (
                  <SwiperSlide key={product._id}>
                    <ProductCardHome product={product} />
                  </SwiperSlide>
                ))}
            </div>
          </Swiper>
          <div className="aboutHome">
            <div className="artistImage">
              <img src={wImg} alt="" />
            </div>
            <div className="artistContent">
              <h2>Pillars of Woca </h2>
              <p>
                {" "}
                Woca Designs believe “Each time a woman stands up for herself,
                she stands up for all women”. Rinki dee is been working with us
                more than 5years .She is most hardworking women who believe
                there is no age for learning . Woca has helped women like Rinki
                dee become monetarily independent while also helping them
                develop their individual skills. She loved when she is able to
                contribute for her family. She is the one who do the sampling
                and handle the production of our unit.
              </p>
            </div>
          </div>
          <h2 className="productHeading">Our Signature Embroidery..!!</h2>
          <Link to="/collections">
            <span className="viewBtn">
              View All Collection <ArrowForwardIcon />
            </span>
          </Link>
          <Swiper
            slidesPerView={4}
            spaceBetween={5}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              "@1.50": {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <div className="productConatiner">
              {collection &&
                collection.map((collect) => (
                  <SwiperSlide key={collect._id}>
                    <PrintCollectionCard collect={collect} />
                  </SwiperSlide>
                ))}
            </div>
          </Swiper>

          {bestseller.length > 3 ? (
            <h2 className="productHeading">Bestseller..!!</h2>
          ) : (
            ""
          )}
          {bestseller.length > 3 ? (
            <Link to="/bestseller">
              <span className="viewBtn">
                View All Bestseller <ArrowForwardIcon />
              </span>
            </Link>
          ) : (
            ""
          )}
          <Swiper
            slidesPerView={4}
            spaceBetween={5}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              "@1.50": {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <div className="productConatiner">
              {bestseller &&
                bestseller.length > 3 &&
                bestseller.map((product) => (
                  <SwiperSlide key={product._id}>
                    <ProductCardHome product={product} />
                  </SwiperSlide>
                ))}
            </div>
          </Swiper>
          {blogs.length > 3 ? (
            <h2 className="productHeading">Blogs..!!</h2>
          ) : (
            ""
          )}
          {blogs.length > 3 ? (
            <Link to="/blogs">
              <span className="viewBtn">
                View All Blogs <ArrowForwardIcon />
              </span>
            </Link>
          ) : (
            ""
          )}
          <Swiper
            slidesPerView={4}
            spaceBetween={5}
            pagination={{
              clickable: true,
              dynamicBullets: true,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              "@1.50": {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <div className="productConatiner">
              {blogs &&
                blogs.length > 3 &&
                blogs.map((blog) => (
                  <SwiperSlide key={blog._id}>
                    <BlogCard blog={blog} />
                  </SwiperSlide>
                ))}
            </div>
          </Swiper>
          <div className="elfsight-app-cfbf3d96-14ee-4b6e-bd33-85b81d5f4c5c"></div>
        </>
      )}
    </>
  );
};

export default Home;

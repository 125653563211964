import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getBlogDetails } from "../../../actions/blogAction";
import Loader from "../../layout/Loader/Loader";
import MetaData from "../../layout/MetaData";
import "./BlogCard.css";

const BlogPage = () => {
  const dispatch = useDispatch();
  const { blog, loading } = useSelector((state) => state.blog);
  const params = useParams();
  useEffect(() => {
    dispatch(getBlogDetails(params.id));
  }, [dispatch, params.id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title={`${blog.Title} -- Woca Designs`} />
          <Link className="blog-goBack" to="/blogs">
            <span> &#8592;</span> <span>Go Back</span>
          </Link>
          <header className="blogHead">
            <h1>{blog.Title}</h1>
            <p className="blog-date">Published By: {blog.authorName}</p>
          </header>
          <div className="blog-wrap">
            <img src={blog.image} alt="cover" />
            <p className="blog-desc">{blog.description}</p>
          </div>
        </>
      )}
    </>
  );
};

export default BlogPage;

import {
  ALL_SALEDIS_REQUEST,
  ALL_SALEDIS_SUCCESS,
  ALL_SALEDIS_FAIL,
  ADMIN_SALEDIS_REQUEST,
  ADMIN_SALEDIS_SUCCESS,
  ADMIN_SALEDIS_FAIL,
  NEW_SALEDIS_REQUEST,
  NEW_SALEDIS_SUCCESS,
  NEW_SALEDIS_RESET,
  NEW_SALEDIS_FAIL,
  DELETE_SALEDIS_REQUEST,
  DELETE_SALEDIS_SUCCESS,
  DELETE_SALEDIS_RESET,
  DELETE_SALEDIS_FAIL,
  CLEAR_ERRORS,
} from "../constants/saleDisConstants";

export const saleDisReducer = (state = { saleDis: [] }, action) => {
  switch (action.type) {
    case ALL_SALEDIS_REQUEST:
    case ADMIN_SALEDIS_REQUEST:
      return {
        loading: true,
        saleDis: [],
      };
    case ALL_SALEDIS_SUCCESS:
      return {
        loading: false,
        saleDis: action.payload.saleDis,
      };
    case ADMIN_SALEDIS_SUCCESS:
      return {
        loading: false,
        saleDis: action.payload,
      };
    case ALL_SALEDIS_FAIL:
    case ADMIN_SALEDIS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newSaleDisReducer = (state = { saleDis: {} }, action) => {
  switch (action.type) {
    case NEW_SALEDIS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_SALEDIS_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        saleDis: action.payload.saleDis,
      };
    case NEW_SALEDIS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_SALEDIS_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const saleDissReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_SALEDIS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_SALEDIS_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_SALEDIS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_SALEDIS_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

import React from "react";
import { Link } from "react-router-dom";
import "./Collection.css";

const CollectionCard = ({ collect }) => {
  return (
    <>
      <div className="aboutCollection">
        <div className="collectionImage">
          <img src={collect.image} alt={collect._id} />
          <h2>{collect.printCollection} Collection</h2>
          <Link to={`/collections/${collect.printCollection}`}>
            <button className="collectBtn">Explore...</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CollectionCard;

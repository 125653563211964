import {
  ADD_TO_RECENTVIEW,
  REMOVE_RECENTVIEW_ITEM,
} from "../constants/recentViewConstants";
import axios from "axios";

// Add to Cart
export const addItemsToRecent = (id) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/v1/product/${id}`);

  dispatch({
    type: ADD_TO_RECENTVIEW,
    payload: {
      product: data.product._id,
      name: data.product.name,
      price: data.product.price,
      discount: data.product.discount,
      weekSale: data.product.weekSale,
      image: data.product.images[0].url,
      stock: data.product.stock,
      ratings: data.product.ratings,
      numOfReviews: data.product.numOfReviews,
    },
  });

  localStorage.setItem(
    "recentItems",
    JSON.stringify(getState().recent.recentItems)
  );
};

// REMOVE FROM CART
export const removeItemsFromRecent = (id) => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_RECENTVIEW_ITEM,
    payload: id,
  });

  localStorage.setItem(
    "recentItems",
    JSON.stringify(getState().recent.recentItems)
  );
};

import {
  ALL_COLLECTION_REQUEST,
  ALL_COLLECTION_SUCCESS,
  ALL_COLLECTION_FAIL,
  ADMIN_COLLECTION_REQUEST,
  ADMIN_COLLECTION_SUCCESS,
  ADMIN_COLLECTION_FAIL,
  NEW_COLLECTION_REQUEST,
  NEW_COLLECTION_SUCCESS,
  NEW_COLLECTION_RESET,
  NEW_COLLECTION_FAIL,
  DELETE_COLLECTION_REQUEST,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_RESET,
  DELETE_COLLECTION_FAIL,
  CLEAR_ERRORS,
} from "../constants/collectionConstants";

export const collectionReducer = (state = { collection: [] }, action) => {
  switch (action.type) {
    case ALL_COLLECTION_REQUEST:
    case ADMIN_COLLECTION_REQUEST:
      return {
        loading: true,
        collection: [],
      };
    case ALL_COLLECTION_SUCCESS:
      return {
        loading: false,
        collection: action.payload.collection,
      };
    case ADMIN_COLLECTION_SUCCESS:
      return {
        loading: false,
        collection: action.payload,
      };
    case ALL_COLLECTION_FAIL:
    case ADMIN_COLLECTION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newCollectionReducer = (state = { collection: {} }, action) => {
  switch (action.type) {
    case NEW_COLLECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_COLLECTION_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        collection: action.payload.collection,
      };
    case NEW_COLLECTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_COLLECTION_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const collectionnReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_COLLECTION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_COLLECTION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_COLLECTION_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// Constants for promotion-related actions
export const CREATE_PROMOTION_REQUEST = "CREATE_PROMOTION_REQUEST";
export const CREATE_PROMOTION_SUCCESS = "CREATE_PROMOTION_SUCCESS";
export const CREATE_PROMOTION_FAIL = "CREATE_PROMOTION_FAIL";

export const UPDATE_ISBOGO_REQUEST = "UPDATE_ISBOGO_REQUEST";
export const UPDATE_ISBOGO_SUCCESS = "UPDATE_ISBOGO_SUCCESS";
export const UPDATE_ISBOGO_FAIL = "UPDATE_ISBOGO_FAIL";

export const UPDATE_ISBTGO_REQUEST = "UPDATE_ISBTGO_REQUEST";
export const UPDATE_ISBTGO_SUCCESS = "UPDATE_ISBTGO_SUCCESS";
export const UPDATE_ISBTGO_FAIL = "UPDATE_ISBTGO_FAIL";

export const GET_ALL_PROMOTIONS_REQUEST = "GET_ALL_PROMOTIONS_REQUEST";
export const GET_ALL_PROMOTIONS_SUCCESS = "GET_ALL_PROMOTIONS_SUCCESS";
export const GET_ALL_PROMOTIONS_FAIL = "GET_ALL_PROMOTIONS_FAIL";

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../../image/logo.png";
import "./Drawer.css";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { getNavbar, clearErrors } from "../../../actions/navbarAction";
import { getAdminWeekSaleDis } from "../../../actions/weekSaleDisAction";

const Drawer = ({ isOpen, toggleDrawer }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { navbar, error } = useSelector((state) => state.navbars);
  const { weekSaleDis } = useSelector((state) => state.weekSaleDiss);
  let disWeekSale = weekSaleDis.map((c) => c.weekSaleDis);
  disWeekSale = disWeekSale[0];
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getAdminWeekSaleDis());
    dispatch(getNavbar());
  }, [dispatch, error, alert]);

  const navRoutes = navbar;
  return (
    <>
      {isOpen && <div className="backDrop" onClick={toggleDrawer}></div>}
      <div
        className={!isOpen ? " sDrawer transTwo" : "sDrawer transOne"}
        isopen={isOpen ? "true" : "false"}
      >
        <Link to="/" className="dnav_brand" onClick={toggleDrawer}>
          <img src={logo} alt="Woca Designs" />
        </Link>
        <ul className="dnav_menu" onClick={toggleDrawer}>
          {disWeekSale && disWeekSale > 0 ? (
            <li className="nav_item">
              <Link to="/weekendsale" className="nav_link">
                WeekendSale
              </Link>
            </li>
          ) : (
            ""
          )}
          {navRoutes &&
            navRoutes.map((route) => (
              <li className="dnav_item" key={route.name}>
                <Link to={route.link} className="dnav_link">
                  {route.name}
                </Link>
              </li>
            ))}
          <div className="dnicon">
            <Link to="/search">
              <li>
                <SearchOutlinedIcon fontSize="large" />
              </li>
            </Link>
            <li>
              <Link to="/login">
                <PersonOutlineOutlinedIcon fontSize="large" />
              </Link>
            </li>
            <li>
              <Link to="/wishlist">
                <FavoriteBorderOutlinedIcon fontSize="large" />
              </Link>
            </li>
            <li>
              <Link to="/cart">
                <LocalMallOutlinedIcon fontSize="large" />
              </Link>
            </li>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Drawer;

import React from "react";
import { Link } from "react-router-dom";
import "../Blogs/BlogCard.css";

const BlogCard = ({ blog }) => {
  return (
    <div className="blogItem-wrap">
      <img className="blogItem-cover" src={blog.image} alt={blog._Title} />
      <Link className="blogItem-link" to={`/blog/${blog._id}`}>
        <h3>{blog.Title}</h3>
      </Link>
      <p className="blogItem-desc">{blog.description}</p>
      <div className="footerblog">
        <div className="blogItem-author">
          <div>
            <h6>{blog.authorName}</h6>
            <p>Author</p>
          </div>
        </div>
        <Link className="blogItem-link" to={`/blog/${blog._id}`}>
          ➝
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;

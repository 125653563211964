import React, { useEffect, useState } from "react";
import "./Products.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProduct } from "../../actions/productAction";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import ProductCard from "../ProductCard/ProductCard";
import { useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import MetaData from "../layout/MetaData";
import { getAdminCategory } from "../../actions/categoryAction";
import { getPageBanner } from "../../actions/pageBannerAction";

const Catproduct = (props) => {
  const params = useParams();
  const alert = useAlert();
  const keyword = params.keyword;
  const dispatch = useDispatch();

  const { error: catError } = useSelector((state) => state.categories);

  const { pageBanner } = useSelector((state) => state.pageBanners);
  const pBanner = pageBanner.filter((c) => c.name === props.category);

  useEffect(() => {
    if (catError) {
      alert.error(catError);
      dispatch(clearErrors());
    }
    dispatch(getPageBanner());
    dispatch(getAdminCategory());
  }, [dispatch, alert, catError]);

  const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([0, 6000]);
  //   const [category, setCategory] = useState("");

  const [ratings, setRatings] = useState(0);

  let {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    // filteredProductsCount,
  } = useSelector((state) => state.products);

  products = products.sort((a, b) =>
    a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
  );

  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };
  const priceHandler = (event, newPrice) => {
    setPrice(newPrice);
  };

  // let count = filteredProductsCount;
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct(keyword, currentPage, price, ratings));
  }, [dispatch, keyword, error, alert, currentPage, price, ratings]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData
            title={
              props.category === "Accessories"
                ? "Accessories-- Woca Designs"
                : props.category === "Combo"
                ? "Combos-- Woca Designs"
                : props.category === "Hair-Accessory"
                ? "Hair Accessories-- Woca Designs"
                : "Shop All-- Woca Designs"
            }
          />
          <div className="productsSec">
            {props.image ? (
              <img
                className="productPageImg"
                src={props.image}
                alt={props.image}
              />
            ) : (
              pBanner &&
              pBanner.map((i) => (
                <img
                  className="productPageImg"
                  src={i.url}
                  alt={i.name}
                  key={i._id}
                />
              ))
            )}

            <h2 className="productsHeading">
              {props.category === "Accessories"
                ? `Explore Handcrafted Accessories Now..!!`
                : props.category === "Combo"
                ? `Explore Handcrafted Combos Now..!!`
                : props.category === "Hair-Accessory"
                ? `Explore Hand Embroidered Hair Accessories`
                : props.category
                ? `Explore ${props.category} Now..!!`
                : "Explore Handcrafted Fashion Now..!!"}
            </h2>
            <div className="productPage">
              <div className="products">
                {props.category === "Accessories"
                  ? products &&
                    products.map(
                      (product) =>
                        product.category === "Accessories" && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : props.category === "Hair-Accessory"
                  ? products &&
                    products.map(
                      (product) =>
                        product.category === "Hair-Accessory" && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : props.category === "Combo"
                  ? products &&
                    products.map(
                      (product) =>
                        product.category === "Combo" && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : props.category
                  ? products &&
                    products.map(
                      (product) =>
                        product.category === props.category && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : products &&
                    products.map((product) => (
                      <ProductCard key={product._id} product={product} />
                    ))}
              </div>
            </div>

            <div className="filterBox">
              <h3 className="filterHeading">Filters</h3>
              <div>
                <Typography>Price</Typography>
                <Slider
                  value={price}
                  onChange={priceHandler}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-Slider"
                  min={0}
                  max={6000}
                />
              </div>
              <div>
                <fieldset>
                  <Typography component="legend"> Ratings Above</Typography>
                  <Slider
                    value={ratings}
                    onChange={(e, newRating) => {
                      setRatings(newRating);
                    }}
                    aria-labelledby="continous-slider"
                    min={0}
                    max={5}
                    valueLabelDisplay="auto"
                  />
                </fieldset>
              </div>
            </div>

            {resultPerPage < productsCount && (
              <div className="paginationBox">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={resultPerPage}
                  totalItemsCount={productsCount}
                  onChange={setCurrentPageNo}
                  nextPageText="Next"
                  prevPageText="Prev"
                  firstPageText="First"
                  lastPageText="Last"
                  itemClass="page-item"
                  linkClass="page-link"
                  activeClass="pageItemActive"
                  activeLinkClass="pageLinkActive"
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Catproduct;

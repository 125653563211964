import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AddIcon from "@mui/icons-material/Add";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CampaignIcon from "@mui/icons-material/Campaign";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import CategoryIcon from "@mui/icons-material/Category";
import NavigationIcon from "@mui/icons-material/Navigation";
import DiscountIcon from "@mui/icons-material/Discount";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <Link to="/admin/dashboard">
        <p>
          <DashboardIcon /> Dashboard
        </p>
      </Link>
      {/* <Link to="#"> */}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ImportExportIcon />}
      >
        {/* Announcement */}
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ImportExportIcon />}
        >
          <TreeItem nodeId="1" label="Announcement">
            <Link className="we" to="/admin/infos">
              <TreeItem nodeId="2" label="All" icon={<CampaignIcon />} />
            </Link>

            <Link to="/admin/info">
              <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
            </Link>
          </TreeItem>
        </TreeView>
        {/* navlinks */}
        <TreeItem nodeId="1" label="NavLinks">
          <Link className="we" to="/admin/navbars">
            <TreeItem nodeId="2" label="All" icon={<NavigationIcon />} />
          </Link>

          <Link to="/admin/navbar">
            <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
          </Link>
        </TreeItem>
      </TreeView>
      {/* BANNER */}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ImportExportIcon />}
      >
        <TreeItem nodeId="1" label="Front Page Banner">
          <Link className="we" to="/admin/banners">
            <TreeItem nodeId="2" label="All" icon={<WallpaperIcon />} />
          </Link>

          <Link to="/admin/banner">
            <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
          </Link>
        </TreeItem>
      </TreeView>
      {/* categories */}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ImportExportIcon />}
      >
        <TreeItem nodeId="1" label="Category">
          <Link className="we" to="/admin/categories">
            <TreeItem nodeId="2" label="All" icon={<CategoryIcon />} />
          </Link>

          <Link to="/admin/category">
            <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
          </Link>
        </TreeItem>
      </TreeView>
      {/* collection PRINT */}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ImportExportIcon />}
      >
        <TreeItem nodeId="1" label="Print Collection">
          <Link className="we" to="/admin/collections">
            <TreeItem nodeId="2" label="All" icon={<CategoryIcon />} />
          </Link>

          <Link to="/admin/collection">
            <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
          </Link>
        </TreeItem>
      </TreeView>
      {/* products */}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ImportExportIcon />}
      >
        <TreeItem nodeId="1" label="Products">
          <Link className="we" to="/admin/products">
            <TreeItem nodeId="2" label="All" icon={<PostAddIcon />} />
          </Link>

          <Link to="/admin/product">
            <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
          </Link>
        </TreeItem>
      </TreeView>
      {/* sale Discount */}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ImportExportIcon />}
      >
        <TreeItem nodeId="1" label="OverAllSale Discount">
          <Link className="we" to="/admin/saleDisEdit">
            <TreeItem nodeId="2" label="SaleDis" icon={<DiscountIcon />} />
          </Link>

          <Link to="/admin/saleDis">
            <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
          </Link>
        </TreeItem>
      </TreeView>
      {/* weeksale Discount */}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ImportExportIcon />}
      >
        <TreeItem nodeId="1" label="WeekSale Discount">
          <Link className="we" to="/admin/weeksaleDisEdit">
            <TreeItem nodeId="2" label="SaleDis" icon={<DiscountIcon />} />
          </Link>

          <Link to="/admin/weeksaleDis">
            <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
          </Link>
          <Link to="/admin/weeksaleDisproduct">
            <TreeItem nodeId="4" label="AllProduct" icon={<PostAddIcon />} />
          </Link>
          <Link to="/admin/weeksaleDisallproduct">
            <TreeItem
              nodeId="5"
              label="Weekly Sale Product"
              icon={<PostAddIcon />}
            />
          </Link>
        </TreeItem>
      </TreeView>

      {/* category section */}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ImportExportIcon />}
      >
        <TreeItem nodeId="1" label="Category Section Image">
          <Link className="we" to="/admin/categoryImgs">
            <TreeItem nodeId="2" label="All" icon={<WallpaperIcon />} />
          </Link>

          <Link to="/admin/categoryImg">
            <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
          </Link>
        </TreeItem>
      </TreeView>
      {/*pAGE BANNER */}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ImportExportIcon />}
      >
        <TreeItem nodeId="1" label="Pages Banner">
          <Link className="we" to="/admin/pageBanners">
            <TreeItem nodeId="2" label="All" icon={<WallpaperIcon />} />
          </Link>

          <Link to="/admin/pageBanner">
            <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
          </Link>
        </TreeItem>
      </TreeView>
      {/*blog */}
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ImportExportIcon />}
      >
        <TreeItem nodeId="1" label="Blog">
          <Link className="we" to="/admin/blogs">
            <TreeItem nodeId="2" label="All" icon={<WallpaperIcon />} />
          </Link>

          <Link to="/admin/blog">
            <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
          </Link>
        </TreeItem>
      </TreeView>

      {/* </Link> */}
      <Link to="/admin/orders">
        <p>
          <ListAltIcon />
          Orders
        </p>
      </Link>
      <Link to="/admin/users">
        <p>
          <PeopleIcon /> Users
        </p>
      </Link>
      <Link to="/admin/reviews">
        <p>
          <RateReviewIcon />
          Reviews
        </p>
      </Link>
      <Link to="/admin/Promotion">
        <p>
          <RateReviewIcon />
          Promotions
        </p>
      </Link>
    </div>
  );
};

export default Sidebar;

import axios from "axios";

import {
  ALL_NAVBAR_REQUEST,
  ALL_NAVBAR_SUCCESS,
  ALL_NAVBAR_FAIL,
  ADMIN_NAVBAR_REQUEST,
  ADMIN_NAVBAR_SUCCESS,
  ADMIN_NAVBAR_FAIL,
  NEW_NAVBAR_REQUEST,
  NEW_NAVBAR_SUCCESS,
  NEW_NAVBAR_FAIL,
  UPDATE_NAVBAR_REQUEST,
  UPDATE_NAVBAR_SUCCESS,
  UPDATE_NAVBAR_FAIL,
  DELETE_NAVBAR_REQUEST,
  DELETE_NAVBAR_SUCCESS,
  DELETE_NAVBAR_FAIL,
  CLEAR_ERRORS,
} from "../constants/navbarConstants";

export const getNavbar = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_NAVBAR_REQUEST,
    });
    const { data } = await axios.get("/api/v1/navbar");

    dispatch({
      type: ALL_NAVBAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_NAVBAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAdminNavbar = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_NAVBAR_REQUEST });
    const { data } = await axios.get("/api/v1/navbar");
    dispatch({
      type: ADMIN_NAVBAR_SUCCESS,
      payload: data.navbar,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_NAVBAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const createNavbar = (navbarData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_NAVBAR_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `/api/v1/admin/navbar/new`,
      navbarData,
      config
    );

    dispatch({
      type: NEW_NAVBAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_NAVBAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateNavbar = (id, navbarData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_NAVBAR_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.put(
      `/api/v1/admin/navbar/${id}`,
      navbarData,
      config
    );

    dispatch({
      type: UPDATE_NAVBAR_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_NAVBAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteNavbar = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_NAVBAR_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/navbar/${id}`);

    dispatch({
      type: DELETE_NAVBAR_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_NAVBAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

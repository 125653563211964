import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  createCategoryImg,
} from "../../actions/categoryImgAction";
import MetaData from "../layout/MetaData";
import "./NewProduct.css";
import { Button } from "@mui/material";
import TitleIcon from "@mui/icons-material/Title";
import LinkIcon from "@mui/icons-material/Link";
import { NEW_CATEGORYIMG_RESET } from "../../constants/categoryImgConstants";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import { getAdminCategory } from "../../actions/categoryAction";
import AccountTreeIcon from "@mui/icons-material/AccountTree";

const NewCategoryImg = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { category: categorie, error: catError } = useSelector(
    (state) => state.categories
  );
  const categories = categorie.sort((a, b) =>
    a.category > b.category ? 1 : b.category > a.category ? -1 : 0
  );
  useEffect(() => {
    if (catError) {
      alert.error(catError);
      dispatch(clearErrors());
    }
    dispatch(getAdminCategory());
  }, [dispatch, alert, catError]);

  const { loading, error, success } = useSelector(
    (state) => state.newCategoryImg
  );

  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Category Section Created Successfully");
      navigate("/admin/categoryImgs");
      dispatch({ type: NEW_CATEGORYIMG_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const createBannerSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("url", url);
    myForm.set("name", name);
    myForm.set("category", category);

    dispatch(createCategoryImg(myForm));
  };

  return (
    <>
      <MetaData title="Create Info" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createBannerSubmitHandler}
          >
            <h1>Create Category Section</h1>

            <div>
              <LinkIcon />
              <input
                type="text"
                placeholder="Banner Category Section Url"
                required
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
            <div>
              <TitleIcon />
              <input
                type="text"
                placeholder="Category Section Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <AccountTreeIcon />
              <select onChange={(e) => setCategory(e.target.value)}>
                <option value="">Choose Category</option>
                {categories &&
                  categories.map((cate) => (
                    <option key={cate._id} value={cate.category}>
                      {cate.category}
                    </option>
                  ))}
              </select>
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewCategoryImg;

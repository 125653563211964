import React, { useEffect, useState } from "react";
import "./Announcement.css";
import { getInfo, clearErrors } from "../../../actions/infoAction";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

const Announcement = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { info, error } = useSelector((state) => state.infos);
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getInfo());
  }, [dispatch, error, alert]);

  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === info.length - 1 ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex, info]);
  return (
    <div className="announ">
      {info &&
        info.map((info, index) => (
          <div
            key={info._id}
            className={index === activeIndex ? "activee" : "inactive"}
          >
            <span>{info.info}</span>
          </div>
        ))}
    </div>
  );
};

export default Announcement;

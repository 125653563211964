import {
  ALL_PAGEBANNER_REQUEST,
  ALL_PAGEBANNER_SUCCESS,
  ALL_PAGEBANNER_FAIL,
  ADMIN_PAGEBANNER_REQUEST,
  ADMIN_PAGEBANNER_SUCCESS,
  ADMIN_PAGEBANNER_FAIL,
  NEW_PAGEBANNER_REQUEST,
  NEW_PAGEBANNER_SUCCESS,
  NEW_PAGEBANNER_RESET,
  NEW_PAGEBANNER_FAIL,
  DELETE_PAGEBANNER_REQUEST,
  DELETE_PAGEBANNER_SUCCESS,
  DELETE_PAGEBANNER_RESET,
  DELETE_PAGEBANNER_FAIL,
  CLEAR_ERRORS,
} from "../constants/pageBannerConstants";

export const pageBannerReducer = (state = { pageBanner: [] }, action) => {
  switch (action.type) {
    case ALL_PAGEBANNER_REQUEST:
    case ADMIN_PAGEBANNER_REQUEST:
      return {
        loading: true,
        pageBanner: [],
      };
    case ALL_PAGEBANNER_SUCCESS:
      return {
        loading: false,
        pageBanner: action.payload.pageBanner,
      };
    case ADMIN_PAGEBANNER_SUCCESS:
      return {
        loading: false,
        pageBanner: action.payload,
      };
    case ALL_PAGEBANNER_FAIL:
    case ADMIN_PAGEBANNER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newPageBannerReducer = (state = { pageBanner: {} }, action) => {
  switch (action.type) {
    case NEW_PAGEBANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_PAGEBANNER_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        pageBanner: action.payload.pageBanner,
      };
    case NEW_PAGEBANNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_PAGEBANNER_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const pageBannerrReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PAGEBANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PAGEBANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_PAGEBANNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PAGEBANNER_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAdminSaleDis,
  deleteSaleDis,
  clearErrors,
} from "../../actions/saleDisAction";
import MetaData from "../layout/MetaData";
import "./ProductList.css";
import Sidebar from "./Sidebar";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { DELETE_SALEDIS_RESET } from "../../constants/saleDisConstants";

const SaleDisList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const { error, saleDis } = useSelector((state) => state.saleDiss);
  const { error: deleteError, isDeleted } = useSelector(
    (state) => state.saleDis
  );

  const deleteInfoHandler = (id) => {
    dispatch(deleteSaleDis(id));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("Sale Discount Deleted Successfully");
      navigate("/admin/dashboard");
      dispatch({ type: DELETE_SALEDIS_RESET });
    }

    dispatch(getAdminSaleDis());
  }, [dispatch, alert, error, deleteError, isDeleted, navigate]);

  const columns = [
    { field: "id", headerName: "Sale Discount ID", minWidth: 200, flex: 0.5 },

    {
      field: "saleDis",
      headerName: "SaleDiscount %",
      minWidth: 350,
      flex: 1,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() =>
                deleteInfoHandler(params.getValue(params.id, "id"))
              }
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
    },
  ];

  const rows = [];

  saleDis &&
    saleDis.forEach((item) => {
      rows.push({
        id: item._id,
        saleDis: item.saleDis,
      });
    });

  return (
    <>
      <MetaData title={`SALE DISCOUNT - Admin`} />

      <div className="dashboard">
        <Sidebar />
        <div className="productListContainer">
          <h1 id="productListHeading">SALE DISCOUNT</h1>

          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </>
  );
};

export default SaleDisList;

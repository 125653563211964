import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  createWeekSaleDis,
} from "../../actions/weekSaleDisAction";
import MetaData from "../layout/MetaData";
import "./NewProduct.css";
import { Button } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { NEW_WEEKSALEDIS_RESET } from "../../constants/weekSaleDisConstants";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";

const NewWeekSaleDis = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { loading, error, success } = useSelector(
    (state) => state.newWeekSaleDis
  );

  const [weekSaleDis, setWeekSaleDis] = useState("");

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("WeekSale Discount Created Successfully");
      navigate("/admin/weeksaleDisEdit");
      dispatch({ type: NEW_WEEKSALEDIS_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const createInfoSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("weekSaleDis", weekSaleDis);

    dispatch(createWeekSaleDis(myForm));
  };

  return (
    <>
      <MetaData title="Create WeekSale Discount" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createInfoSubmitHandler}
          >
            <h1>Create WeekSale Discount</h1>

            <div>
              <DescriptionIcon />
              <input
                type="number"
                placeholder="WeekSale Discount"
                required
                value={weekSaleDis}
                onChange={(e) => setWeekSaleDis(e.target.value)}
              />
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewWeekSaleDis;

import React, { useState } from "react";
import MetaData from "../../layout/MetaData";
import "./FAQs.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const dataFaq = [
  {
    questions: "How do I track my order?",
    answer:
      "If the Order is Dispatched:- You will receive a shipping confirmation along with the tracking details on your registered Email ID. You can track your order using the link on your Email ID.If the order is not dispatched:- We usually dispatch all the orders within 48 working hours, kindly wait for the shipping confirmation email sent from our side along with the tracking details.",
  },
  {
    questions: "How to avail Free Shipping?",
    answer:
      "Free shipping is available on all orders, subject to a minimum order value. A Shipping  charge of Rs. 150/- will be applied to all orders below Rs. 3000/-",
  },
  {
    questions: "How long will my order take to deliver?",
    answer:
      "Depending upon your location, the product is delivered to you between 4-10 workings days after it has been dispatched from our warehouse. We usually dispatch all the orders within 48 hours unless it is on PRE-ORDER.",
  },
  {
    questions: "What do I do if the order lost in transit?",
    answer:
      "Kindly reach out to us on email support@wocadesigns.in immediately if your order is lost in transit. We will initiate a full refund incase you don’t wish to exchange it with any other product.",
  },
];

const FAQs = () => {
  const [accordion, setAccordion] = useState(null);
  const toggleAccordion = (index) => {
    if (index === accordion) {
      setAccordion(null);
      return;
    }
    setAccordion(index);
  };

  return (
    <>
      <MetaData title="FAQ's--Woca Designs" />
      {/* <img
        className="faqPageImg"
        src="https://res.cloudinary.com/woca-designs/image/upload/v1663673847/Useful%20Links%20Image/Woca_Designs_FAQs_wrhlaz.jpg"
        alt="FAQ's Woca Designs"
      /> */}
      <h2 className="productsHeading">Frequently Asked Questions (FAQ's)</h2>

      <div className="faqDiv">
        {dataFaq &&
          dataFaq.map((item, index) => (
            <div
              className="item"
              key={index}
              onClick={() => toggleAccordion(index)}
            >
              <div className="faqHeading">
                {accordion === index ? (
                  <span className="verticle">
                    <KeyboardArrowUpIcon fontSize="large" />
                  </span>
                ) : (
                  <span className="verticle">
                    <KeyboardArrowDownIcon fontSize="large" />
                  </span>
                )}
                <h3 className={accordion === index ? "activefaq" : ""}>
                  {item.questions}
                </h3>
              </div>
              <div
                className={
                  accordion === index ? "faqContent show" : "faqContent"
                }
              >
                {item.answer}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default FAQs;

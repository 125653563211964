import React from "react";
import Helmet from "react-helmet";

const metaDecorator = require("./MetaDecorater.json");

const MetaData = ({ title, description, imageUrl, imageAlt }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* <!--  Essential META Tags --> */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website " />
      <meta property="og:image" content={imageUrl} />
      <meta
        property="og:url"
        content={
          metaDecorator.hostname +
          window.location.pathname +
          window.location.search
        }
      />
      <meta name="twitter:card" content="summary_large_image" />

      {/* <!--  Non-Essential, But Recommended --> */}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Woca Designs" />
      <meta name="twitter:image:alt" content={imageAlt} />
    </Helmet>
  );
};

export default MetaData;

import {
  ADD_TO_WISHLIST,
  REMOVE_WISHLIST_ITEM,
} from "../constants/wishlistConstants";
import axios from "axios";

// Add to Cart
export const addItemsToWish = (id) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/v1/product/${id}`);

  dispatch({
    type: ADD_TO_WISHLIST,
    payload: {
      product: data.product._id,
      name: data.product.name,
      price: data.product.price,
      discount: data.product.discount,
      image: data.product.images[0].url,
      stock: data.product.stock,
    },
  });

  localStorage.setItem("wishItems", JSON.stringify(getState().wish.wishItems));
};

// REMOVE FROM CART
export const removeItemsFromWish = (id) => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_WISHLIST_ITEM,
    payload: id,
  });

  localStorage.setItem("wishItems", JSON.stringify(getState().wish.wishItems));
};

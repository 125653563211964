import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAdminNavbar,
  deleteNavbar,
  clearErrors,
} from "../../actions/navbarAction";
import MetaData from "../layout/MetaData";
import "./ProductList.css";
import Sidebar from "./Sidebar";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { DELETE_NAVBAR_RESET } from "../../constants/navbarConstants";

const NavbarList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const { error, navbar } = useSelector((state) => state.navbars);
  const { error: deleteError, isDeleted } = useSelector(
    (state) => state.navbar
  );

  const deleteInfoHandler = (id) => {
    dispatch(deleteNavbar(id));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("Banner Deleted Successfully");
      navigate("/admin/navbars");
      dispatch({ type: DELETE_NAVBAR_RESET });
    }

    dispatch(getAdminNavbar());
  }, [dispatch, alert, error, deleteError, isDeleted, navigate]);

  const columns = [
    { field: "id", headerName: "Navlinks ID", minWidth: 200, flex: 0.5 },

    {
      field: "navbar",
      headerName: "Navbar",
      minWidth: 350,
      flex: 1,
    },
    {
      field: "link",
      headerName: "NavLinks",
      minWidth: 350,
      flex: 1,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {/* <Link to={`/admin/navbar/${params.getValue(params.id, "id")}`}>
              <EditIcon />
            </Link> */}
            <Button
              onClick={() =>
                deleteInfoHandler(params.getValue(params.id, "id"))
              }
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
    },
  ];

  const rows = [];

  navbar &&
    navbar.forEach((item) => {
      rows.push({
        id: item._id,
        navbar: item.name,
        link: item.link,
      });
    });

  return (
    <>
      <MetaData title={`ALL NAVLINKS - Admin`} />

      <div className="dashboard">
        <Sidebar />
        <div className="productListContainer">
          <h1 id="productListHeading">ALL NAVLINKS</h1>

          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </>
  );
};

export default NavbarList;

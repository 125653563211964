import {
  ALL_BANNER_REQUEST,
  ALL_BANNER_SUCCESS,
  ALL_BANNER_FAIL,
  ADMIN_BANNER_REQUEST,
  ADMIN_BANNER_SUCCESS,
  ADMIN_BANNER_FAIL,
  NEW_BANNER_REQUEST,
  NEW_BANNER_SUCCESS,
  NEW_BANNER_RESET,
  NEW_BANNER_FAIL,
  DELETE_BANNER_REQUEST,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_RESET,
  DELETE_BANNER_FAIL,
  CLEAR_ERRORS,
} from "../constants/bannerConstants";

export const bannerReducer = (state = { banner: [] }, action) => {
  switch (action.type) {
    case ALL_BANNER_REQUEST:
    case ADMIN_BANNER_REQUEST:
      return {
        loading: true,
        banner: [],
      };
    case ALL_BANNER_SUCCESS:
      return {
        loading: false,
        banner: action.payload.banner,
      };
    case ADMIN_BANNER_SUCCESS:
      return {
        loading: false,
        banner: action.payload,
      };
    case ALL_BANNER_FAIL:
    case ADMIN_BANNER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newBannerReducer = (state = { banner: {} }, action) => {
  switch (action.type) {
    case NEW_BANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_BANNER_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        banner: action.payload.banner,
      };
    case NEW_BANNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_BANNER_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const bannerrReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_BANNER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_BANNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_BANNER_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

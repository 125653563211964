import {
  ALL_WEEKSALEDIS_REQUEST,
  ALL_WEEKSALEDIS_SUCCESS,
  ALL_WEEKSALEDIS_FAIL,
  ADMIN_WEEKSALEDIS_REQUEST,
  ADMIN_WEEKSALEDIS_SUCCESS,
  ADMIN_WEEKSALEDIS_FAIL,
  NEW_WEEKSALEDIS_REQUEST,
  NEW_WEEKSALEDIS_SUCCESS,
  NEW_WEEKSALEDIS_RESET,
  NEW_WEEKSALEDIS_FAIL,
  DELETE_WEEKSALEDIS_REQUEST,
  DELETE_WEEKSALEDIS_SUCCESS,
  DELETE_WEEKSALEDIS_RESET,
  DELETE_WEEKSALEDIS_FAIL,
  CLEAR_ERRORS,
} from "../constants/weekSaleDisConstants";

export const weekSaleDisReducer = (state = { weekSaleDis: [] }, action) => {
  switch (action.type) {
    case ALL_WEEKSALEDIS_REQUEST:
    case ADMIN_WEEKSALEDIS_REQUEST:
      return {
        loading: true,
        weekSaleDis: [],
      };
    case ALL_WEEKSALEDIS_SUCCESS:
      return {
        loading: false,
        weekSaleDis: action.payload.weekSaleDis,
      };
    case ADMIN_WEEKSALEDIS_SUCCESS:
      return {
        loading: false,
        weekSaleDis: action.payload,
      };
    case ALL_WEEKSALEDIS_FAIL:
    case ADMIN_WEEKSALEDIS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newWeekSaleDisReducer = (state = { weekSaleDis: {} }, action) => {
  switch (action.type) {
    case NEW_WEEKSALEDIS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_WEEKSALEDIS_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        weekSaleDis: action.payload.weekSaleDis,
      };
    case NEW_WEEKSALEDIS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_WEEKSALEDIS_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const weekSaleDissReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_WEEKSALEDIS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_WEEKSALEDIS_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_WEEKSALEDIS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_WEEKSALEDIS_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAdminWeekSaleDis,
  deleteWeekSaleDis,
  clearErrors,
} from "../../actions/weekSaleDisAction";
import MetaData from "../layout/MetaData";
import "./ProductList.css";
import Sidebar from "./Sidebar";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import { DELETE_WEEKSALEDIS_RESET } from "../../constants/weekSaleDisConstants";

const WeekSaleDisList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const { error, weekSaleDis } = useSelector((state) => state.weekSaleDiss);
  const { error: deleteError, isDeleted } = useSelector(
    (state) => state.weekSaleDis
  );
  const deleteInfoHandler = (id) => {
    dispatch(deleteWeekSaleDis(id));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("WeekSale Discount Deleted Successfully");
      navigate("/admin/dashboard");
      dispatch({ type: DELETE_WEEKSALEDIS_RESET });
    }

    dispatch(getAdminWeekSaleDis());
  }, [dispatch, alert, error, deleteError, isDeleted, navigate]);

  const columns = [
    {
      field: "id",
      headerName: "WeekSale Discount ID",
      minWidth: 200,
      flex: 0.5,
    },

    {
      field: "weekSaleDis",
      headerName: "WeekSaleDiscount %",
      minWidth: 350,
      flex: 1,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              onClick={() =>
                deleteInfoHandler(params.getValue(params.id, "id"))
              }
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
    },
  ];

  const rows = [];

  weekSaleDis &&
    weekSaleDis.forEach((item) => {
      rows.push({
        id: item._id,
        weekSaleDis: item.weekSaleDis,
      });
    });

  return (
    <>
      <MetaData title={`WEEKSALE DISCOUNT - Admin`} />

      <div className="dashboard">
        <Sidebar />
        <div className="productListContainer">
          <h1 id="productListHeading">WEEKSALE DISCOUNT</h1>

          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </>
  );
};

export default WeekSaleDisList;

export const ALL_INFO_REQUEST = "ALL_INFO_REQUEST";
export const ALL_INFO_SUCCESS = "ALL_INFO_SUCCESS";
export const ALL_INFO_FAIL = "ALL_INFO_FAIL";
export const ADMIN_INFO_REQUEST = "ADMIN_INFO_REQUEST";
export const ADMIN_INFO_SUCCESS = "ADMIN_INFO_SUCCESS";
export const ADMIN_INFO_FAIL = "ADMIN_INFO_FAIL";
export const NEW_INFO_REQUEST = "NEW_INFO_REQUEST";
export const NEW_INFO_SUCCESS = "NEW_INFO_SUCCESS";
export const NEW_INFO_RESET = "NEW_INFO_RESET";
export const NEW_INFO_FAIL = "NEW_INFO_FAIL";
export const DELETE_INFO_REQUEST = "DELETE_INFO_REQUEST";
export const DELETE_INFO_SUCCESS = "DELETE_INFO_SUCCESS";
export const DELETE_INFO_RESET = "DELETE_INFO_RESET";
export const DELETE_INFO_FAIL = "DELETE_INFO_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

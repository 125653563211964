import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  updateNavbar,
  getAdminNavbar,
} from "../../actions/navbarAction";
import MetaData from "../layout/MetaData";
import { Button } from "@mui/material";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import { UPDATE_NAVBAR_RESET } from "../../constants/navbarConstants";
import Sidebar from "./Sidebar";
import { useNavigate, useParams } from "react-router-dom";

const UpdateNavbar = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const params = useParams();

  const { error, navbar } = useSelector((state) => state.navbars);

  const {
    loading,
    error: updateError,
    isUpdated,
  } = useSelector((state) => state.navbar);

  const [name, setName] = useState("");
  const [link, setLink] = useState("");

  const navbarId = params.id;

  useEffect(() => {
    if (navbar && navbar._id !== navbarId) {
      dispatch(getAdminNavbar(navbarId));
    } else {
      setName(navbar.name);
      setLink(navbar.link);
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Navlink Updated Successfully");
      navigate("/admin/navbars");
      dispatch({ type: UPDATE_NAVBAR_RESET });
    }
  }, [
    dispatch,
    alert,
    error,
    navigate,
    isUpdated,
    navbarId,
    navbar,
    updateError,
  ]);

  const updateProductSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("link", link);

    dispatch(updateNavbar(navbarId, myForm));
  };

  return (
    <>
      <MetaData title="Update Navlink" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={updateProductSubmitHandler}
          >
            <h1>Update Navlink</h1>

            <div>
              <SpellcheckIcon />
              <input
                type="text"
                placeholder="NavRoute Link"
                required
                onChange={(e) => setLink(e.target.value)}
                value={link}
              />
            </div>
            <div>
              <SpellcheckIcon />
              <input
                type="text"
                placeholder="Navlink Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Update
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateNavbar;

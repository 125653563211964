import React, { useEffect } from "react";
import "./Cart.css";
import "./CartItemCard";
import CartItemCard from "./CartItemCard";
import { useSelector, useDispatch } from "react-redux";
import { addItemsToCart, removeItemsFromCart } from "../../actions/cartAction";
import { Typography } from "@mui/material";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../layout/MetaData";
import { getWeekSaleDis } from "../../actions/weekSaleDisAction";
import { register } from "../../actions/userAction";
import SlideModal from "../Home/SlideModal";

const Cart = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  console.log(cartItems);

  const { weekSaleDis } = useSelector((state) => state.weekSaleDiss);
  let disWeekSale = weekSaleDis.map((c) => c.weekSaleDis);
  useEffect(() => {
    dispatch(getWeekSaleDis());
  }, [dispatch]);
  disWeekSale = disWeekSale[0];

  const increaseQuantity = (id, quantity, stock) => {
    const newQty = quantity + 1;
    if (stock <= quantity) {
      return;
    }
    dispatch(addItemsToCart(id, newQty));
  };
  const decreaseQuantity = (id, quantity) => {
    const newQty = quantity - 1;
    if (1 >= quantity) {
      return;
    }
    dispatch(addItemsToCart(id, newQty));
  };

  const deleteCartItem = (id, stock) => {
    if (stock === 0) {
      return;
    }
    dispatch(removeItemsFromCart(id));
  };

  const checkOutHandler = () => {
    navigate("/login?redirect=shipping");
    // navigate("/shipping");
  };
  const checkOutAsGuest = () => {
    const randomEmail = `guest${Math.floor(Math.random() * 100000)}@woca.com`;
    const randomPassword = `guest${Math.floor(Math.random() * 100000)}`;
    const randomName = "guest";
    const myForm = new FormData();

    myForm.set("name", randomName);
    myForm.set("email", randomEmail);
    myForm.set("password", randomPassword);
    dispatch(register(myForm));
    navigate("/login?redirect=shipping");
  };
  return (
    <>
      <MetaData title="Cart- Woca Designs" />
      <SlideModal />
      {cartItems.length === 0 ? (
        <div className="emptyCart">
          <RemoveShoppingCartIcon />
          <Typography> Please add some product</Typography>
          <Link to="/products">View Products</Link>
        </div>
      ) : (
        <>
          <div className="cartPage">
            <h2>Cart Details</h2>
            <div className="cartHeader">
              <p>Product</p>
              <p>Quantity</p>
              <p>Subtotal</p>
            </div>
            <div className="overflowcart">
              {cartItems &&
                cartItems.map((item) => (
                  <div className="cartContainer" key={item.product}>
                    <CartItemCard item={item} deleteCartItem={deleteCartItem} />
                    <div className="cardInput">
                      <button
                        onClick={() =>
                          decreaseQuantity(item.product, item.quantity)
                        }
                      >
                        -
                      </button>
                      <input readOnly type="number" value={item.quantity} />
                      <button
                        onClick={() =>
                          increaseQuantity(
                            item.product,
                            item.quantity,
                            item.stock
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                    <p className="cardSubTotal">{`Rs. ${
                      `${
                        props.discount
                          ? props.discount
                          : item.weekSale
                          ? disWeekSale
                          : item.discount
                      }` > 0
                        ? `${
                            Math.floor(
                              item.price -
                                (item.price / 100) *
                                  `${
                                    props.discount
                                      ? props.discount
                                      : item.weekSale
                                      ? disWeekSale
                                      : item.discount
                                  }`
                            ) * item.quantity
                          }`
                        : `${item.price}` * item.quantity
                    }`}</p>
                  </div>
                ))}
            </div>
            <div className="cartGrossTotal">
              <div className="cartGrossTotalBox">
                <div>
                  <p>Total Price Before Discount:</p>
                </div>
                <p>{`Rs.${cartItems.reduce(
                  (acc, item) =>
                    acc +
                    item.quantity *
                      `${
                        `${
                          props.discount
                            ? props.discount
                            : item.weekSale
                            ? disWeekSale
                            : item.discount
                        }` > 0
                          ? `${Math.floor(
                              item.price -
                                (item.price / 100) *
                                  `${
                                    props.discount
                                      ? props.discount
                                      : item.weekSale
                                      ? disWeekSale
                                      : item.discount
                                  }`
                            )}`
                          : `${item.price}`
                      }`,
                  0
                )}`}</p>
              </div>
            </div>
            {!user ? (
              <div className="checkOutBtn">
                <button onClick={checkOutAsGuest}>CheckOut as Guest</button>
                <button onClick={checkOutHandler}>SignUp & CheckOut</button>
                <Link to="/products">
                  <button>Continue Shopping</button>
                </Link>
              </div>
            ) : (
              <div className="checkOutBtn">
                <button onClick={() => navigate("/shipping")}>CheckOut</button>
                <Link to="/products">
                  <button>Continue Shopping</button>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Cart;

export const ALL_NAVBAR_REQUEST = "ALL_NAVBAR_REQUEST";
export const ALL_NAVBAR_SUCCESS = "ALL_NAVBAR_SUCCESS";
export const ALL_NAVBAR_FAIL = "ALL_NAVBAR_FAIL";
export const ADMIN_NAVBAR_REQUEST = "ADMIN_NAVBAR_REQUEST";
export const ADMIN_NAVBAR_SUCCESS = "ADMIN_NAVBAR_SUCCESS";
export const ADMIN_NAVBAR_FAIL = "ADMIN_NAVBAR_FAIL";
export const NEW_NAVBAR_REQUEST = "NEW_NAVBAR_REQUEST";
export const NEW_NAVBAR_SUCCESS = "NEW_NAVBAR_SUCCESS";
export const NEW_NAVBAR_RESET = "NEW_NAVBAR_RESET";
export const NEW_NAVBAR_FAIL = "NEW_NAVBAR_FAIL";
export const UPDATE_NAVBAR_REQUEST = "UPDATE_NAVBAR_REQUEST";
export const UPDATE_NAVBAR_SUCCESS = "UPDATE_NAVBAR_SUCCESS";
export const UPDATE_NAVBAR_RESET = "UPDATE_NAVBAR_RESET";
export const UPDATE_NAVBAR_FAIL = "UPDATE_NAVBAR_FAIL";
export const DELETE_NAVBAR_REQUEST = "DELETE_NAVBAR_REQUEST";
export const DELETE_NAVBAR_SUCCESS = "DELETE_NAVBAR_SUCCESS";
export const DELETE_NAVBAR_RESET = "DELETE_NAVBAR_RESET";
export const DELETE_NAVBAR_FAIL = "DELETE_NAVBAR_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

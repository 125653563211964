import React from "react";
import MetaData from "../../layout/MetaData";
import "../Privacy Policy Page/PrivacyPolicy.css";
import "./about.css";
const About = () => {
  return (
    <>
      <MetaData title="About--Woca Designs" />
      <div className="aboutus">
        <div className="privacyPolicy">
          <h2 className="productsHeading">About Us</h2>.
          <p>
            Women Empowerment through Woca Designs Woca Designs is a brand that
            is dedicated to empowering women by bringing the rich heritage of
            Indian handcrafts to the modern world. The brand works with local
            artisans and empowers them by providing them with a platform to
            showcase their skills and earn a livelihood.<br></br>
            Women empowerment is a crucial issue in today's world, and Woca
            Designs is playing its part in making a difference. By providing
            women with job opportunities and supporting them, the brand is
            helping them become financially independent and empowering them to
            take control of their lives.<br></br>
            The brand's focus on traditional handcrafts such as weaving,
            embroidery, and block printing helps preserve the rich cultural
            heritage of India. The artisans are trained in traditional
            techniques and are given the resources they need to create
            beautiful, high-quality products that appeal to modern consumers.
            <br></br>
            In addition to empowering women and preserving cultural heritage,
            Woca Designs is also committed to sustainability. The brand uses
            natural and eco-friendly materials in its products, reducing its
            impact on the environment.<br></br>
            Through its work, Woca Designs is making a positive impact on
            women's lives and contributing to the global movement for women's
            empowerment. By supporting the brand, you can make a difference in
            the lives of women and help preserve the rich heritage of Indian
            handcrafts.<br></br>
            So, if you're looking for a way to support women's empowerment and
            preserve cultural heritage, consider supporting Woca Designs and its
            mission. Your purchase can make a real difference in the lives of
            women and communities.<br></br> Voyaging through the lanes of India
            we have found craft at every corner. The tradition of handcrafted
            products is deeply rooted in our history, but for us other than our
            books we remember it by the products that were made by our
            grandmothers, mothers, and aunts when we were children. There has
            always been a shift in the trend but the soul or the memory of each
            product always stays. We believe everybody knows the art of thread
            and needle and we just need to polish that skill. Here at woca
            designs, we teach to be the master of this skill to our very own
            women who wants to learn and be independent. If we have the
            opportunity nothing is impossible, that’s what our women at woca
            design house believe too. Learn, grow, and empower. Elegant,
            handcrafted, created by love—woca designs product represents the
            craft of India created with love by our very own artisans and women.
          </p>
        </div>
        <div>
          <img
            className="about"
            src="https://res.cloudinary.com/dbdez1ptp/image/upload/v1667297388/other%20img/WhatsApp_Image_2022-10-04_at_11.51.04_AM_rcmooc.jpg"
            alt="Wocadesigns.in"
          />
        </div>
      </div>
    </>
  );
};

export default About;

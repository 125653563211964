import {
  ALL_INFO_REQUEST,
  ALL_INFO_SUCCESS,
  ALL_INFO_FAIL,
  ADMIN_INFO_REQUEST,
  ADMIN_INFO_SUCCESS,
  ADMIN_INFO_FAIL,
  NEW_INFO_REQUEST,
  NEW_INFO_SUCCESS,
  NEW_INFO_RESET,
  NEW_INFO_FAIL,
  DELETE_INFO_REQUEST,
  DELETE_INFO_SUCCESS,
  DELETE_INFO_RESET,
  DELETE_INFO_FAIL,
  CLEAR_ERRORS,
} from "../constants/infoConstants";

export const infoReducer = (state = { info: [] }, action) => {
  switch (action.type) {
    case ALL_INFO_REQUEST:
    case ADMIN_INFO_REQUEST:
      return {
        loading: true,
        info: [],
      };
    case ALL_INFO_SUCCESS:
      return {
        loading: false,
        info: action.payload.info,
        infosCount: action.payload.infosCount,
      };
    case ADMIN_INFO_SUCCESS:
      return {
        loading: false,
        info: action.payload,
      };
    case ALL_INFO_FAIL:
    case ADMIN_INFO_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newInfoReducer = (state = { info: {} }, action) => {
  switch (action.type) {
    case NEW_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_INFO_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        info: action.payload.info,
      };
    case NEW_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_INFO_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const infooReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_INFO_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

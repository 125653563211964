import React from "react";
import MetaData from "../../layout/MetaData";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <MetaData title="Privacy Policy--Woca Designs" />
      <h2 className="productsHeading">Privacy Policy</h2>.
      <div className="mainPrivacy">
        <div className="privacyPolicy">
          <h2>WHAT DO WE DO WITH YOUR INFORMATION ?</h2>
          <p>
            When you purchase something from our store, as part of the buying
            and selling process, we collect the personal information you give us
            such as your name, address and email address. When you browse our
            store, we also automatically receive your computer’s internet
            protocol (IP) address in order to provide us with information that
            helps us learn about your browser and operating system. Email
            marketing (if applicable): With your permission, we may send you
            emails about our store, new products and other updates. We may
            enable access of the Users’ information to the Company Entities,
            joint ventures, agents or third parties for the purposes of the
            services provided by them or for any other marketing related
            activity undertaken by or on behalf of the Company Entities and/or
            its joint ventures. We will ensure on reasonable commercial efforts
            basis that the third parties and agents employed by us for the
            purposes of the Website are under an obligation to maintain
            confidentiality with respect to the Personal Information provided by
            the Users and to use it strictly for the purposes of the Website
            only. Company may disclose your Personal Information to such extent,
            as the Company may deem necessary for you to enter into commercial
            transactions with other users of the Website.
          </p>
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/dbdez1ptp/image/upload/v1667297706/other%20img/woca_designs_privacy_policy_ey0eus.png"
            alt="PrivacyPolicy"
          />
        </div>
      </div>
      <div className="privacyPolicy">
        <h2>Business Transfers</h2>
        <p>
          Company may sell, transfer or otherwise share some or all of its
          assets, including your Personal Information, in connection with a
          merger, acquisition, reorganization or sale of assets or in the event
          of bankruptcy. Should such a sale or transfer occur, we will ensure
          that the Personal Information You have provided through the Website is
          stored and used by the transferee in a manner that is consistent with
          this Privacy Policy.
        </p>
        <h2>Email Policies</h2>
        <p>
          Company may use your Personal Information for the aforementioned
          purposes of the Website. You have full control regarding which of
          these emails You want to receive. If You decide at any time that You
          no longer wish to receive such communications from us, please follow
          the unsubscribe instructions provided in any of the communications.
          Please note that once we receive your request, it may take an
          additional period of time for your opt-out to become effective.
        </p>
        <h2>Deleting Your Information</h2>
        <p>
          If You wish to have the Personal Information that You have provided to
          us deleted, You can always do so by sending a request to us on the
          e-mail id of our Customer Services department provided at the bottom
          of this page. You may note that deletion of certain Personal
          Information may lead to cancellation of your registration with the
          Website and your access to certain features of the Website.
        </p>
        <h2>Security</h2>
        <p>
          Your information may be transferred to or be maintained on computers,
          computer systems and computer resources located outside of your state
          or country where the privacy laws may not be as protective as those
          where you live. If You are located outside India and choose to provide
          information to us, please be aware that Company keeps or transfers
          information to India and processes it there. Your submission of such
          information represents your agreement to that transfer. Unfortunately,
          the transmission of information over the Internet is not completely
          secure. Although We strive to protect your personal data, We cannot
          guarantee the security of your data while it is being transmitted to
          our site; any transmission is at your own risk. Once We have received
          your information, We have commercially reasonable procedures and
          security features in place to reasonably endeavor to prevent
          unauthorized access.
        </p>
        <h2>Changes in Privacy Policy</h2>
        <p>
          From time to time We may update this Privacy Policy. Your continued
          subscription to our Services constitutes an acceptance of the
          then-current Privacy Policy and Terms of Use so We encourage You to
          visit this page periodically to review any changes.
        </p>
        <h2>Phishing</h2>
        <p>
          Identity theft and the practice currently known as “phishing” are of
          great concern to Company. Safeguarding information to help protect You
          from identity theft is a top priority. We do not and will not, at any
          time, request your credit card information or national identification
          numbers in a non-secure or unsolicited e-mail or telephone
          communication.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, the practices of
          Company or your dealings with the Website, you can contact us via the
          links at the bottom of the page.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;

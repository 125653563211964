import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  updateProduct,
  getProductDetails,
} from "../../actions/productAction";
import MetaData from "../layout/MetaData";
import { Button } from "@mui/material";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DescriptionIcon from "@mui/icons-material/Description";
import StorageIcon from "@mui/icons-material/Storage";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { UPDATE_PRODUCT_RESET } from "../../constants/productConstants";
import Sidebar from "./Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { getAdminCategory } from "../../actions/categoryAction";
import DiscountIcon from "@mui/icons-material/Discount";
import { getAdminCollection } from "../../actions/collectionAction";

const UpdateProduct = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();
  const params = useParams();

  const { category: categorie, error: catError } = useSelector(
    (state) => state.categories
  );
  const { collection } = useSelector((state) => state.printCollections);
  useEffect(() => {
    if (catError) {
      alert.error(catError);
      dispatch(clearErrors());
    }
    dispatch(getAdminCategory());
    dispatch(getAdminCollection());
  }, [dispatch, alert, catError]);

  const categories = categorie.sort((a, b) =>
    a.category > b.category ? 1 : b.category > a.category ? -1 : 0
  );

  const { error, product } = useSelector((state) => state.productDetails);

  const {
    loading,
    error: updateError,
    isUpdated,
  } = useSelector((state) => state.product);

  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [printCollection, setPrintCollection] = useState("");
  const [frequentProductOne, setFrequentProductOne] = useState("");
  const [frequentProductTwo, setFrequentProductTwo] = useState("");
  const [stock, setStock] = useState(0);
  const [images, setImages] = useState([]);
  const [oldImages, setOldImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);

  const productId = params.id;

  useEffect(() => {
    if (product && product._id !== productId) {
      dispatch(getProductDetails(productId));
    } else {
      setName(product.name);
      setDescription(product.description);
      setPrice(product.price);
      setDiscount(product.discount);
      setCategory(product.category);
      setPrintCollection(product.printCollection);
      setFrequentProductOne(product.frequentProductOne);
      setFrequentProductTwo(product.frequentProductTwo);
      setStock(product.stock);
      setOldImages(product.images);
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Product Updated Successfully");
      navigate("/admin/products");
      dispatch({ type: UPDATE_PRODUCT_RESET });
    }
  }, [
    dispatch,
    alert,
    error,
    navigate,
    isUpdated,
    productId,
    product,
    updateError,
  ]);

  const updateProductSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("price", price);
    myForm.set("discount", discount);
    myForm.set("description", description);
    myForm.set("category", category);
    myForm.set("printCollection", printCollection);
    myForm.set("frequentProductOne", frequentProductOne);
    myForm.set("frequentProductTwo", frequentProductTwo);
    myForm.set("stock", stock);

    images.forEach((image) => {
      myForm.append("images", image);
    });
    dispatch(updateProduct(productId, myForm));
  };

  const updateProductImagesChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);
    setImagesPreview([]);
    setOldImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((old) => [...old, reader.result]);
          setImages((old) => [...old, reader.result]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  return (
    <>
      <MetaData title="Update Product" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={updateProductSubmitHandler}
          >
            <h1>Update Product</h1>

            <div>
              <SpellcheckIcon />
              <input
                type="text"
                placeholder="Product Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <AttachMoneyIcon />
              <input
                type="number"
                placeholder="Price"
                required
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              />
            </div>
            <div>
              <DiscountIcon />
              <input
                type="number"
                placeholder="Discount"
                required
                onChange={(e) => setDiscount(e.target.value)}
                value={discount}
              />
            </div>

            <div>
              <DescriptionIcon />

              <textarea
                placeholder="Product Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                cols="30"
                rows="1"
              ></textarea>
            </div>

            <div>
              <AccountTreeIcon />
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Choose Category</option>
                {categories &&
                  categories.map((cate) => (
                    <option key={cate._id} value={cate.category}>
                      {cate.category}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <AccountTreeIcon />
              <select
                value={printCollection}
                onChange={(e) => setPrintCollection(e.target.value)}
              >
                <option value="">Choose Collection</option>
                {collection &&
                  collection.map((cate) => (
                    <option key={cate._id} value={cate.printCollection}>
                      {cate.printCollection}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <AccountTreeIcon />
              <select
                value={frequentProductOne}
                onChange={(e) => setFrequentProductOne(e.target.value)}
              >
                <option value="">Choose FrequentProductOne Category</option>
                {categories &&
                  categories.map((cate) => (
                    <option key={cate._id} value={cate.category}>
                      {cate.category}
                    </option>
                  ))}
              </select>
            </div>
            <div>
              <AccountTreeIcon />
              <select
                value={frequentProductTwo}
                onChange={(e) => setFrequentProductTwo(e.target.value)}
              >
                <option value="">Choose FrequentProductTwo Category</option>
                {categories &&
                  categories.map((cate) => (
                    <option key={cate._id} value={cate.category}>
                      {cate.category}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <StorageIcon />
              <input
                type="number"
                placeholder="Stock"
                required
                onChange={(e) => setStock(e.target.value)}
                value={stock}
              />
            </div>

            <div id="createProductFormFile">
              <input
                type="file"
                name="avatar"
                accept="image/*"
                onChange={updateProductImagesChange}
                multiple
              />
            </div>

            <div id="createProductFormImage">
              {oldImages &&
                oldImages.map((image, index) => (
                  <img key={index} src={image.url} alt=" oldProduct Preview" />
                ))}
            </div>
            <div id="createProductFormImage">
              {imagesPreview.map((image, index) => (
                <img key={index} src={image} alt="Product Preview" />
              ))}
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Update
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default UpdateProduct;

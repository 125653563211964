import axios from "axios";

import {
  ALL_WEEKSALEDIS_REQUEST,
  ALL_WEEKSALEDIS_SUCCESS,
  ALL_WEEKSALEDIS_FAIL,
  ADMIN_WEEKSALEDIS_REQUEST,
  ADMIN_WEEKSALEDIS_SUCCESS,
  ADMIN_WEEKSALEDIS_FAIL,
  NEW_WEEKSALEDIS_REQUEST,
  NEW_WEEKSALEDIS_SUCCESS,
  NEW_WEEKSALEDIS_FAIL,
  DELETE_WEEKSALEDIS_REQUEST,
  DELETE_WEEKSALEDIS_SUCCESS,
  DELETE_WEEKSALEDIS_FAIL,
  CLEAR_ERRORS,
} from "../constants/weekSaleDisConstants";

export const getWeekSaleDis = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_WEEKSALEDIS_REQUEST,
    });
    const { data } = await axios.get("/api/v1/weeksaleDis");

    dispatch({
      type: ALL_WEEKSALEDIS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_WEEKSALEDIS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAdminWeekSaleDis = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_WEEKSALEDIS_REQUEST });
    const { data } = await axios.get("/api/v1/weeksaleDis");
    dispatch({
      type: ADMIN_WEEKSALEDIS_SUCCESS,
      payload: data.weekSaleDis,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_WEEKSALEDIS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const createWeekSaleDis = (weekSaleDisData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_WEEKSALEDIS_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `/api/v1/admin/weeksaleDis/new`,
      weekSaleDisData,
      config
    );

    dispatch({
      type: NEW_WEEKSALEDIS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_WEEKSALEDIS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteWeekSaleDis = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_WEEKSALEDIS_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/weeksaleDis/${id}`);

    dispatch({
      type: DELETE_WEEKSALEDIS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_WEEKSALEDIS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

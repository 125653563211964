import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import "./ProductList.css";
import Sidebar from "./Sidebar";
import { getCartDetails } from "../../actions/cartAction";

const CartList = () => {
  const dispatch = useDispatch();
  const { cart } = useSelector((state) => state.cart);
  console.log(cart);

  useEffect(() => {
    dispatch(getCartDetails());
  }, [dispatch]);

  const columns = [
    { field: "id", headerName: "ID", minWidth: 0, flex: 0 },
    { field: "productCart", headerName: "Product", minWidth: 200, flex: 2 },

    {
      field: "count",
      headerName: "Count",
      minWidth: 350,
      flex: 1,
    },
  ];

  const rows = [];
  cart &&
    cart.forEach((item) => {
      rows.push({
        id: item._id,
        productCart: item.productCart,
        count: item.count,
      });
    });

  const sortedRows = [...rows].sort((a, b) => b.count - a.count);
  return (
    <>
      <MetaData title={`Cart List - Admin`} />

      <div className="dashboard">
        <Sidebar />
        <div className="productListContainer">
          <h1 id="productListHeading">Cart List</h1>

          <DataGrid
            rows={sortedRows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </>
  );
};

export default CartList;

import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearErrors, getAdminProduct } from "../../actions/productAction";
import MetaData from "../layout/MetaData";
import "./ProductList.css";
import Sidebar from "./Sidebar";
import EditIcon from "@mui/icons-material/Edit";
import { Avatar } from "@mui/material";

const WeeksaleDisProductList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const { error, products } = useSelector((state) => state.products);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(getAdminProduct());
  }, [dispatch, alert, error, navigate]);

  const columns = [
    { field: "id", headerName: "Product ID", flex: 0.3 },

    {
      field: "name",
      headerName: "Name",
      flex: 0.3,
    },

    {
      field: "image",
      headerName: "Image",
      flex: 0.3,
      renderCell: (params) => <Avatar alt="" scr={params.row.image} />,
      sortable: false,
    },
    {
      field: "stock",
      headerName: "Stock",
      type: "number",
      flex: 0.3,
    },
    {
      field: "weekSale",
      headerName: "WeekSale",
      type: "number",
      flex: 0.3,
    },
    {
      field: "discount",
      headerName: "Discount %",
      type: "number",
      flex: 0.3,
    },

    {
      field: "price",
      headerName: "Price",
      type: "number",
      flex: 0.3,
    },
    {
      field: "disPrice",
      headerName: "DiscountPrice",
      type: "number",
      flex: 0.3,
    },
    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={`/admin/weeksaleproduct/${params.getValue(params.id, "id")}`}
            >
              <EditIcon />
            </Link>
          </>
        );
      },
    },
  ];

  const rows = [];

  products &&
    products.forEach((item) => {
      rows.push({
        id: item._id,
        stock: item.stock,
        image: item.images[0].url,
        weekSale: item.weekSale,
        discount: item.discount,
        price: item.price,
        disPrice: item.price - (item.price * item.discount) / 100,
        name: item.name,
      });
    });
  return (
    <>
      <MetaData title={`ALL PRODUCTS - Admin`} />

      <div className="dashboard">
        <Sidebar />
        <div className="productListContainer">
          <h1 id="productListHeading">ALL PRODUCTS</h1>

          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </>
  );
};

export default WeeksaleDisProductList;

import {
  ALL_CATEGORYIMG_REQUEST,
  ALL_CATEGORYIMG_SUCCESS,
  ALL_CATEGORYIMG_FAIL,
  ADMIN_CATEGORYIMG_REQUEST,
  ADMIN_CATEGORYIMG_SUCCESS,
  ADMIN_CATEGORYIMG_FAIL,
  NEW_CATEGORYIMG_REQUEST,
  NEW_CATEGORYIMG_SUCCESS,
  NEW_CATEGORYIMG_RESET,
  NEW_CATEGORYIMG_FAIL,
  DELETE_CATEGORYIMG_REQUEST,
  DELETE_CATEGORYIMG_SUCCESS,
  DELETE_CATEGORYIMG_RESET,
  DELETE_CATEGORYIMG_FAIL,
  CLEAR_ERRORS,
} from "../constants/categoryImgConstants";

export const categoryImgReducer = (state = { categoryImg: [] }, action) => {
  switch (action.type) {
    case ALL_CATEGORYIMG_REQUEST:
    case ADMIN_CATEGORYIMG_REQUEST:
      return {
        loading: true,
        categoryImg: [],
      };
    case ALL_CATEGORYIMG_SUCCESS:
      return {
        loading: false,
        categoryImg: action.payload.categoryImg,
      };
    case ADMIN_CATEGORYIMG_SUCCESS:
      return {
        loading: false,
        categoryImg: action.payload,
      };
    case ALL_CATEGORYIMG_FAIL:
    case ADMIN_CATEGORYIMG_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newCategoryImgReducer = (state = { categoryImg: {} }, action) => {
  switch (action.type) {
    case NEW_CATEGORYIMG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_CATEGORYIMG_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        categoryImg: action.payload.categoryImg,
      };
    case NEW_CATEGORYIMG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_CATEGORYIMG_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const categoryImgsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CATEGORYIMG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CATEGORYIMG_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case DELETE_CATEGORYIMG_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_CATEGORYIMG_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

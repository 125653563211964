import axios from "axios";

import {
  ALL_PAGEBANNER_REQUEST,
  ALL_PAGEBANNER_SUCCESS,
  ALL_PAGEBANNER_FAIL,
  ADMIN_PAGEBANNER_REQUEST,
  ADMIN_PAGEBANNER_SUCCESS,
  ADMIN_PAGEBANNER_FAIL,
  NEW_PAGEBANNER_REQUEST,
  NEW_PAGEBANNER_SUCCESS,
  NEW_PAGEBANNER_FAIL,
  DELETE_PAGEBANNER_REQUEST,
  DELETE_PAGEBANNER_SUCCESS,
  DELETE_PAGEBANNER_FAIL,
  CLEAR_ERRORS,
} from "../constants/pageBannerConstants";

export const getPageBanner = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_PAGEBANNER_REQUEST,
    });
    const { data } = await axios.get("/api/v1/pageBanner");

    dispatch({
      type: ALL_PAGEBANNER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_PAGEBANNER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAdminPageBanner = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_PAGEBANNER_REQUEST });
    const { data } = await axios.get("/api/v1/pageBanner");
    dispatch({
      type: ADMIN_PAGEBANNER_SUCCESS,
      payload: data.pageBanner,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PAGEBANNER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const createPageBanner = (PageBannerData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_PAGEBANNER_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `/api/v1/admin/pageBanner/new`,
      PageBannerData,
      config
    );

    dispatch({
      type: NEW_PAGEBANNER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PAGEBANNER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deletePageBanner = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PAGEBANNER_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/pageBanner/${id}`);

    dispatch({
      type: DELETE_PAGEBANNER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PAGEBANNER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

import React, { useState } from "react";
import whatsapp from "../../../image/whatsapp.png";

const Whatsapp = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="whatsapp"
      style={{
        position: "fixed",
        zIndex: 999,
        bottom: "10rem",
        right: "1rem",
        display: "flex",
        alignItems: "center",
        backgroundColor: "#D3D3D3", // Set the background color
        padding: "0.5rem", // Add some padding for better styling
        borderRadius: "1rem", // Add rounded corners
        transition: "opacity 0.3s", // Add a smooth transition for opacity changes
        opacity: isHovered ? 1 : 0.5, // Change opacity based on hover state
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a
        href="https://wa.me/917985350600"
        target="blank"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={whatsapp}
          alt="whatsapp"
          style={{
            width: "2vmax",
          }}
        />
        <span
          style={{
            marginLeft: "1rem",
            fontSize: "1.2rem",
          }}
        >
          WhatsApp Us!
        </span>
      </a>
    </div>
  );
};

export default Whatsapp;

import {
  CREATE_PROMOTION_REQUEST,
  CREATE_PROMOTION_SUCCESS,
  CREATE_PROMOTION_FAIL,
  UPDATE_ISBOGO_REQUEST,
  UPDATE_ISBOGO_SUCCESS,
  UPDATE_ISBOGO_FAIL,
  UPDATE_ISBTGO_REQUEST,
  UPDATE_ISBTGO_SUCCESS,
  UPDATE_ISBTGO_FAIL,
  GET_ALL_PROMOTIONS_REQUEST,
  GET_ALL_PROMOTIONS_SUCCESS,
  GET_ALL_PROMOTIONS_FAIL,
} from "../constants/promotionConstants";
const initialState = {
  promotions: [],
  loading: false,
  error: null,
};

const promotionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PROMOTION_REQUEST:
    case UPDATE_ISBOGO_REQUEST:
    case UPDATE_ISBTGO_REQUEST:
    case GET_ALL_PROMOTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_PROMOTION_SUCCESS:
    case UPDATE_ISBOGO_SUCCESS:
    case UPDATE_ISBTGO_SUCCESS:
      return {
        ...state,
        loading: false,
        promotions: action.payload, // Update promotions with the new data
      };

    case GET_ALL_PROMOTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        promotions: action.payload, // Replace promotions with the fetched data
      };

    case CREATE_PROMOTION_FAIL:
    case UPDATE_ISBOGO_FAIL:
    case UPDATE_ISBTGO_FAIL:
    case GET_ALL_PROMOTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload, // Set error message
      };

    default:
      return state;
  }
};

export default promotionReducer;

import React, { useEffect, useState } from "react";
import "./Loader.css";
import HashLoader from "react-spinners/HashLoader";

const Loader = () => {
  let [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [setLoading]);
  return (
    <div className="loading">
      <div>
        <HashLoader size={50} color={"#50E3C2"} loading={loading} />
      </div>
    </div>
  );
};

export default Loader;

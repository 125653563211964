import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./PaymentSuccess.css";
import { Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createOrder } from "../../actions/orderAction";

const PaymentSuccess = () => {
  const searchQuery = useSearchParams()[0];
  const refrenceNum = searchQuery.get("reference");
  // add on
  const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));
  const dispatch = useDispatch();
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const orders = {
    shippingInfo,
    orderItems: cartItems,
    itemsPrice: orderInfo.subtotal,
    taxPrice: orderInfo.tax,
    shippingPrice: orderInfo.shippingCharges,
    totalPrice: orderInfo.totalPrice,
    paymentInfo: {
      id: refrenceNum,
      status: "Successed",
    },
  };

  const placeOrder = () => {
    if (refrenceNum) {
      dispatch(createOrder(orders));
    }
  };
  window.onload = function () {
    placeOrder();
  };
  // till here

  return (
    <div className="orderSuccess">
      <CheckCircleIcon />
      <Typography>Your Order has been Placed successfully </Typography>
      <span>Reference No: {refrenceNum}</span>
      <Link to="/orders">Click here to Check Order</Link>
    </div>
  );
};

export default PaymentSuccess;

import React from "react";
import { Link } from "react-router-dom";
import "./ProductHome.css";

const PrintCollectionCard = ({ collect }) => {
  return (
    <>
      <div className="mainH">
        <div className="containerH">
          <div className="cardH cardHp">
            <div className=" imgp">
              <img src={collect.image} alt={collect.printCollection} />
            </div>
            <Link
              to={`/collections/${collect.printCollection}`}
              className="contentH"
            >
              <div className="productNameH">
                <h3>{collect.printCollection}</h3>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintCollectionCard;

import React from "react";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const Arrow = ({ prevSlide, nextSlide }) => {
  return (
    <div className="arrows">
      <span className="prev" onClick={prevSlide}>
        <ArrowCircleLeftIcon fontSize="large" />
      </span>
      <span className="next" onClick={nextSlide}>
        <ArrowCircleRightIcon fontSize="large" />
      </span>
    </div>
  );
};

export default Arrow;

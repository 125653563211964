import React from "react";
import { Link } from "react-router-dom";
import MetaData from "../../layout/MetaData";
import "../Privacy Policy Page/PrivacyPolicy.css";

const PageNotFound = () => {
  return (
    <>
      <MetaData title="Page Not Found--Woca Designs" />
      <div className="pageNotFound">
        <Link to="/">
          <img
            src="https://res.cloudinary.com/dbdez1ptp/image/upload/v1667297706/other%20img/pageNotFound-WocaDesigns_omoi2j.png"
            alt="PageNotFound"
          />
        </Link>
      </div>
    </>
  );
};

export default PageNotFound;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MetaData from "../MetaData";
import "./Search.css";

const Search = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      navigate(`/products/${keyword}`);
    } else {
      navigate(`/products`);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchSubmitHandler(e);
    }
  };

  return (
    <>
      <MetaData title="Search a product-- Woca Designs" />
      <form className="searchBox" onSubmit={searchSubmitHandler}>
        <input
          type="text"
          placeholder="Search a Product..."
          onChange={(e) => setKeyword(e.target.value)}
          onKeyPress={handleKeyPress}
        />
        <input type="submit" value="search" />
      </form>
    </>
  );
};

export default Search;

import React from "react";
import MetaData from "../../layout/MetaData";
import "../Privacy Policy Page/PrivacyPolicy.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ReturnPolicy = () => {
  return (
    <>
      <MetaData title="Return policy--Woca Designs" />
      <h2 className="productsHeading">Return policy</h2>.
      <div className="mainPrivacy">
        <div className="privacyPolicy">
          <p>
            We offer free and hassle free returns and exchange with no questions
            asked. You have 15 days from the date of delivery to fill the
            return/ exchange request form. Kindly proceed to send the product
            back at our studio after filling the form. We will not be able to
            assist you with any information regarding returns or exchange on
            Instagram DM or any other email or contact number apart from the one
            mentioned on this page. care@wocadesigns.in
          </p>
          <h2>
            *Ensure that the following address is securely pasted on the return
            package along with your Full Name and Order ID*
          </h2>
          <p>
            Woca Designs <br />
            D53/107-A Chotti Ghabi <br />
            Rathyatra, Varanasi (U.P)- 221010 <br />
            Near Baba Dham Apartments <br /> Contact: +91-7985350600
          </p>
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/dbdez1ptp/image/upload/v1667297706/other%20img/returnpolicy-wocadesigns_agov1m.png"
            alt="ReturnPolicy"
          />
        </div>
      </div>
      <div className="privacyPolicy">
        <h2>Please note:</h2>
        <ul className="shippingUl">
          <li>
            WE CANNOT ARRANGE A RETURN PICKUP FOR YOU UNLESS YOU HAVE RECEIVED A
            DAMAGED OR WRONG PRODUCT.
          </li>
          <li>
            Products purchased during ANY SALE is NON-REFUNDABLE. You can only
            exchange it with some other product or ask for a coupon code of the
            same value you have paid.{" "}
          </li>
          <li>
            The to and fro courier cost will be borne by you if you wish to
            return the product. In case of refunds we shall refund the total
            amount paid for the products and not shipping if any.
          </li>
          <li>
            For exchange we shall provide you with a unique code once we receive
            the order back at our studio. We shall only provide the value paid
            for the product returned and not any shipping if applicable. This
            process can take up to 20 working days upon receiving your order
            back at our studio.
          </li>
          <li>
            Kindly allow 10 days from the delivery date for the returns to be
            processed and the amount to be shown in your bank account.
            <strong>
              {" "}
              PLEASE NOTE: REFUND / BANK TRANSFER ARE ONLY APPLICABLE FOR
              PREPAID ORDERS / ONLINE PAYMENTS MADE ON OUR WEBSITE.
            </strong>{" "}
          </li>
          <li>
            Payments made with COD option WILL RECEIVE A COUPON CODE WORTH THE
            SAME AMOUNT AS YOUR PRODUCT VALUE WHICH YOU CAN USE WITHIN 30 DAYS
            OF ISSUANCE. <strong> NO REFUND ON COD ORDERS.</strong>
          </li>
          <li>
            Feel free to get in touch with us if you have any doubts or you want
            further assistance by emailing us at care@wocadesigns.in or send us
            a message on WhatsApp +91 7985350600. All your queries will be
            solved between <br />
            <span>
              {" "}
              <ArrowForwardIcon />
              Monday-Saturday, 10:30am-5:00pm. All pending queries will be
              solved the next day.{" "}
            </span>{" "}
            <br />
            <span>
              {" "}
              <ArrowForwardIcon />
              We do not work on SUNDAY or on National Holidays.
            </span>
          </li>
          <li>
            WE CANNOT HELP YOU ON INSTAGRAM DM OR FACEBOOK MESSAGE for all order
            related queries. kindly Whatsapp us or email us with your query.{" "}
          </li>
          <li>
            We request you to keep all your communication with us either on
            email, or on WhatsApp for all your order related queries.
          </li>
          <li>
            Cancellations will only be possible till the order has not been
            dispatched from our warehouse.{" "}
          </li>
        </ul>
        <h2>CANCELLATION POLICY</h2>
        <p>
          We usually ship all our orders within 2-3 days upon receiving the
          order. You can contact us to cancel the order till the time it is not
          dispatched. Once we dispatch your order you will receive an email on
          your registered email ID along with the tracking details.
          wocadesigns.in reserves the right to cancel any order without any
          explanation for doing so, as per the circumstances where the
          requirement could not be met. The company will ensure that any
          communication of cancellation of an order or any applicable refund
          will be made in reasonable time.
        </p>
      </div>
    </>
  );
};

export default ReturnPolicy;

import axios from "axios";

import {
  ALL_CATEGORYIMG_REQUEST,
  ALL_CATEGORYIMG_SUCCESS,
  ALL_CATEGORYIMG_FAIL,
  ADMIN_CATEGORYIMG_REQUEST,
  ADMIN_CATEGORYIMG_SUCCESS,
  ADMIN_CATEGORYIMG_FAIL,
  NEW_CATEGORYIMG_REQUEST,
  NEW_CATEGORYIMG_SUCCESS,
  NEW_CATEGORYIMG_FAIL,
  DELETE_CATEGORYIMG_REQUEST,
  DELETE_CATEGORYIMG_SUCCESS,
  DELETE_CATEGORYIMG_FAIL,
  CLEAR_ERRORS,
} from "../constants/categoryImgConstants";

export const getCategoryImg = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_CATEGORYIMG_REQUEST,
    });
    const { data } = await axios.get("/api/v1/categoryImg");

    dispatch({
      type: ALL_CATEGORYIMG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_CATEGORYIMG_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAdminCategoryImg = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_CATEGORYIMG_REQUEST });
    const { data } = await axios.get("/api/v1/categoryImg");
    dispatch({
      type: ADMIN_CATEGORYIMG_SUCCESS,
      payload: data.categoryImg,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_CATEGORYIMG_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const createCategoryImg = (categoryImgData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_CATEGORYIMG_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.post(
      `/api/v1/admin/categoryImg/new`,
      categoryImgData,
      config
    );

    dispatch({
      type: NEW_CATEGORYIMG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_CATEGORYIMG_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Product
export const deleteCategoryImg = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CATEGORYIMG_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/categoryImg/${id}`);

    dispatch({
      type: DELETE_CATEGORYIMG_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CATEGORYIMG_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const clearErrors = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERRORS,
  });
};

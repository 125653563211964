import {
  ALL_NAVBAR_REQUEST,
  ALL_NAVBAR_SUCCESS,
  ALL_NAVBAR_FAIL,
  ADMIN_NAVBAR_REQUEST,
  ADMIN_NAVBAR_SUCCESS,
  ADMIN_NAVBAR_FAIL,
  NEW_NAVBAR_REQUEST,
  NEW_NAVBAR_SUCCESS,
  NEW_NAVBAR_RESET,
  NEW_NAVBAR_FAIL,
  UPDATE_NAVBAR_REQUEST,
  UPDATE_NAVBAR_SUCCESS,
  UPDATE_NAVBAR_RESET,
  UPDATE_NAVBAR_FAIL,
  DELETE_NAVBAR_REQUEST,
  DELETE_NAVBAR_SUCCESS,
  DELETE_NAVBAR_RESET,
  DELETE_NAVBAR_FAIL,
  CLEAR_ERRORS,
} from "../constants/navbarConstants";

export const navbarReducer = (state = { navbar: [] }, action) => {
  switch (action.type) {
    case ALL_NAVBAR_REQUEST:
    case ADMIN_NAVBAR_REQUEST:
      return {
        loading: true,
        navbar: [],
      };
    case ALL_NAVBAR_SUCCESS:
      return {
        loading: false,
        navbar: action.payload.navbar,
      };
    case ADMIN_NAVBAR_SUCCESS:
      return {
        loading: false,
        navbar: action.payload,
      };
    case ALL_NAVBAR_FAIL:
    case ADMIN_NAVBAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newNavbarReducer = (state = { navbar: {} }, action) => {
  switch (action.type) {
    case NEW_NAVBAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_NAVBAR_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        navbar: action.payload.navbar,
      };
    case NEW_NAVBAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_NAVBAR_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const navbarrReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_NAVBAR_REQUEST:
    case UPDATE_NAVBAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_NAVBAR_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case UPDATE_NAVBAR_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_NAVBAR_FAIL:
    case UPDATE_NAVBAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_NAVBAR_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_NAVBAR_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

import "./App.css";
import Footer from "./component/layout/Footer/Footer.jsx";
import Announcement from "./component/layout/Announcement/Announcement";
import Home from "./component/Home/Home.jsx";
import ProductDetails from "./component/ProductDetails/ProductDetails";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import webFont from "webfontloader";
import React, { useEffect } from "react";
import Products from "./component/ProductsPage/Products";
import Search from "./component/layout/Search/Search";
import GoToTop from "./component/layout/ScrollToTop/GoToTop";
import Navigation from "./component/layout/Header/Navigation";
import LoginSignUp from "./component/User/LoginSignUp";
import store from "./store";
import { loadUser } from "./actions/userAction";
import UserOptions from "./component/layout/Header/UserOptions";
import { useDispatch, useSelector } from "react-redux";
import Profile from "./component/User/Profile";
import ProtectedRoute from "./component/Route/ProtectedRoute";
import UpdateProfile from "./component/User/UpdateProfile";
import UpdatePassword from "./component/User/UpdatePassword";
import ForgotPassword from "./component/User/ForgotPassword";
import ResetPassword from "./component/User/ResetPassword";
import Cart from "./component/Cart/Cart";
import Shipping from "./component/Cart/Shipping";
import ConfirmOrder from "./component/Cart/ConfirmOrder";
import Payment from "./component/Cart/Payment";
import PaymentSuccess from "./component/Cart/PaymentSuccess";
import MyOrders from "./component/Orders/MyOrders";
import OrderDetails from "./component/Orders/OrderDetails";
import Dashboard from "./component/Admin/Dashboard";
import ProductList from "./component/Admin/ProductList";
import NewProduct from "./component/Admin/NewProduct";
import UpdateProduct from "./component/Admin/UpdateProduct";
import OrderList from "./component/Admin/OrderList";
import ProcessOrder from "./component/Admin/ProcessOrder";
import UserList from "./component/Admin/UserList";
import UpdateUser from "./component/Admin/UpdateUser";
import ProductReview from "./component/Admin/ProductReview";
import InfoList from "./component/Admin/InfoList";
import NewInfo from "./component/Admin/NewInfo";
import BannerList from "./component/Admin/BannerList";
import NewBanner from "./component/Admin/NewBanner";
import CategoryList from "./component/Admin/CategoryList";
import NewCategory from "./component/Admin/NewCategory";
import CategoryImgList from "./component/Admin/CategoryImgList";
import NewCategoryImg from "./component/Admin/NewCategoryImg";
import NavbarList from "./component/Admin/NavbarList";
import NewNavbar from "./component/Admin/NewNavbar";
import UpdateNavbar from "./component/Admin/UpdateNavbar";
import { useAlert } from "react-alert";
import { getAdminCategory, clearErrors } from "./actions/categoryAction";
import Wishlist from "./component/Cart/Wishlist";
import SaleDisList from "./component/Admin/SaleDisList";
import NewSaleDis from "./component/Admin/NewSaleDis";
import { getSaleDis } from "./actions/saleDisAction";
import WeekSaleDisList from "./component/Admin/WeekSaleDisList";
import NewWeekSaleDis from "./component/Admin/NewWeekSaleDis";
import WeeksaleDisProductList from "./component/Admin/WeeksaleDisProductList";
import UpdateWeekSaleProduct from "./component/Admin/UpdateWeekSaleProduct";
import WeeklySaleProduct from "./component/Admin/WeeklySaleProduct";
import CollectionList from "./component/Admin/CollectionList";
import NewCollection from "./component/Admin/NewCollection";
import Collection from "./component/Pages/Collection Page/Collection";
import { getCollection } from "./actions/collectionAction";
import FAQs from "./component/Pages/FAQs Page/FAQs";
import PrivacyPolicy from "./component/Pages/Privacy Policy Page/PrivacyPolicy";
import TermOfServices from "./component/Pages/Term of Services Page/TermOfServices";
import Shippingpolicy from "./component/Pages/Shipping policy Page/Shippingpolicy";
import ReturnPolicy from "./component/Pages/Return Policy page/ReturnPolicy";
import Exchange from "./component/Pages/Exchange Page/Exchange";
import PageNotFound from "./component/Pages/Page Not Found/PageNotFound";
import UsageandCare from "./component/Pages/FAQs Page/UsageandCare";
import PageBannerList from "./component/Admin/PageBannerList";
import NewPageBanner from "./component/Admin/NewPageBanner";
import About from "./component/Pages/About/About";
import Catproduct from "./component/ProductsPage/Catproduct";
import Whatsapp from "./component/layout/ScrollToTop/Whatsapp";
import Blog from "./component/Pages/Blogs/Blog";
import BlogPage from "./component/Pages/Blogs/BlogPage";
import BlogList from "./component/Admin/BlogList";
import NewBlog from "./component/Admin/NewBlog";
import CartList from "./component/Admin/CartList";
import Promotion from "./component/Admin/Promotion";

function App() {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { saleDis } = useSelector((state) => state.saleDiss);
  const disSale = saleDis.map((c) => c.saleDis);
  const { category: categories, error: catError } = useSelector(
    (state) => state.categories
  );
  const { collection } = useSelector((state) => state.printCollections);

  useEffect(() => {
    if (catError) {
      alert.error(catError);
      dispatch(clearErrors());
    }
    dispatch(getSaleDis());
    dispatch(getAdminCategory());
    dispatch(getCollection());
  }, [dispatch, alert, catError]);

  const { isAuthenticated, user } = useSelector((state) => state.user);

  useEffect(() => {
    webFont.load({
      google: {
        families: ["Quicksand", "Roboto Flex", "Open Sans"],
      },
    });
    store.dispatch(loadUser());
  }, []);
  // window.addEventListener("contextmenu", (e) => e.preventDefault());
  return (
    <Router>
      <GoToTop />
      <Announcement />
      <Navigation />
      <Whatsapp />
      {isAuthenticated && <UserOptions user={user} />}
      <Routes>
        {/* Navbar and open Routes */}
        <Route exact path="/" element={<Home />} />
        <Route
          exact
          path="/handcraftedBags"
          element={<Products category={"Bags"} />}
        />
        <Route exact path="/products" element={<Products category={""} />} />
        <Route exact path="/onsale" element={<Products category={"Sale"} />} />
        <Route
          exact
          path="/bestseller"
          element={<Products category={"BestSeller"} />}
        />
        <Route
          exact
          path="/weekendsale"
          element={<Products category={"weeksale"} />}
        />
        <Route
          exact
          path="/product/:name/:id"
          element={<ProductDetails discount={disSale[0] ? disSale[0] : ""} />}
        />
        <Route path="/products/:keyword" element={<Products />} />
        {categories &&
          categories.map((category) => (
            <Route
              path={`/products/${category.category}`}
              element={
                <Products category={category.category} image={category.image} />
              }
              key={category._id}
            />
          ))}
        {collection &&
          collection.map((collect) => (
            <Route
              path={`/collections/${collect.printCollection}`}
              element={
                <Products
                  collections={collect.printCollection}
                  image={collect.image}
                />
              }
              key={collect._id}
            />
          ))}
        <Route exact path="/collections" element={<Collection />} />
        <Route
          exact
          path="/accessories"
          element={<Catproduct category={"Accessories"} />}
        />
        <Route
          exact
          path="/hairaccessories"
          element={<Catproduct category={"Hair-Accessory"} />}
        />
        <Route
          exact
          path="/combos"
          element={<Catproduct category={"Combo"} />}
        />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/search" element={<Search />} />
        <Route exact path="/login" element={<LoginSignUp />} />
        <Route exact path="/password/forgot" element={<ForgotPassword />} />
        <Route
          exact
          path="/cart"
          element={<Cart discount={disSale[0] ? disSale[0] : ""} />}
        />
        <Route exact path="/wishlist" element={<Wishlist />} />
        <Route exact path="/paymentsuccess" element={<PaymentSuccess />} />
        <Route
          exact
          path="/password/reset/:token"
          element={<ResetPassword />}
        />
        <Route exact path="/blogs" element={<Blog />} />
        <Route exact path="/blog/:id" element={<BlogPage />} />
        {/* Footer Routes */}
        <Route exact path="/faqs" element={<FAQs />} />
        <Route exact path="/usageandcare" element={<UsageandCare />} />
        <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/termsOfService" element={<TermOfServices />} />
        <Route exact path="/shippingPolicy" element={<Shippingpolicy />} />
        <Route exact path="/returnPolicy" element={<ReturnPolicy />} />
        <Route exact path="/exchangePolicy" element={<Exchange />} />
        {/* protected Routes */}
        <Route exact path="shipping" element={<Shipping />} />
        <Route element={<ProtectedRoute />}>
          <Route
            exact
            path="/order/confirm"
            element={<ConfirmOrder discount={disSale[0] ? disSale[0] : ""} />}
          />
          <Route exact path="/process/payment" element={<Payment />} />
          <Route exact path="/orders" element={<MyOrders />} />
          <Route exact path="/account" element={<Profile />} />
          <Route exact path="/me/update" element={<UpdateProfile />} />
          <Route exact path="/password/update" element={<UpdatePassword />} />
          <Route exact path="/order/:id" element={<OrderDetails />} />
          <Route exact path="/admin/dashboard" element={<Dashboard />} />
          <Route exact path="/admin/products" element={<ProductList />} />
          <Route exact path="/admin/product" element={<NewProduct />} />
          <Route exact path="/admin/product/:id" element={<UpdateProduct />} />
          <Route exact path="/admin/orders" element={<OrderList />} />
          <Route exact path="/admin/order/:id" element={<ProcessOrder />} />
          <Route exact path="/admin/users" element={<UserList />} />
          <Route exact path="/admin/user/:id" element={<UpdateUser />} />
          <Route exact path="/admin/reviews" element={<ProductReview />} />
          <Route exact path="/admin/promotion" element={<Promotion />} />
          <Route exact path="/admin/infos" element={<InfoList />} />
          <Route exact path="/admin/info" element={<NewInfo />} />
          <Route exact path="/admin/cartList" element={<CartList />} />
          <Route exact path="/admin/banners" element={<BannerList />} />
          <Route exact path="/admin/banner" element={<NewBanner />} />
          <Route exact path="/admin/pageBanners" element={<PageBannerList />} />
          <Route exact path="/admin/pageBanner" element={<NewPageBanner />} />
          <Route exact path="/admin/categories" element={<CategoryList />} />
          <Route exact path="/admin/category" element={<NewCategory />} />
          <Route exact path="/admin/collections" element={<CollectionList />} />
          <Route exact path="/admin/collection" element={<NewCollection />} />
          <Route exact path="/admin/blogs" element={<BlogList />} />
          <Route exact path="/admin/blog" element={<NewBlog />} />
          <Route
            exact
            path="/admin/categoryImgs"
            element={<CategoryImgList />}
          />
          <Route exact path="/admin/categoryImg" element={<NewCategoryImg />} />
          <Route exact path="/admin/navbars" element={<NavbarList />} />
          <Route exact path="/admin/navbar" element={<NewNavbar />} />
          <Route exact path="/admin/navbar/:id" element={<UpdateNavbar />} />
          <Route exact path="/admin/saleDisEdit" element={<SaleDisList />} />
          <Route exact path="/admin/saleDis" element={<NewSaleDis />} />
          <Route
            exact
            path="/admin/weeksaleDisEdit"
            element={<WeekSaleDisList />}
          />
          <Route exact path="/admin/weeksaleDis" element={<NewWeekSaleDis />} />
          <Route
            exact
            path="/admin/weeksaleDisproduct"
            element={<WeeksaleDisProductList />}
          />
          <Route
            exact
            path="/admin/weeksaleDisallproduct"
            element={<WeeklySaleProduct />}
          />
          <Route
            exact
            path="/admin/weeksaleproduct/:id"
            element={<UpdateWeekSaleProduct />}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import "./Products.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProduct } from "../../actions/productAction";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import ProductCard from "../ProductCard/ProductCard";
import { useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import MetaData from "../layout/MetaData";
import { getAdminCategory } from "../../actions/categoryAction";
import { getPageBanner } from "../../actions/pageBannerAction";
import SlideModal from "../Home/SlideModal";

const Products = (props) => {
  const params = useParams();
  const alert = useAlert();
  const keyword = params.keyword;
  const dispatch = useDispatch();
  const img1 =
    "https://res.cloudinary.com/dbdez1ptp/image/upload/f_auto,q_auto/v1667486079/page%20banners/shop_mgx7z2_u3s7vq.avif";

  const { category: categorie, error: catError } = useSelector(
    (state) => state.categories
  );
  const categories = categorie.sort((a, b) =>
    a.category > b.category ? 1 : b.category > a.category ? -1 : 0
  );
  const { pageBanner } = useSelector((state) => state.pageBanners);
  const pBanner = pageBanner.filter((c) => c.name === props.category);

  useEffect(() => {
    if (catError) {
      alert.error(catError);
      dispatch(clearErrors());
    }
    dispatch(getPageBanner());
    dispatch(getAdminCategory());
  }, [dispatch, alert, catError]);

  const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([0, 6000]);
  const [category, setCategory] = useState(
    props.category === "" ||
      props.category === "Sale" ||
      props.category === "weeksale" ||
      props.category === "BestSeller" ||
      props.category === "Bags" ||
      props.collections
      ? ""
      : props.category === "Accessories"
      ? "Accessories"
      : props.category === "Combo"
      ? "Combo"
      : props.category === "Hair-Accessory"
      ? "Hair-Accessory"
      : props.category
  );

  const [ratings, setRatings] = useState(0);

  let {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    // filteredProductsCount,
  } = useSelector((state) => state.products);

  products = products.sort((a, b) =>
    a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
  );

  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };
  const priceHandler = (event, newPrice) => {
    setPrice(newPrice);
  };

  // let count = filteredProductsCount;
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct(keyword, currentPage, price, category, ratings));
  }, [dispatch, keyword, error, alert, currentPage, price, category, ratings]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData
            title={
              props.category === "Sale"
                ? "On SALE-- Woca Designs"
                : props.category === "weeksale"
                ? "Weekend SALE-- Woca Designs"
                : props.category === "BestSeller"
                ? "Bestseller-- Woca Designs"
                : props.category === "Bags"
                ? "Handcrafted Bags-- Woca Designs"
                : props.category === "Accessories"
                ? "Accessories-- Woca Designs"
                : props.category === "Combo"
                ? "Combos-- Woca Designs"
                : props.category === "Hair-Accessory"
                ? "Hair Accessories-- Woca Designs"
                : "Shop All-- Woca Designs"
            }
          />
          <SlideModal />
          <div className="productsSec">
            {props.image ? (
              <img
                className="productPageImg"
                src={props.image}
                alt={props.image}
              />
            ) : (
              pBanner &&
              pBanner.map((i) => (
                <img
                  className="productPageImg"
                  src={i.url}
                  alt={i.name}
                  key={i._id}
                />
              ))
            )}
            {props.category === "" && (
              <img className="productPageImg" src={img1} alt={img1} />
            )}

            <h2 className="productsHeading">
              {props.category === ""
                ? `Explore ${
                    category ? category : "Handcrafted Fashion"
                  } Now..!!`
                : props.category === "Sale"
                ? `${category ? category : "Handcrafted Fashion"} on Sale..!!`
                : props.category === "weeksale"
                ? "Weekend Sale..!!"
                : props.category === "Bags"
                ? `Explore ${
                    category ? category : "Handcrafted Fashion Bags"
                  } Now..!!`
                : props.category === "Accessories"
                ? `Explore Handcrafted Accessories Now..!!`
                : props.category === "Combo"
                ? `Explore Handcrafted Combos Now..!!`
                : props.category === "Hair-Accessory"
                ? `Explore Hand Embroidered Hair Accessories`
                : props.collections
                ? `Explore ${props.collections} Collection Now..!!`
                : props.category
                ? `Explore ${props.category} Now..!!`
                : "Explore Handcrafted Fashion Now..!!"}
            </h2>
            <h5 className="productsHeading">
              {(category !== "" && props.category === "") ||
              (category !== "" && props.category === "Sale") ||
              (category !== "" && props.category === "weeksale") ||
              (category !== "" && props.category === "Bags")
                ? "Select All in Category Filter for displaying all Bags..!!"
                : ""}
            </h5>
            {props.category === "" ||
            props.category === "Sale" ||
            props.category === "weeksale" ? (
              <div>
                <div className="categoryBox">
                  <select
                    onChange={(e) =>
                      setCategory(
                        e.target.value === "All" ? "" : e.target.value
                      )
                    }
                  >
                    <option className="category-link" value="All">
                      Choose Category
                    </option>
                    {categories &&
                      categories.map(
                        (cate) =>
                          cate.category !== "Combo" && (
                            <option
                              className="category-link"
                              key={cate._id}
                              value={cate.category}
                            >
                              {cate.category}
                            </option>
                          )
                      )}
                  </select>
                </div>
              </div>
            ) : props.category === "Bags" ? (
              <div>
                <div className="categoryBox">
                  <select
                    onChange={(e) =>
                      setCategory(
                        e.target.value === "All" ? "" : e.target.value
                      )
                    }
                  >
                    <option className="category-link" value="">
                      Choose Category
                    </option>
                    {categories &&
                      categories.map(
                        (cate) =>
                          cate.category !== "Accessories" &&
                          cate.category !== "Combo" &&
                          cate.category !== "Hair-Accessory" && (
                            <option
                              className="category-link"
                              key={cate._id}
                              value={cate.category}
                            >
                              {cate.category}
                            </option>
                          )
                      )}
                  </select>
                </div>
              </div>
            ) : props.category ? (
              <div>
                <div className="categoryBox">
                  {/* <select onChange={(e) => setCategory(props.category)}>
                    <option className="category-link" value="">
                      Choose Category
                    </option>
                    {categories &&
                      categories.map(
                        (cate) =>
                          cate.category !== "Accessories" &&
                          cate.category !== "Combo" &&
                          cate.category !== "Hair-Accessory" && (
                            <option
                              className="category-link"
                              key={cate._id}
                              value={cate.category}
                            >
                              {cate.category}
                            </option>
                          )
                      )}
                  </select> */}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="productPage">
              <div className="products">
                {props.category === "Sale"
                  ? products &&
                    products.map(
                      (product) =>
                        product.discount > 0 && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : props.category === "weeksale"
                  ? products &&
                    products.map(
                      (product) =>
                        product.weekSale === true && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : props.category === "BestSeller"
                  ? products &&
                    products.map(
                      (product) =>
                        product.ratings > 4 && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : props.category === "Bags"
                  ? products &&
                    products.map(
                      (product) =>
                        product.category !== "Accessories" &&
                        product.category !== "Hair-Accessory" && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : props.category === "Accessories"
                  ? products &&
                    products.map(
                      (product) =>
                        product.category === "Accessories" && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : props.category === "Hair-Accessory"
                  ? products &&
                    products.map(
                      (product) =>
                        product.category === "Hair-Accessory" && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : props.category === "Combo"
                  ? products &&
                    products.map(
                      (product) =>
                        product.category === "Combo" && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : props.collections
                  ? products &&
                    products.map(
                      (product) =>
                        product.printCollection === props.collections && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : props.category
                  ? products &&
                    products.map(
                      (product) =>
                        product.category === props.category && (
                          <ProductCard key={product._id} product={product} />
                        )
                    )
                  : products &&
                    products.map((product) => (
                      <ProductCard key={product._id} product={product} />
                    ))}
              </div>
            </div>

            <div className="filterBox">
              <h3 className="filterHeading">Filters</h3>
              <div>
                <Typography>Price</Typography>
                <Slider
                  value={price}
                  onChange={priceHandler}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-Slider"
                  min={0}
                  max={6000}
                />
              </div>
              <div>
                <fieldset>
                  <Typography component="legend"> Ratings Above</Typography>
                  <Slider
                    value={ratings}
                    onChange={(e, newRating) => {
                      setRatings(newRating);
                    }}
                    aria-labelledby="continous-slider"
                    min={0}
                    max={5}
                    valueLabelDisplay="auto"
                  />
                </fieldset>
              </div>
            </div>

            {resultPerPage < productsCount && (
              <div className="paginationBox">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={resultPerPage}
                  totalItemsCount={productsCount}
                  onChange={setCurrentPageNo}
                  nextPageText="Next"
                  prevPageText="Prev"
                  firstPageText="First"
                  lastPageText="Last"
                  itemClass="page-item"
                  linkClass="page-link"
                  activeClass="pageItemActive"
                  activeLinkClass="pageLinkActive"
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Products;

import React from "react";
import MetaData from "../../layout/MetaData";
import "../Privacy Policy Page/PrivacyPolicy.css";

const Shippingpolicy = () => {
  return (
    <>
      <MetaData title="Shipping Policy--Woca Designs" />
      <h2 className="productsHeading">Shipping Policy</h2>.
      <div className="mainPrivacy">
        <div className="privacyPolicy">
          <p>
            We at Woca know how important it is to receive your purchased
            products in the finest condition, and on time. So we use a large
            network of couriers to deliver your products to you as early as
            possible.
          </p>
        </div>
        <div>
          <img
            src="https://res.cloudinary.com/dbdez1ptp/image/upload/v1667297706/other%20img/shippingPolicy-WocaDesigns_jszr6x.png"
            alt="Shippingpolicy"
          />
        </div>
      </div>
      <div className="privacyPolicy">
        <ul className="shippingUl">
          <li>
            We offer free shipping to all over India on all prepaid orders. COD
            orders are charged a separate COD fee, which is added at the time of
            checkout.
          </li>
          <li>
            Free shipping is available on all products, subject to a minimum
            order value. A Shipping charge of Rs. 100/- will be applied to all
            orders below Rs. 3000/-
          </li>
          <li>Prepaid orders get dispatched faster.</li>
          <li>
            All COD orders will be verified post order purchase, before being
            dispatched.
          </li>
          <li>
            In case of overbooking due to technical issues, prepaid orders will
            be given preference.
          </li>
          <li>
            Depending upon the location, the product is delivered to you in
            10-15 workings days after it has been dispatched from our warehouse.
          </li>
          <li>
            The whole order amount including any delivery charges will be
            refunded if the order is cancelled before dispatch or lost in
            transit by us.
          </li>
          <li>
            We work with multiple reputed courier partners, to ensure a smooth
            delivery experience. All orders are shipped on surface transport
            basis.
          </li>
          <li>
            Once you place an order with us, you will receive an email
            confirming your order details.
          </li>
          <li>
            Once the order is dispatched, a tracking link will be shared via a
            separate email from us.
          </li>
          <li>
            If you want to avail of international shipping, please feel free to
            write to us at info@wocadesigns.in
          </li>
        </ul>
      </div>
    </>
  );
};

export default Shippingpolicy;

export const ALL_BANNER_REQUEST = "ALL_BANNER_REQUEST";
export const ALL_BANNER_SUCCESS = "ALL_BANNER_SUCCESS";
export const ALL_BANNER_FAIL = "ALL_BANNER_FAIL";
export const ADMIN_BANNER_REQUEST = "ADMIN_BANNER_REQUEST";
export const ADMIN_BANNER_SUCCESS = "ADMIN_BANNER_SUCCESS";
export const ADMIN_BANNER_FAIL = "ADMIN_BANNER_FAIL";
export const NEW_BANNER_REQUEST = "NEW_BANNER_REQUEST";
export const NEW_BANNER_SUCCESS = "NEW_BANNER_SUCCESS";
export const NEW_BANNER_RESET = "NEW_BANNER_RESET";
export const NEW_BANNER_FAIL = "NEW_BANNER_FAIL";
export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_RESET = "DELETE_BANNER_RESET";
export const DELETE_BANNER_FAIL = "DELETE_BANNER_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, createNavbar } from "../../actions/navbarAction";
import MetaData from "../layout/MetaData";
import "./NewProduct.css";
import { Button } from "@mui/material";
import TitleIcon from "@mui/icons-material/Title";
import LinkIcon from "@mui/icons-material/Link";
import { NEW_NAVBAR_RESET } from "../../constants/navbarConstants";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";

const NewNavbar = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { loading, error, success } = useSelector((state) => state.newNavbar);

  const [link, setLink] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Navlink Created Successfully");
      navigate("/admin/navbars");
      dispatch({ type: NEW_NAVBAR_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const createBannerSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("link", link);
    myForm.set("name", name);

    dispatch(createNavbar(myForm));
  };

  return (
    <>
      <MetaData title="Create Navlink" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createBannerSubmitHandler}
          >
            <h1>Create Navlink</h1>

            <div>
              <LinkIcon />
              <input
                type="text"
                placeholder="NavRoute Link"
                required
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </div>
            <div>
              <TitleIcon />
              <input
                type="text"
                placeholder="Navlink Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewNavbar;

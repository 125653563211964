export const ALL_PAGEBANNER_REQUEST = "ALL_PAGEBANNER_REQUEST";
export const ALL_PAGEBANNER_SUCCESS = "ALL_PAGEBANNER_SUCCESS";
export const ALL_PAGEBANNER_FAIL = "ALL_PAGEBANNER_FAIL";
export const ADMIN_PAGEBANNER_REQUEST = "ADMIN_PAGEBANNER_REQUEST";
export const ADMIN_PAGEBANNER_SUCCESS = "ADMIN_PAGEBANNER_SUCCESS";
export const ADMIN_PAGEBANNER_FAIL = "ADMIN_PAGEBANNER_FAIL";
export const NEW_PAGEBANNER_REQUEST = "NEW_PAGEBANNER_REQUEST";
export const NEW_PAGEBANNER_SUCCESS = "NEW_PAGEBANNER_SUCCESS";
export const NEW_PAGEBANNER_RESET = "NEW_PAGEBANNER_RESET";
export const NEW_PAGEBANNER_FAIL = "NEW_PAGEBANNER_FAIL";
export const DELETE_PAGEBANNER_REQUEST = "DELETE_PAGEBANNER_REQUEST";
export const DELETE_PAGEBANNER_SUCCESS = "DELETE_PAGEBANNER_SUCCESS";
export const DELETE_PAGEBANNER_RESET = "DELETE_PAGEBANNER_RESET";
export const DELETE_PAGEBANNER_FAIL = "DELETE_PAGEBANNER_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";

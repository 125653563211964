import React from "react";
import "./Footer.css";
import logo from "../../../image/logo.png";
import { Link } from "react-router-dom";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SendIcon from "@mui/icons-material/Send";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer id="footer">
      <div className="row">
        <div className="col">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
          <p>Bringing the Indian Handcraft to the modern world...!!</p>
          <div className="icon">
            <a href="https://www.facebook.com/WocaDesigns" target="blank">
              <FacebookRoundedIcon fontSize="large" />
            </a>
            <a
              href="https://www.instagram.com/wocadesigns_bags/"
              target="blank"
            >
              <InstagramIcon fontSize="large" />
            </a>
            <a href="https://in.pinterest.com/wocadesigns/" target="blank">
              <PinterestIcon fontSize="large" />
            </a>
            <Link to="#">
              <YouTubeIcon fontSize="large" />
            </Link>
          </div>
        </div>
        <div className="col">
          <h2>
            Info{" "}
            <div className="underline">
              <span></span>
            </div>
          </h2>
          <ul>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/products">Shop All</Link>
            </li>
            <li>
              <Link to="/blogs">Blog</Link>
            </li>
            <li>
              <a
                href="https://www.shiprocket.in/shipment-tracking/"
                target="blank"
              >
                Track Your Order
              </a>
            </li>
            <li>
              <Link to="/faqs">FAQs</Link>
            </li>
            <li>
              <Link to="/usageandcare">Usage and Care</Link>
            </li>
          </ul>
        </div>
        <div className="col">
          <h2>
            Useful Links{" "}
            <div className="underline">
              <span></span>
            </div>
          </h2>
          <ul>
            <li>
              <Link to="/exchangePolicy">Initiate Return/ Exchange</Link>
            </li>
            <li>
              <Link to="/returnPolicy">Return Policy</Link>
            </li>
            <li>
              <Link to="/shippingPolicy">Shipping Policy</Link>
            </li>
            <li>
              <Link to="/termsOfService">Terms of Service</Link>
            </li>
            <li>
              <Link to="/privacyPolicy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className="col">
          <h2>
            Join Us{" "}
            <div className="underline">
              <span></span>
            </div>
          </h2>
          <p>
            Subscribe to our newsletter and stay updated with the latest offers
            and new collection launch
          </p>
          <form>
            <MailOutlineIcon fontSize="large" style={{ marginRight: "10px" }} />
            <input type="email" placeholder="Enter your email id" required />
            <button type="submit">
              <SendIcon fontSize="large" style={{ marginRight: "10px" }} />
            </button>
          </form>
        </div>
        <div className="col">
          <h2>
            Woca Studio{" "}
            <div className="underline">
              <span></span>
            </div>
          </h2>

          <p>
            {" "}
            <MapsHomeWorkOutlinedIcon /> &nbsp; D53/107 Rathyatra{" "}
          </p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Varanasi (U.P)- 221010</p>

          <p className="email_id">
            {" "}
            <ForwardToInboxOutlinedIcon /> &nbsp; wocadesigns@gmail.com{" "}
          </p>
          <h4>
            <CallOutlinedIcon /> &nbsp; +91-7985350600
          </h4>
        </div>
      </div>
      <hr />
      <div className="footer_content">
        <h2> Empowering Womens</h2>
        <p>
          Woca is a Proudly Indian brand and empowering our womans because a
          strong woman knows she has strength enough for the journey, but a
          woman of strength knows it is in the journey where she will become
          strong.We make laptop bags, handbags, women's office bags, female
          wallets, mini wallets, sling bags, bucket bags, backpacks, travel
          pouches for women & men. All bags are handcrafted by expert artisans.
          We only sell online and can ship to all over India. Our products are a
          great choice for office goers, homemakers and college students. Many
          people also choose our products for gifts in Weddings, Anniversary,
          Diwali, Holi, Christmas, New Year, Karwa Chauth, Valentine's Day,
          Women's Day.
        </p>
        <span>
          &#169; {year} Woca Designs, Development by Netstarpreneur Pvt Ltd
        </span>
      </div>
    </footer>
  );
};

export default Footer;

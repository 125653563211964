import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, createSaleDis } from "../../actions/saleDisAction";
import MetaData from "../layout/MetaData";
import "./NewProduct.css";
import { Button } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import { NEW_SALEDIS_RESET } from "../../constants/saleDisConstants";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";

const NewSaleDis = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { loading, error, success } = useSelector((state) => state.newSaleDis);

  const [saleDis, setSaleDis] = useState("");

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Sale Discount Created Successfully");
      navigate("/admin/saleDis");
      dispatch({ type: NEW_SALEDIS_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const createInfoSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("saleDis", saleDis);

    dispatch(createSaleDis(myForm));
  };

  return (
    <>
      <MetaData title="Create Sale Discount" />
      <div className="dashboard">
        <Sidebar />
        <div className="newProductContainer">
          <form
            className="createProductForm"
            encType="multipart/form-data"
            onSubmit={createInfoSubmitHandler}
          >
            <h1>Create Sale Discount</h1>

            <div>
              <DescriptionIcon />
              <input
                type="number"
                placeholder="Sale Discount"
                required
                value={saleDis}
                onChange={(e) => setSaleDis(e.target.value)}
              />
            </div>

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
            >
              Create
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default NewSaleDis;

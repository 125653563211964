import React, { useEffect, useState } from "react";
import Arrow from "./Arrow";
import Dots from "./Dots";
import "./Slider.css";
import { useDispatch, useSelector } from "react-redux";
import { getBanner, clearErrors } from "../../../actions/bannerAction";
import { useAlert } from "react-alert";

function Slider(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  let { banner, error } = useSelector((state) => state.banners);

  // Ensure the banner is an array before sorting
  if (Array.isArray(banner)) {
    banner = banner.sort((a, b) =>
      a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
    );
  } else {
    banner = [];
  }

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getBanner());
  }, [dispatch, error, alert]);

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((activeIndex) =>
        activeIndex === banner.length - 1 ? 0 : activeIndex + 1
      );
    }, 8000); // Increased the interval to 8 seconds

    // Preload images to improve slide change performance
    banner.forEach((slide) => {
      new Image().src = slide.url;
    });

    return () => clearInterval(interval);
  }, [banner]);

  return (
    <div className="slider-container">
      {banner.map((slide, index) => (
        <div
          key={slide._id}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <img className="slide-image fade" src={slide.url} alt={slide.name} />
        </div>
      ))}

      <Arrow
        prevSlide={() =>
          setActiveIndex((activeIndex) =>
            activeIndex < 1 ? banner.length - 1 : activeIndex - 1
          )
        }
        nextSlide={() =>
          setActiveIndex((activeIndex) =>
            activeIndex === banner.length - 1 ? 0 : activeIndex + 1
          )
        }
      />

      <Dots
        activeIndex={activeIndex}
        imageSlider={banner}
        onClick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </div>
  );
}

export default Slider;
